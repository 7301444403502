import React, { useState,useEffect } from 'react'
import {PaymentElement,useElements,useStripe} from '@stripe/react-stripe-js';
import { completeOrder } from '../../helpers/apiRequests';

import useApp from '../../store/useApp'
import { billingsAnalytics, purchaseAnalytics } from '../../helpers/gtagEvents';

const CheckoutForm = () => {
    const elements = useElements()
    const {setClientSecret,setOrderProcessed,setSucessMessage,setSucessStatus,setActiveStep} = useApp()
    const stripe = useStripe();
    const [errorMessage, setErrorMessage] = useState(null)
    // const {setClientSecret} = useContext(AppContext)
    const [isProcessing, setIsProcessing] = useState(false)
    const [disableBtns,setDisableBtns] = useState(false)


    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        setIsProcessing(true)
    
        if (!stripe || !elements) {
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          setIsProcessing(false)
          return;
        }
    
        const {error,paymentIntent} = await stripe.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements,
          redirect: 'if_required'
        //   confirmParams: {
        //     return_url: 'http://localhost:3000/?Id=create-test-2',
        //   },
        });
    
        if (error) {
          // This point will only be reached if there is an immediate error when
          // confirming the payment. Show error to your customer (for example, payment
          // details incomplete)
          setIsProcessing(false)
          setErrorMessage(error?.message);
        } else {
          console.log("Success",paymentIntent)
          try {
            const {data} = await completeOrder(paymentIntent.id)
            const {status,message,object} = data;
            const orderNumber = object?.transaction_id ? object.transaction_id : 0;
            setSucessStatus(status)
            setSucessMessage({email:message,orderNumber})
            setOrderProcessed(true)
            setClientSecret(null)
            billingsAnalytics();
            purchaseAnalytics(object);
            setActiveStep('success')
          } catch (error) {
            console.log("Error",error)
            setClientSecret(null)
          }
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.

        //   try {
        //     const { data }:any = await completeOrder(paymentIntent.id)
        //     const {status,message,object} = data
        //     if(status === 'Error'){
        //       //When error
        //         setOrderFailed(true)
        //         setClientSecret(null)
        //         setIsProcessing(false)
        //     }else if(status === 'Success'){
        //       // setOrderPlaced(true)
        //       router.push('/checkout/thank-you?id='+object?.id+'&sec='+object.sec)
        //       // setIsProcessing(false)
        //     }else{
        //       setIsProcessing(false)
        //       setOrderFailed(true)
        //       setClientSecret(null)
        //     }
        //     setIsProcessing(false)
        //   } catch (error) {
        //     setIsProcessing(false)
        //     setClientSecret(null)
        //     setOrderFailed(true)
        //   }
        }
      };

      useEffect(()=>{
        if(isProcessing){
          setDisableBtns(true)
        }else{
          setDisableBtns(false)
        }
      },[isProcessing])
    
  return (
    <form onSubmit={handleSubmit} className='left-column basis-7/12 px-1 md:px-4 mb-5'>
      <div className="font-bold text-[16px] text-[#00263A] mb-5 uppercase">Payment Details</div>
      <PaymentElement className='payment-element'
        options={{layout: { type: 'tabs', defaultCollapsed: false}}}/>
      <div className='msg-error'>
        {errorMessage && <p style={{color:'#F85431'}}>{errorMessage}</p>}
      </div>
      <div className='payment-btn pay-wrap mt-5'>
      <button aria-label="Buy" type="submit" disabled={!stripe || disableBtns} className="button btn-reverse button--full-width custom-btn bg-[#ce0e2d]">
          {disableBtns && <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="lock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="inline-block h-4 mr-2 pb-0.5 svg-inline--fa fa-lock"><path fill="currentColor" d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" className=""></path></svg>}
          Complete Purchase
      </button>
        {/* <button className='btn btn--primary' disabled={!stripe || disableBtns}>
          {isProcessing ? (
            <LoadingSvg/>
          ): (
            'Pay Now'
          )}
        </button> */}
      </div>
    </form>
  );
};

export default CheckoutForm;