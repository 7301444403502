import React from 'react'

function SingleBox({index,box,boxErrors,handleDescriptionChange}) {
  return (
    <div>
        <div className="font-semibold uppercase">
        <ul className="boxes-shipping-details-wrapper">
            <li id="ship-box-detail" className="flex justify-between mb-2 tracking-[1px] text-tiny text-[#888]"> Custom // {box?.length} x {box?.width} x {box?.height}{box?.unitOfMeasurement === 'IN' ? 'in' : 'cm'} {box?.weight}{box?.weightUnitOfMeasurement === 'LBS' ? 'lb' : 'kg'}</li>
        </ul>
        </div>
        <div className={`input-group mb-2 ${boxErrors.outerDescError ? 'bf-form-error-col' : ''}`}>
            <textarea onChange={(e)=>handleDescriptionChange('outer',e.target.value,index)} value={box?.boxOuterDescription} placeholder="e.g. Specialized Allez road bike box from Trail Head Cyclery in Cupertino." maxlength="600" className="w-full bf-input custom-style-input leading-4"></textarea> 
            <label>Describe The Outside Of Your Box Or Case {boxErrors.outerDescError ? '(MIN 2 WORDS)' : ''} </label>
        </div>
        <div className={`input-group mb-2 ${boxErrors.innerDescError ? 'bf-form-error-col' : ''}`}>
            <textarea onChange={(e)=>handleDescriptionChange('inner',e.target.value,index)} value={box?.boxInnerDescription} placeholder="e.g. Ibis Hakka MX road/gravel bike, SRAM Force/Eagle AXS Groupset, Enve AG25 Wheels, Challenge Getaway Tires, Serial Number - 123456789" maxlength="600" className="w-full bf-input custom-style-input leading-4"></textarea> 
            <label>Describe The Contents Of Your Box Or Case {boxErrors.innerDescError ? '(MIN 3 WORDS)' : ''} </label>
        </div>
    </div>
  )
}

export default SingleBox