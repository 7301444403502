import axiosAuth from './axios'
import axios from 'axios'
import { getFormattedAddressByPlace,formatDateToLocalISOString } from './generals'

export const getFormattedAddress =async function(place){

    const address = getFormattedAddressByPlace(place)

    return axios.post(process.env.REACT_APP_API_URL + '/api/resources/validateaddress',{
            "xavRequest": {
              "addressKeyFormat": {
                "consigneeName": "",
                "buildingName": "",
                "addressLine": [
                  address.addressLine ?? ''
                ],
                "region": address.region ?? '',
                "politicalDivision2": address.politicalDevision2 ?? '',
                "politicalDivision1": address.politicalDevision1 ?? '',
                "postcodePrimaryLow": address.postcodePrimaryLow ?? '',
                "postcodeExtendedLow": "",
                "urbanization": "",
                "countryCode": address.countryCode ?? ''
              }
            }
    })
}

export const getProtections = async function(){
  return axios.get(process.env.REACT_APP_API_URL + '/api/resources/insuranceoptions')
}

export const getPresets = async function(){
  return axios.get(process.env.REACT_APP_API_URL + '/api/resources/packagepresets')
}

export const getCategories = async function(){
  return axios.get(process.env.REACT_APP_API_URL + '/api/resources/packagecategories')
}

export const getPurposes = async function(){
  return axios.get(process.env.REACT_APP_API_URL + '/api/resources/packagepurpose')
}

export const getRates = async function(boxesObj,trips,purpose){
  const {packages,shipmentTotalWeight,numOfPieces} = boxesObj  
  return axiosAuth.post(process.env.REACT_APP_API_URL + '/api/shipment/newshipment',{
    shipmentId: 0,
    shipperId: 0,
    tripeType: trips?.length,
    measurementUnit: "in/lbs",
    rateRequest: {
      request: {
        transactionReference: "n/a"
      },
      shipment: {
        originRecordTransactionTimestamp: new Date(),
        shippingAddresses: trips,
        numOfPieces,
        packages,
        shipmentTotalWeight
      }
    },
    "purpose": purpose?.name
  },{withCredentials:true})
}

export const getQuickRates = async function(box,trip){
  const to = getFormattedAddressByPlace(trip?.to)
  const from = getFormattedAddressByPlace(trip?.from)
  // const from = {
  //   "addressLine": [
  //     "33 Santa Barbara Dr"
  //   ],
  //   "city": "Aliso Viejo",
  //   "stateProvinceCode": "CA",
  //   "postalCode": "92656",
  //   "countryCode": "US"
  // };
  // const to = {
  //   "addressLine": [
  //     "1535 Broadway"
  //   ],
  //   "city": "New York",
  //   "stateProvinceCode": "NY",
  //   "postalCode": "10036",
  //   "countryCode": "US"
  // }
  return axios.post(process.env.REACT_APP_API_URL + '/api/resources/quickrates',
    {
      "contentType": "",
      "shipFrom": {
        "addressLine": [from.street_number +' '+ from.route],
        "city": from.politicalDevision2,
        "stateProvinceCode": from.politicalDevision1,
        "postalCode": from.postcodePrimaryLow,
        "countryCode": from.countryCode
      },
      "shipTo": {
        "addressLine": [to.street_number +' '+ to.route],
        "city": to.politicalDevision2,
        "stateProvinceCode": to.politicalDevision1,
        "postalCode": to.postcodePrimaryLow,
        "countryCode": to.countryCode
      },
      "dimension": {
        "unitOfMeasurement": {
          "code": 'IN',
          "description": ""
        },
        "length": box.length.toString(),
        "width": box.width.toString(),
        "height": box.height.toString()
      },
      "packageWeight": {
        "unitOfMeasurement": {
          "code": 'LBS',
          "description": ""
        },
        "weight": box.weight.toString()
      }
  }
  // {
  //   "contentType": "",
  //   "shipFrom": {
  //     "addressLine": [
  //       "2410 Wardlow Rd STE 109 Corona"
  //     ],
  //     "city": "Corona",
  //     "stateProvinceCode": "CA",
  //     "postalCode": "92878",
  //     "countryCode": "US"
  //   },
  //   "shipTo": {
  //     "addressLine": [
  //       "714 Franklin St #100"
  //     ],
  //     "city": "Oakland",
  //     "stateProvinceCode": "CA",
  //     "postalCode": "94612",
  //     "countryCode": "US"
  //   },
  //   "dimension": {
  //     "unitOfMeasurement": {
  //       "code": "IN",
  //       "description": ""
  //     },
  //     "length": "43",
  //     "width": "11",
  //     "height": "32"
  //   },
  //   "packageWeight": {
  //     "unitOfMeasurement": {
  //       "code": "LBS",
  //       "description": ""
  //     },
  //     "weight": "8"
  //   }
  // }
  )
}

export const updateShippingDate = async function(shipmentId,stageNo,shipmentDate){
  console.log("ShipmentDate>>",shipmentDate)
  shipmentDate = formatDateToLocalISOString(shipmentDate)
  return axiosAuth.post(process.env.REACT_APP_API_URL + '/api/shipment/updateshippingdate',{
    shipmentDate,
    stageNo,
    shipmentId
  })
}

export const updateShipmentService = async function(shipmentId,shippingServiceId){
  return axiosAuth.get(process.env.REACT_APP_API_URL + '/api/shipment/updateservice?shipmentId='+shipmentId+'&shippingServiceId='+shippingServiceId)
}

export const updatePickup = async function(object){
  return axiosAuth.post(process.env.REACT_APP_API_URL + '/api/shipment/pickup',object)
}

export const validateCookie = async function(){
  return axiosAuth.get(process.env.REACT_APP_API_URL + '/api/resources/cookievalue')
}

export const getShipmentAddresses = async function(shipmentId){
  return axiosAuth.get(process.env.REACT_APP_API_URL + '/api/shipment/addresses?ShipmentId='+shipmentId)
}

export const updateShipmentAddresses = async function(shipmentId,obj){  
  return axiosAuth.post(process.env.REACT_APP_API_URL + '/api/shipment/update/addresses?ShipmentId='+shipmentId,obj)
}

export const getShipmentPackages = async function(shipmentId){
  return axiosAuth.get(process.env.REACT_APP_API_URL + '/api/shipment/packages?ShipmentId='+shipmentId)
}

export const updateShipmentPackages = async function(shipmentId,obj){
  return axiosAuth.post(process.env.REACT_APP_API_URL + '/api/shipment/update/packages?ShipmentId='+shipmentId,obj)
}

export const updateShipmentLabel = async function(shipmentId){
  return axiosAuth.post(process.env.REACT_APP_API_URL + '/api/shipment/label',{
      shipmentId,
      imageFormateCode: "GIF",
      language: "en"
  })
}

export const updateGuestUser = async function(obj){
  return axiosAuth.post(process.env.REACT_APP_API_URL + '/api/auth/register/guest',obj)
}

export const completeOrder = async function(paymentIntentId){
  return axiosAuth.get(process.env.REACT_APP_API_URL + '/api/shipment/complete?paymentIntentId='+paymentIntentId)
}