import React, { useEffect, useState } from 'react'
import { boxesList,boxesModal,tripsModal } from '../utils/data'
import useApp from '../store/useApp'

const paths = ['One-Way','Round-Trip','Multi-City']

function Filters({handleUnitsChange,tripsHelperRef,boxesHelperRef}) {
    const {trips,units} = useApp()
    const [numberOfBoxes,setNumberOfBoxes] = useState({name:'1 Package',value:1})
    const [openPath,setOpenPath] = useState(false)
    const [openBoxes,setOpenBoxes] = useState(false)
    const [openUnits,setOpenUnits] = useState(false)
    const [numberOfTrips,setNumberOfTrips] = useState('One-Way')

    function handleNumberOfBoxesChange(value){
        const boxesLength = boxesHelperRef.current.form.values.boxes.length
        const diff = value.value - boxesLength
        if(diff > 0){
            for (let index = 0; index < diff; index++) {
                let boxesModalUpdated = boxesModal
                boxesModalUpdated.id = boxesModal.id + index
                boxesHelperRef.current.push(boxesModalUpdated)
            }
        }else if(diff < 0){
            for (let index = 0; index < Math.abs(diff); index++) {
                boxesHelperRef.current.pop()
            }
        }
        setNumberOfBoxes(value)
        setOpenBoxes(false)
    }

    function handleTripChange(value){
        const tripsLength = tripsHelperRef.current.form.values.trips.length
        switch (value) {
            case 'One-Way':
                for (let index = 0; index < (tripsLength - 1); index++) {
                    tripsHelperRef.current.pop()
                }
                setNumberOfTrips('One-Way')
                break;
            case 'Round-Trip':
                if(tripsLength < 2){
                        tripsHelperRef.current.push(tripsModal)
                }else if(tripsLength > 2){
                    for (let index = 0; index < (tripsLength - 2); index++) {
                        tripsHelperRef.current.pop()
                    }
                }
                setNumberOfTrips('Round-Trip')
                break;
            case 'Multi-City':
                let diff = 3 - tripsLength
                if(tripsLength < 3){
                    for (let index = 0; index < diff; index++) {
                        let tripsModalUpdated = tripsModal
                        tripsModalUpdated.id = tripsModal + index
                        tripsHelperRef.current.push(tripsModalUpdated)
                    } 
                }
                setNumberOfTrips('Multi-City')
                break;
            default:
                break;
        }
        setOpenPath(false)
    }

    return (
        <div>
            <p className='mb-4 m-0 font-bold text-black'>SHIPMENT OPTIONS</p>
            <div className="flex flex-wrap flex-row gap-3">
                <div className="relative grow">
                    <input
                        id="number_of_trips"
                        type="text"
                        name="trips"
                        value={numberOfTrips}
                        onFocus={()=>(setOpenPath(true))}
                        className="text-[#00263A] border peer block w-full appearance-none cursor-pointer rounded-lg border-[#ccc] pl-5 py-5 pr-0 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:border-b-0 focus:rounded-bl-[0px] focus:rounded-br-[0px] focus:outline-none focus:ring-0"
                        readOnly
                        />
                    <label
                        htmlFor="number_of_trips"
                        className="absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent">
                        Trips
                    </label>
                    <span onClick={(e)=>{setOpenPath(!openPath);
                        if(!openPath){
                            e.currentTarget.parentNode.querySelector('input').focus();
                        }
                    }} className='absolute right-5 top-[50%] translate-y-[-50%] text-[#808080] peer-focus:text-[#00263A] text-[14px] leading-[20px] text-medium peer-focus:-rotate-180 transform duration-300 cursor-pointer'>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-8 svg-inline--fa fa-angle-down">
                            <path fill="currentColor" d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" className=""></path>
                        </svg>
                    </span>
                    <div className='custom-overlay' style={{display: openPath ? "block" : "none"}} onClick={()=>setOpenPath(!openPath)}></div>
                    <div style={{display: openPath ? "block":"none"}} className="absolute jb-select-dropdown transform duration-300 shadow-[0_6px_10px_rgba(0,0,0,0.2)] top-full z-40 w-full lef-0 rounded-bl-lg rounded-br-lg border border-[#00263A] border-t-0 overflow-y-auto max-h-52 min-w-133">
                        <div className="flex flex-col w-full">
                            {paths.map((item,index)=>{
                                return <div onClick={()=>handleTripChange(item)} className="cursor-pointer w-full bg-white" key={index}>
                                    <div className="flex w-full items-center p-2 pl-2 border-transparent hover:bg-brand-200 border-l-2 relative">
                                        <div className="w-full items-center flex">
                                            <div className="mx-2 text-[#00263A] text-[14px] leading-[20px] text-medium">{item}</div>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
                <div className="relative grow">
                    <input
                        id="number_of_packages"
                        type="text"
                        name="packages"
                        value={numberOfBoxes?.name}
                        onFocus={()=>(setOpenBoxes(true))}
                        className="text-[#00263A] border peer block w-full appearance-none cursor-pointer rounded-lg border-[#ccc] pl-5 py-5 pr-0 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:border-b-0 focus:rounded-bl-[0px] focus:rounded-br-[0px] focus:outline-none focus:ring-0"
                        readOnly
                        />
                    <label
                        htmlFor="number_of_packages"
                        className="absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent">
                        Packages
                    </label>
                    <span onClick={(e)=>{setOpenBoxes(!openBoxes);
                        if(!openBoxes){
                            e.currentTarget.parentNode.querySelector('input').focus();
                        }
                    }} className='absolute right-5 top-[50%] translate-y-[-50%] text-[#808080] peer-focus:text-[#00263A] text-[14px] leading-[20px] text-medium peer-focus:-rotate-180 transform duration-300 cursor-pointer'>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-8 svg-inline--fa fa-angle-down">
                            <path fill="currentColor" d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" className=""></path>
                        </svg>
                    </span>
                    <div className='custom-overlay' style={{display: openBoxes ? "block" : "none"}} onClick={()=>setOpenBoxes(!openBoxes)}></div>
                    <div style={{display: openBoxes ? "block":"none"}} className="absolute jb-select-dropdown transform duration-300 shadow-[0_6px_10px_rgba(0,0,0,0.2)] top-full z-40 w-full lef-0 rounded-bl-lg rounded-br-lg border border-[#00263A] border-t-0 overflow-y-auto max-h-52 min-w-133">
                        <div className="flex flex-col w-full">
                            {boxesList.map((item,index)=>{
                                return <div onClick={()=>handleNumberOfBoxesChange(item)} className="cursor-pointer w-full bg-white" key={index}>
                                    <div className="flex w-full items-center p-2 pl-2 border-transparent hover:bg-brand-200 border-l-2 relative">
                                        <div className="w-full items-center flex">
                                            <div className="mx-2 text-[#00263A] text-[14px] leading-[20px] text-medium">{item?.name}</div>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
                {/* <div className="relative grow">
                    <input
                        id="units"
                        type="text"
                        name="packages"
                        value={units.length + ' / ' + units.weight}
                        onFocus={()=>(setOpenUnits(true))}
                        className="text-[#00263A] border peer block w-full appearance-none cursor-pointer rounded-lg border-[#ccc] pl-5 py-5 pr-0 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:border-b-0 focus:rounded-bl-[0px] focus:rounded-br-[0px] focus:outline-none focus:ring-0"
                        readOnly
                        />
                    <label
                        htmlFor="units"
                        className="absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent">
                        Units
                    </label>
                    <span onClick={(e)=>{setOpenUnits(!openUnits);
                        if(!openUnits){
                            e.currentTarget.parentNode.querySelector('input').focus();
                        }
                    }} className='absolute right-5 top-[50%] translate-y-[-50%] text-[#808080] peer-focus:text-[#00263A] text-[14px] leading-[20px] text-medium peer-focus:-rotate-180 transform duration-300 cursor-pointer'>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-8 svg-inline--fa fa-angle-down">
                            <path fill="currentColor" d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" className=""></path>
                        </svg>
                    </span>
                    <div className='custom-overlay' style={{display: openUnits ? "block" : "none"}} onClick={()=>setOpenUnits(!openUnits)}></div>
                    <div style={{display: openUnits ? "block":"none"}} className="absolute jb-select-dropdown transform duration-300 shadow-[0_6px_10px_rgba(0,0,0,0.2)] top-full z-40 w-full lef-0 rounded-bl-lg rounded-br-lg border border-[#00263A] border-t-0 overflow-y-auto max-h-52 min-w-133">
                        <div className="flex flex-col w-full">
                            <div onClick={()=>{handleUnitsChange('0');setOpenUnits(false)}} className="cursor-pointer w-full bg-white">
                                <div className="flex w-full items-center p-2 pl-2 border-transparent hover:bg-brand-200 border-l-2 relative">
                                    <div className="w-full items-center flex">
                                        <div className="mx-2 text-[#00263A] text-[14px] leading-[20px] text-medium">{'in / lbs'}</div>
                                    </div>
                                </div>
                            </div>
                            <div onClick={()=>{handleUnitsChange('1');setOpenUnits(false)}} className="cursor-pointer w-full bg-white">
                                <div className="flex w-full items-center p-2 pl-2 border-transparent hover:bg-brand-200 border-l-2 relative">
                                    <div className="w-full items-center flex">
                                        <div className="mx-2 text-[#00263A] text-[14px] leading-[20px] text-medium">{'cm / kgs'}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default Filters