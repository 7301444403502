import React from 'react'

function Loading() {
  return (
    <div id="throbber" className="throbber-container" style={{display:"flex"}}>
        <img src="https://cdn.shopify.com/s/files/1/0795/8813/1105/files/logo-icon-loader.png" alt="loading..." id="throbberImage0" draggable="false" title="loading..." className="fa-spin"/>
    </div>
  )
}

export default Loading