import React, { useState } from 'react'
import OrderSummary from '../components/OrderSummary'
import useApp from '../store/useApp'

function FormLayout({children}) {
   const {setActiveStep,activeStep} = useApp()
   const [trip,setTrip] = useState('One-Way')
   const [openDropdown,setOpenDropdown] = useState(false)
   const [steps,setSteps] = useState([
      {
         label: 'Shipment Info',
         step:'search',
         active: false},
      {
         label: 'Input Addresses',
         step:'details',
         active: false
      },
      {
         label: 'Choose Options',
         step:'costs',
         active: false
      },
      {
         label: 'Billing Info',
         step:'payments',
         active: false
      }
   ])

   function handleStepClick(step){
      if(step.active) setActiveStep(step.step)
   }

   function renderSteps(steps){
      let visited =  true;
      return steps.map((step,index)=>{
         if(step.step === activeStep) visited = false;

         if(visited){
            return <div key={index} className={`breadcrumb-item breadcrumb-visited ${step.active ? 'pointer' : ''} ${step.step === activeStep ? 'breadcrumb-current' : ''}`} onClick={(e)=>handleStepClick(step)}>
            <span>
               <div className='breadcrumb-icon'><svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 10 9" fill="none">
                  <line x1="0.640184" y1="4.88461" x2="2.90101" y2="6.76863" stroke="black" strokeWidth="2"/>
                  <line x1="0.640184" y1="4.88461" x2="2.90101" y2="6.76863" stroke="black" strokeWidth="2"/>
                  <line x1="0.640184" y1="4.88461" x2="2.90101" y2="6.76863" stroke="white" strokeWidth="2"/>
                  <path d="M2.69815 7.94897L9.09134 1" stroke="black" strokeWidth="2"/>
                  <path d="M2.69815 7.94897L9.09134 1" stroke="black" strokeWidth="2"/>
                  <path d="M2.69815 7.94897L9.09134 1" stroke="white" strokeWidth="2"/>
            </svg></div>
            </span>
            <span>{step.label}</span>
         </div>
         }else{
            return  <div key={index} className={`breadcrumb-item ${step.active ? 'pointer' : ''} ${step.step === activeStep ? 'breadcrumb-current' : ''}`}>
               <span>{index + 1}</span>
               <span>{step.label}</span>
            </div>
         }
      })
   }

   const handleTripChange = function(value){
      setTrip(value)
      setOpenDropdown(false)
   }

   useState(()=>{
      let flag = true;
      let allSteps = steps.map((step)=>{
         if(!flag) return {...step,active:false}
         if(step.step === activeStep) flag = false
         return {...step,active:true}
         
      });
      setSteps(allSteps)
   },[activeStep])

  return (
    <div data-fetch-key="Ship:0" className="ship-page-wrapper" style={{paddingBottom:'5rem'}}>
    <div id="shipHeader" className="container ship-header m-auto pt-9 pb-9">
       <div className="text-center">
          <h1 className="text-[42px] font-extrabold uppercase text-black">SCHEDULE YOUR SHIPMENT</h1>
          <div id="shipProgress" className="breadcrumb-container mt-1">
            {renderSteps(steps)}
          </div>
       </div>
    </div>
    <div className="container m-auto" style={{marginBottom:'2em'}}>
       <div id="costs-step" className="ship-form-wrapper" style={{padding:'30px'}}>
          <div className="flex flex-col md:flex-row justify-between">
             {children}
             {activeStep !== 'details' && activeStep !== 'search' && <OrderSummary/>}
          </div>
       </div>
    </div>
 </div>
  )
}

export default FormLayout