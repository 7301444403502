import { useEffect, useState } from 'react';
import './App.css';
import Contents from './screens/Contents';
import Costs from './screens/Costs';
import Details from './screens/Details';

import Search from './screens/Search';
import useApp from './store/useApp';
import Payments from './screens/Payments';
import { validateCookie } from './helpers/apiRequests';
import Loading from './components/loaders/Loading';
import Success from './screens/Success';
import GoogleAutoComplete from './helpers/GoogleMapsClassService';

function App() {
  const {activeStep,isLoading} = useApp()

  const handleCookieValidation = async function(e){
    try {
      const data = await validateCookie()
      console.log("Data",data)
    } catch (error) {
      console.log("Error",error)
    }
  }

  const renderStages = function(stage){
    switch (stage) {
      case 'search':
        return <Search/>
      case 'costs':
        return <Costs/>
      case 'details':
        return <Details/>
      case 'contents':
        return <Contents/>
      case 'payments':
        return <Payments/>
      case 'success':
        return <Success/>
      default:
        return ''
    }
  }

  useEffect(()=>{
    console.log("Loading...")
    const AutoComplete = new GoogleAutoComplete();
    AutoComplete.initMapAPI();
  },[])

  return (
    <>
      {renderStages(activeStep)}
      {isLoading && <Loading/>}
      {/* <div>
        <button onClick={handleCookieValidation}>Validate Cookie</button>
      </div> */}
    </>
  );
}

export default App;
