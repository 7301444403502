import React, { useEffect, useState } from 'react'
import useApp from '../store/useApp'

function OrderSummary() {
   const {orderSummary,activeSearch} = useApp()
   const [openOrderDetails,setOpenOrderDetails] = useState(false)
   const [openOrderSummary,setOpenOrderSummary] = useState(false)
   const [subTotal,setSubtotal] = useState(0)

   function renderAddress(address){
      return address.street_address + ", "+ address.city + ", " + address.state + " " + address.postal_code + ", "+ address.country;
   }

   useEffect(()=>{
      let subTotal = orderSummary.reduce((acc,item)=>acc + item.subTotal, 0)
      setSubtotal(subTotal.toFixed(2))
      console.log("Active Search",activeSearch)
   },[orderSummary])

  return (
    <div id="order-summary-column" className={`right-column basis-5/12 px-1 md:px-4`}>
    <div className="order-summary-details p-5 bg-[#f1f1f1] rounded mb-2 text-[13px]">
       <div className="font-bold text-[14] uppercase py-5 text-[#00263A]">
          ORDER SUMMARY 
          <button aria-label="Open" type="button" onClick={()=>setOpenOrderSummary(!openOrderSummary)} className="block md:hidden float-right md:text-gray-400">
             {!openOrderSummary ? (<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-angle-down">
                <path fill="currentColor" d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" className=""></path>
             </svg>) :
             (<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-angle-up">
                <path fill="currentColor" d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z" className=""></path>
             </svg>)}
          </button>
       </div>
       {orderSummary?.map((summary,index)=>{
         return <ul className="text-[14px] text-[#00263A] font-semibold pb-5 md:!block" key={index} style={{display: openOrderSummary ? "block":"none"}}>
               {orderSummary.length > 1 && <li className="flex justify-between mb-2">
                  <div className="text-[#00263A] font-bold text-[14px] uppercase">Stage {index + 1}</div>
               </li>}
               <li className="flex justify-between mb-2">
                  <div>Shipping</div>
                  <div>${summary.shippingRate?.toFixed(2)}</div>
               </li>
               <li className="flex justify-between mb-2">
                  <div>Protection</div>
                  <div>${summary.insurance?.toFixed(2)}</div>
               </li>
               {summary?.pickupRate === 0 ? (<li className="flex justify-between mb-2">
                  <div>Drop Off</div>
                  <div>${summary?.dropoffRate?.toFixed(2)}</div>
               </li>) : (
               <li className='flex justify-between mb-2'>
                  <div>Pickup</div>
                  <div>${summary?.pickupRate?.toFixed(2)}</div>
               </li>
               )}
            </ul>
       })}
  
       {/* <ul className="text-sm font-semibold pb-3 md:!block" style={{display: "none !important"}}>
          <li className="flex justify-between mb-2">
             <div className="font-bold text-sm uppercase">Stage 2</div>
          </li>
          <li className="flex justify-between mb-2">
             <div>Shipping</div>
             <div>$184.95</div>
          </li>
          <li className="flex justify-between mb-2">
             <div>Protection ($12500)</div>
             <div>$193.60</div>
          </li>
          <li className="flex justify-between mb-2">
             <div>Drop Off</div>
             <div>$0.00</div>
          </li>
       </ul> */}
       <ul className="order-summary text-[#00263A] text-[14px] font-semibold pb-5 md:border-dashed md:border-b md:border-[#ccc]">
          <li className="flex justify-between mb-2">
             <div>Subtotal (USD)</div>
             <div className="font-bold">${subTotal}</div>
          </li>
       </ul>
       <div className="shipping-details font-semibold pt-3 md:!block" style={{display: openOrderSummary ? "block" : "none"}}>
          <div className="flex justify-between pt-2 mb-5 text-black">
             <div className="uppercase text-[13px] font-semibold text-[#888]">Shipping Details</div>
             <button aria-label="Open" type="button" className="text-[#888]" onClick={(e)=>setOpenOrderDetails(!openOrderDetails)}>
                {!openOrderDetails ? (<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-angle-down">
                   <path fill="currentColor" d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" className=""></path>
                </svg>) :
                (<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-angle-up">
                   <path fill="currentColor" d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z" className=""></path>
                </svg>)}
             </button>
          </div>
          <div style={{display: openOrderDetails ? "block":"none"}}>
             {activeSearch?.stages.map((trip,index)=>{
               return <div className="shipping-details-wrapper text-black" key={index}>
                     {activeSearch?.stages?.length > 1 && <div className="uppercase py-5 text-[#888]">Stage {index+1}</div>}
                  <ul>
                     <li className="flex font-medium justify-between mb-5 text-[13px] text-[#888]">Ship From: {renderAddress(trip.from)} </li>
                     <li className="flex font-medium justify-between mb-5 text-[13px] text-[#888]">Ship To: {renderAddress(trip.to)} </li>
                     {/* <li className="flex font-medium justify-between mb-2 text-[#888]">Ship Date: </li>
                     <li className="flex font-medium justify-between mb-2 text-[#888]">Delivery: </li> */}
                     {activeSearch?.boxes?.map((box,innerIndex)=>{
                        return <li className="flex font-medium justify-between mb-5 text-[13px] text-[#888]" key={innerIndex}>Box {innerIndex + 1}: {box?.preset} // {box?.length} x {box?.width} x {box?.height}{box?.linearUnit} {box?.weight}{box?.weightUnit}</li>
                     })}
                  </ul>
               </div>
             })}
             {/* <div className="shipping-details-wrapper text-black">
                <div className="uppercase py-3 text-[#888]">Stage 2</div>
                <ul>
                   <li className="flex font-medium justify-between mb-2 text-[#888]">Ship From: harry reid international airport </li>
                   <li className="flex font-medium justify-between mb-2 text-[#888]">Ship To: new york-new york hotel &amp; casino </li>
                   <li className="flex font-medium justify-between mb-2 text-[#888]">Ship Date: </li>
                   <li className="flex font-medium justify-between mb-2 text-[#888]">Delivery: </li>
                   <li className="flex font-medium justify-between mb-2 text-[#888]">Box 1: BIKND Helium Bike Case // 50 x 12 x 34in 60lb</li>
                   <li className="flex font-medium justify-between mb-2 text-[#888]">Box 2: BIKEFLIGHTS Bike Box SMALL // 43 x 10 x 24in 55lb</li>
                </ul>
             </div> */}
          </div>
       </div>
    </div>
 </div>
  )
}

export default OrderSummary