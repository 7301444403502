import React, { useEffect, useState } from 'react'
import Datepicker from "react-datepicker"; 
import { updateShippingDate,updateShipmentService } from '../helpers/apiRequests';
import SelectArrows from './SelectArrows';
import useApp from '../store/useApp';
import { addBusinessDays } from '../helpers/generals';

const pickups = [
   // {
   //    name: 'Schedule a monitored pickup by a UPS driver',
   //    value: 1
   // },
   {
      name: 'Drop off at a UPS Store or Customer Center (NO CHARGE)',
      value: 2
   }
]

const pickupTypes = [
   {
      name: 'Oncall',
      value: 'oncall'
   },
   {
      name: 'Smart',
      value: 'smart'
   },
   {
      name: 'Both',
      value: 'both'
   }
]

const serviceDates = [
   {
      name: 'Same-Day Pickup',
      value: '01'
   },{
      name: 'Future-Day Pickup',
      value: '02'
   },{
      name: 'A Specific-Day Pickup',
      value: '03'
   }   
]

function CostStage({stageCount,stage,errors,touched,shipmentId,services,index,setFieldValue}) {

   const {setLoading,activeSearch,updateShippingRate} = useApp()
    const [openPickup,setOpenPickup] = useState(false)
    const [openPickupType,setOpenPickupType] = useState(false)
    const [openServiceType,setOpenServiceType] = useState(false)
    const isWeekday = (date) => {
       const day = date.getDay();
       return day !== 0 && day !== 6;
     };

     const handlePickupChange = function(value){
      let item = pickups.filter((item)=>item.value === value.value)[0]
      setFieldValue(`stages[${index}].pickup`,item)
      setOpenPickup(false)
     }

     const handlePickupTypeChange = function(value){
      let item = pickupTypes.filter((item)=>item.value === value.value)[0]
      setFieldValue(`stages[${index}].pickupType`,item)
      setOpenPickupType(false)
     }

     const handleServiceDateChange = function(value){
      setFieldValue(`stages[${index}].serviceDate`,value)
      setOpenServiceType(false)
     }
 
     const handleDateChange = async function(date){
      console.log("Date>>",date)
      setLoading(true)
       try {
          await updateShippingDate(shipmentId,(index+1),date)
          setLoading(false)
       } catch (error) {
          console.log("Error",error)
          setLoading(false)
       }
       setFieldValue(`stages[${index}].shipmentDate`,date)
     }

     const handlePickupDateChange = async function(date){
      setFieldValue(`stages[${index}].pickupDate`,date)
     }

     const handleReadyAtChange = async function(date){
      setFieldValue(`stages[${index}].readyAt`,date)
     }

     const handleCloseAtChange = async function(date){
      setFieldValue(`stages[${index}].closeAt`,date)
     }

     const renderDeliveryDate = function(date,type){
         const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
         let result = '';      
         switch (type) {
            case '01':
               result = addBusinessDays(date,1);
               return new Intl.DateTimeFormat('en-US', options).format(result)
            case '02':
               result = addBusinessDays(date,2);
               return new Intl.DateTimeFormat('en-US', options).format(result)
            case '03':
               result = addBusinessDays(date,4);
               return new Intl.DateTimeFormat('en-US', options).format(result)
            default:
               return ''
         }
     }

     const handleServiceChange = async function(i){
      let newServices = services.map((service,innerIndex)=>{
         return (innerIndex === i) ? {...service,isSelected:true} : {...service, isSelected: false}
      })
      setLoading(true)
      try {
         await updateShipmentService(shipmentId,newServices[i]?.id)
         updateShippingRate(newServices[i].totalPrice,index)
         setFieldValue(`stages[${index}].services`,newServices)
         setLoading(false)
      } catch (error) {
         console.log("Error",error)
         setLoading(false)
      }
     }

     useEffect(()=>{
      console.log("Active search",activeSearch)
     },[])

  return (
    <div className="ship-stage-wrapper mb-5">
    <div className="font-medium text-[#000000] text-[16px] leading-[24px] uppercase mb-7">{stageCount > 1 ? `Stage ${index + 1} -` : ''} Shipment Details and Options</div>
    {/* <div className="font-semibold text-tiny pb-3">{activeSearch.stages[index]?.from?.formatted_address} To {activeSearch.stages[index]?.to?.formatted_address}</div> */}
    <div className="pickup-dropoff">
      <div className="relative w-full mb-5">
            <input
                id="pickup_or_dropoff" type="text" name="pickup_or_dropoff" value={stage?.pickup?.name}
                onFocus={()=>(setOpenPickup(true))}
                className={`text-[#00263A] border peer block w-full appearance-none cursor-pointer rounded-lg border-[#ccc] p-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:border-b-0 focus:rounded-bl-[0px] focus:rounded-br-[0px] focus:outline-none focus:ring-0 ${(errors.stages?.length && touched.stages && errors.stages[index]?.pickup && touched.stages[index]?.pickup) && 'bf-form-error-col'}`}
                placeholder=' ' autoComplete='off' readOnly
                />
            <label
                htmlFor="pickup_or_dropoff"
                className="absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent">
                Pickup Or Drop Off
            </label>
            <span onClick={(e)=>{setOpenPickup(!openPickup);
                if(!openPickup){
                    e.currentTarget.parentNode.querySelector('input').focus();
                }
            }} className='absolute right-5 top-[50%] translate-y-[-50%] text-[#808080] peer-focus:text-[#00263A] text-[14px] leading-[20px] text-medium peer-focus:-rotate-180 transform duration-300 cursor-pointer'>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-8 svg-inline--fa fa-angle-down">
                    <path fill="currentColor" d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" className=""></path>
                </svg>
            </span>
            <div className='custom-overlay' style={{display: openPickup ? "block" : "none"}} onClick={()=>setOpenPickup(!openPickup)}></div>
            <div style={{display: openPickup ? "block":"none"}} className="absolute jb-select-dropdown transform duration-300 shadow-[0_6px_10px_rgba(0,0,0,0.2)] top-full z-40 w-full lef-0 rounded-bl-lg rounded-br-lg border border-[#00263A] border-t-0 overflow-y-auto max-h-52 min-w-133">
                <div className="flex flex-col w-full">
                    {pickups.map((pickup,index)=>{
                        return <div onClick={()=>handlePickupChange(pickup)} className="cursor-pointer w-full bg-white" key={index}>
                            <div className="flex w-full items-center p-2 pl-2 border-transparent hover:bg-brand-200 border-l-2 relative">
                                <div className="w-full items-center flex">
                                    <div className="mx-2 text-[#00263A] text-[14px] leading-[20px] text-medium">{pickup.name}</div>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
      </div>

      {stage?.pickup?.value === 1 && <>
         <div className="relative w-full mb-5">
            <input
                id="pickup_type" type="text" name="pickup_type" value={stage?.pickupType?.name}
                onFocus={()=>(setOpenPickupType(true))}
                className={`text-[#00263A] border peer block w-full appearance-none cursor-pointer rounded-lg border-[#ccc] p-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:border-b-0 focus:rounded-bl-[0px] focus:rounded-br-[0px] focus:outline-none focus:ring-0 ${(errors.stages?.length && touched.stages && errors.stages[index]?.pickupType && touched.stages[index]?.pickupType) && 'bf-form-error-col'}`}
                placeholder=' ' autoComplete='off' readOnly
                />
            <label
                htmlFor="pickup_type"
                className="absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent">
                Pickup Type
            </label>
            <span onClick={(e)=>{setOpenPickupType(!openPickupType);
                if(!openPickupType){
                    e.currentTarget.parentNode.querySelector('input').focus();
                }
            }} className='absolute right-5 top-[50%] translate-y-[-50%] text-[#808080] peer-focus:text-[#00263A] text-[14px] leading-[20px] text-medium peer-focus:-rotate-180 transform duration-300 cursor-pointer'>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-8 svg-inline--fa fa-angle-down">
                    <path fill="currentColor" d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" className=""></path>
                </svg>
            </span>
            <div className='custom-overlay' style={{display: openPickupType ? "block" : "none"}} onClick={()=>setOpenPickupType(!openPickupType)}></div>
            <div style={{display: openPickupType ? "block":"none"}} className="absolute jb-select-dropdown transform duration-300 shadow-[0_6px_10px_rgba(0,0,0,0.2)] top-full z-40 w-full lef-0 rounded-bl-lg rounded-br-lg border border-[#00263A] border-t-0 overflow-y-auto max-h-52 min-w-133">
                <div className="flex flex-col w-full">
                    {pickupTypes.map((type,index)=>{
                        return <div onClick={()=>handlePickupTypeChange(type)} className="cursor-pointer w-full bg-white" key={index}>
                            <div className="flex w-full items-center p-2 pl-2 border-transparent hover:bg-brand-200 border-l-2 relative">
                                <div className="w-full items-center flex">
                                    <div className="mx-2 text-[#00263A] text-[14px] leading-[20px] text-medium">{type.name}</div>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
         </div>
         <div className="relative w-full mb-5">
            <input
                id={`stages[${index}].serviceDate`} type="text" name={`stages[${index}].serviceDate`} value={stage?.serviceDate?.name}
                onFocus={()=>(setOpenServiceType(true))}
                className={`text-[#00263A] border peer block w-full appearance-none cursor-pointer rounded-lg border-[#ccc] p-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:border-b-0 focus:rounded-bl-[0px] focus:rounded-br-[0px] focus:outline-none focus:ring-0 ${(errors.stages?.length && touched.stages && errors.stages[index]?.serviceDate && touched.stages[index]?.serviceDate) && 'bf-form-error-col'}`}
                placeholder=' ' autoComplete='off' readOnly
                />
            <label
                htmlFor={`stages[${index}].serviceDate`}
                className="absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent">
                Service Date Option
            </label>
            <span onClick={(e)=>{setOpenServiceType(!openServiceType);
                if(!openServiceType){
                    e.currentTarget.parentNode.querySelector('input').focus();
                }
            }} className='absolute right-5 top-[50%] translate-y-[-50%] text-[#808080] peer-focus:text-[#00263A] text-[14px] leading-[20px] text-medium peer-focus:-rotate-180 transform duration-300 cursor-pointer'>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-8 svg-inline--fa fa-angle-down">
                    <path fill="currentColor" d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" className=""></path>
                </svg>
            </span>
            <div className='custom-overlay' style={{display: openServiceType ? "block" : "none"}} onClick={()=>setOpenServiceType(!openServiceType)}></div>
            <div style={{display: openServiceType ? "block":"none"}} className="absolute jb-select-dropdown transform duration-300 shadow-[0_6px_10px_rgba(0,0,0,0.2)] top-full z-40 w-full lef-0 rounded-bl-lg rounded-br-lg border border-[#00263A] border-t-0 overflow-y-auto max-h-52 min-w-133">
                <div className="flex flex-col w-full">
                    {serviceDates.map((serviceDate,index)=>{
                        return <div onClick={()=>handleServiceDateChange(serviceDate)}className="cursor-pointer w-full bg-white" key={index}>
                            <div className="flex w-full items-center p-2 pl-2 border-transparent hover:bg-brand-200 border-l-2 relative">
                                <div className="w-full items-center flex">
                                    <div className="mx-2 text-[#00263A] text-[14px] leading-[20px] text-medium">{serviceDate.name}</div>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
         </div>
       {(stage?.serviceDate?.value === '03' || stage?.serviceDate?.value === '02' || stage?.serviceDate?.value === '01') && <div className='flex flex-row justify-between md:gap-2 flex-wrap lg:flex-nowrap'>
         {(stage?.serviceDate?.value === '03' || stage?.serviceDate?.value === '02') &&
         <div className={`relative mb-5 lg:basis-1/3 basis-[100%] grow`}>
         <Datepicker selected={stage?.pickupDate}
            onChange={(date)=>handlePickupDateChange(date)} 
            filterDate={isWeekday} 
            minDate={new Date()}
            dateFormat={'MMM dd, yyyy'}
            className={`text-[#00263A] border peer block w-full appearance-none rounded-lg border-[#ccc] p-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:outline-none focus:ring-0 ${(errors.stages?.length && touched.stages && errors.stages[index]?.pickupDate && touched.stages[index]?.pickupDate) && '!border-[#FF2853]'}`} 
            wrapperClassName='peer'
            placeholderText='MMM dd, yyyy'/>
      <label
         htmlFor="user_email"
         className={`absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent ${(errors.stages?.length && touched.stages && errors.stages[index]?.pickupDate && touched.stages[index]?.pickupDate) && '!text-[#FF2853]'}`}>
         Pickup Date
      </label>
      <span className={`absolute right-5 top-[50%] translate-y-[-50%] text-[#808080] peer-focus:text-[#00263A] text-[14px] leading-[20px] text-medium`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width="1em" height="1em">
               <path d="M940.218182 107.054545h-209.454546V46.545455h-65.163636v60.50909H363.054545V46.545455H297.890909v60.50909H83.781818c-18.618182 0-32.581818 13.963636-32.581818 32.581819v805.236363c0 18.618182 13.963636 32.581818 32.581818 32.581818h861.090909c18.618182 0 32.581818-13.963636 32.581818-32.581818V139.636364c-4.654545-18.618182-18.618182-32.581818-37.236363-32.581819zM297.890909 172.218182V232.727273h65.163636V172.218182h307.2V232.727273h65.163637V172.218182h176.872727v204.8H116.363636V172.218182h181.527273zM116.363636 912.290909V442.181818h795.927273v470.109091H116.363636z"></path>
            </svg>
      </span>
         </div>}
         <div className={`relative mb-5 basis-[100%] grow ${(stage?.serviceDate?.value === '01') ? 'md:basis-1/2' : 'md:basis-1/3'}`}>
               <Datepicker selected={stage?.readyAt} 
                     onChange={(date)=>handleReadyAtChange(date)} 
                     showTimeSelect
                     showTimeSelectOnly
                     timeCaption='Time'
                     minDate={new Date()}
                     dateFormat={'h:mm aa'}
                  className={`text-[#00263A] border peer block w-full appearance-none rounded-lg border-[#ccc] p-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:outline-none focus:ring-0 ${(errors.stages?.length && touched.stages && errors.stages[index]?.readyAt && touched.stages[index]?.readyAt) && '!border-[#FF2853]'}`} 
                  wrapperClassName='peer'
                  placeholderText='h:mm aa'/>
            <label
               htmlFor="user_email"
               className={`absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent ${(errors.stages?.length && touched.stages && errors.stages[index]?.readyAt && touched.stages[index]?.readyAt) && '!text-[#FF2853]'}`}>
               Ready At
            </label>
            <span className={`absolute right-5 top-[50%] translate-y-[-50%] text-[#808080] peer-focus:text-[#00263A] text-[14px] leading-[20px] text-medium`}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width="1em" height="1em">
                     <path d="M940.218182 107.054545h-209.454546V46.545455h-65.163636v60.50909H363.054545V46.545455H297.890909v60.50909H83.781818c-18.618182 0-32.581818 13.963636-32.581818 32.581819v805.236363c0 18.618182 13.963636 32.581818 32.581818 32.581818h861.090909c18.618182 0 32.581818-13.963636 32.581818-32.581818V139.636364c-4.654545-18.618182-18.618182-32.581818-37.236363-32.581819zM297.890909 172.218182V232.727273h65.163636V172.218182h307.2V232.727273h65.163637V172.218182h176.872727v204.8H116.363636V172.218182h181.527273zM116.363636 912.290909V442.181818h795.927273v470.109091H116.363636z"></path>
                  </svg>
            </span>
         </div>
         <div className={`relative mb-5 basis-[100%] grow ${(stage?.serviceDate?.value === '01') ? 'md:basis-1/2' : 'md:basis-1/3'}`}>
               <Datepicker selected={stage?.closeAt}
                     onChange={(date)=>handleCloseAtChange(date)} 
                     showTimeSelect
                     showTimeSelectOnly
                     timeCaption='Time'
                     minDate={new Date()}
                     dateFormat={'h:mm aa'}
                  className={`text-[#00263A] border peer block w-full appearance-none rounded-lg border-[#ccc] p-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:outline-none focus:ring-0 ${(errors.stages?.length && touched.stages && errors.stages[index]?.closeAt && touched.stages[index]?.closeAt) && '!border-[#FF2853]'}`} 
                  wrapperClassName='peer'
                  placeholderText='h:mm aa'/>
            <label
               htmlFor="user_email"
               className={`absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent ${(errors.stages?.length && touched.stages && errors.stages[index]?.closeAt && touched.stages[index]?.closeAt) && '!text-[#FF2853]'}`}>
               Close At
            </label>
            <span className={`absolute right-5 top-[50%] translate-y-[-50%] text-[#808080] peer-focus:text-[#00263A] text-[14px] leading-[20px] text-medium`}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width="1em" height="1em">
                     <path d="M940.218182 107.054545h-209.454546V46.545455h-65.163636v60.50909H363.054545V46.545455H297.890909v60.50909H83.781818c-18.618182 0-32.581818 13.963636-32.581818 32.581819v805.236363c0 18.618182 13.963636 32.581818 32.581818 32.581818h861.090909c18.618182 0 32.581818-13.963636 32.581818-32.581818V139.636364c-4.654545-18.618182-18.618182-32.581818-37.236363-32.581819zM297.890909 172.218182V232.727273h65.163636V172.218182h307.2V232.727273h65.163637V172.218182h176.872727v204.8H116.363636V172.218182h181.527273zM116.363636 912.290909V442.181818h795.927273v470.109091H116.363636z"></path>
                  </svg>
            </span>
         </div>
       </div>}
      </>}
    </div>
    <div className={`relative mb-5`}>
            <Datepicker selected={stage.shipmentDate} 
                onChange={(date)=>handleDateChange(date)} 
                filterDate={isWeekday} 
                minDate={new Date()}
                dateFormat={'MMM dd, yyyy'}
                className={`text-[#00263A] border peer block w-full appearance-none rounded-lg border-[#ccc] p-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:outline-none focus:ring-0 ${(errors.stages?.length && touched.stages && errors.stages[index]?.shipmentDate && touched.stages[index]?.shipmentDate) && '!border-[#FF2853]'}`} 
                wrapperClassName='peer'
                placeholderText='MMM dd, yyyy'/>
         <label
            htmlFor="user_email"
            className={`absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent ${(errors.stages?.length && touched.stages && errors.stages[index]?.shipmentDate && touched.stages[index]?.shipmentDate) && '!text-[#FF2853]'}`}>
            Ship Date
         </label>
         <span className={`absolute right-5 top-[50%] translate-y-[-50%] text-[#808080] peer-focus:text-[#00263A] text-[14px] leading-[20px] text-medium`}>
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width="1em" height="1em">
                  <path d="M940.218182 107.054545h-209.454546V46.545455h-65.163636v60.50909H363.054545V46.545455H297.890909v60.50909H83.781818c-18.618182 0-32.581818 13.963636-32.581818 32.581819v805.236363c0 18.618182 13.963636 32.581818 32.581818 32.581818h861.090909c18.618182 0 32.581818-13.963636 32.581818-32.581818V139.636364c-4.654545-18.618182-18.618182-32.581818-37.236363-32.581819zM297.890909 172.218182V232.727273h65.163636V172.218182h307.2V232.727273h65.163637V172.218182h176.872727v204.8H116.363636V172.218182h181.527273zM116.363636 912.290909V442.181818h795.927273v470.109091H116.363636z"></path>
               </svg>
         </span>
    </div>
    <div className="delivery">
       <div className="input-group mb-5 block">
          <label className='text-[#00263A] text-[14px] leading-[20px] font-bold pb-5'>Delivery</label> 
          <fieldset className={`${stage.shipmentDate === null ? 'disabled' : ''}`}>
             {services?.map((service,i)=>{
                return <div className="flex items-center bottom-separator pt-5 relative" key={i}>
                   <input type="checkbox" id="delivery-0-0" onChange={()=>handleServiceChange(i)} className={`checkbox accent-[#00263A] m-2 w-4 h-4 p-4 rounded-2xl border-b-zinc-100 ${service.isSelected ? 'checked':''}`} checked={service.isSelected} /> 
                   <div className="ml-3 flex justify-between items-center w-full">
                      <div className="flex flex-col gap-2">
                        <div className='flex items-center'>
                           <img src="https://gcdn.bikeflights.com/Vue/logos/bf-ups-logo-bw.png" alt="UPS logo" fallback="logos/bf-ups-logo-bw.png" className="w-6 mr-3"/> 
                           <div className="font-semibold text-[#00263A] text-[12px] leading-[20px] uppercase">{service?.serviceDescription}</div>
                         </div>
                         {stage.shipmentDate && <span className='!translate-y-0 text-[11px]'>{"* "}{renderDeliveryDate(stage.shipmentDate,service?.serviceTypeCode)}</span>}
                      </div>
                      <div className="text-[12px]">${service?.totalPrice.toFixed(2)}</div>
                   </div>
                </div>
             })}
          </fieldset>
       </div>
    </div>
 </div>
  )
}

export default CostStage