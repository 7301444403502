import React, { useEffect, useState } from 'react'
import FormLayout from './FormLayout'
import { FieldArray, Formik } from 'formik'
import * as Yup from 'yup'
import useApp from '../store/useApp'
import { getFormattedAddressByPlaceAnother } from '../helpers/generals'
import DetailStage from './Details/Index'
import { getShipmentAddresses, updateShipmentAddresses } from '../helpers/apiRequests'
import Loading from '../components/loaders/Loading'
import { addressesAnalytics } from '../helpers/gtagEvents'

const initialValues = {
   stages: []
}

function Details() {
   const {shipmentId,updateTheShippingAddresses,setLoading} = useApp()
   const [isLoadingData,setIsLoadingData] = useState(true)
   const [errors,setErrors] = useState([])

   const validationSchema = Yup.object().shape({
      stages: Yup.array()
        .of(
          Yup.object().shape({
            from: Yup.object().required('Required').shape({
               country: Yup.string().required('Required'),
               first_name: Yup.string().required('Required'),
               last_name: Yup.string().required('Required'),
               street_address: Yup.string().required('Required'),
               city: Yup.string().required('Required'),
               state: Yup.string().required('Required'),
               postal_code: Yup.string().required('Required'),
               phone_number: Yup.string().required('Required').min(10,'Minimum 10 digits are allowed.').max(15,'Maximum 15 digits are allowed')
            }),
            to: Yup.object().required('Required').shape({
               country: Yup.string().required('Required'),
               first_name: Yup.string().required('Required'),
               last_name: Yup.string().required('Required'),
               street_address: Yup.string().required('Required'),
               city: Yup.string().required('Required'),
               state: Yup.string().required('Required'),
               postal_code: Yup.string().required('Required'),
               phone_number: Yup.string().required('Required').min(10,'Minimum 10 digits are allowed.').max(15,'Maximum 15 digits are allowed')
            })
          })
        ),
  });

  const prepareObj = function(obj){
   const {id,addressType,company,addressStage,shipmentId,city,country,state,phone_number,postal_code,first_name,last_name,street_address,apartment,residential} = obj;
   return {
      id,addressStage,addressType,shipmentId,city,country,state,
      phone: phone_number,
      zipCode: postal_code,
      name: first_name + ' ' + last_name,
      line: street_address + (apartment ? ', ' + apartment : ''),
      attentionName: '',
      residential: residential,
      companyName: company?.length ? company : null
   }
  }

   const handleFormSubmit = async function(values){
      const {stages} = values
      const obj = []
      stages?.forEach((stage,i)=>{
         obj.push(prepareObj(stage.from))
         obj.push(prepareObj(stage.to))
      })

      console.log("Obj",obj)

      try {
         await updateTheShippingAddresses(obj,values)
         addressesAnalytics()
      } catch (error) {
         const {message,object,status} = error.response.data;
         if(status === 'Error'){
            const reponse = JSON.parse(message)
            setErrors(reponse.Response.Errors)
            window.scrollTo({ top: 0, behavior: 'smooth' });
         }
      }
      
   }

   const getAddresses = async function(){
      let values = [];
      setIsLoadingData(true)
      // const {data:stages} = await getShipmentAddresses(shipmentId)
      try{
         const {data:stages} = await getShipmentAddresses(shipmentId)
         stages?.map((stage)=>{
            let from = {},to = {};
            let first_name = '',last_name = '';
            let name = stage.name.split(' ')
            first_name = name[0];
            if(name.length > 1) last_name = name[1]
   
            if(stage.addressType === 'From'){
               from['id'] = stage.id;
               from['addressType'] = stage.addressType;
               from['addressStage'] = stage.addressStage;
               from['shipmentId'] = stage.shipmentId;
               from['country'] = stage.country;
               from['company'] = stage?.companyName ? stage.companyName : '';
               from['first_name'] = first_name;
               from['last_name'] = last_name;
               from['street_address'] = stage.line;
               from['appartment'] = '';
               from['city'] = stage.city;
               from['state'] = stage.state;
               from['postal_code'] = stage.zipCode;
               from['phone_number'] = stage.phone;
               from['residential'] = stage?.residential ? stage.residential : false;
               if(values[parseInt(stage.addressStage) - 1]){
                  values[parseInt(stage.addressStage) - 1].from = from
               }else{
                  values[parseInt(stage.addressStage) - 1] = {from}
               }
               
            }else if(stage.addressType === 'To'){
               to['id'] = stage.id;
               to['addressType'] = stage.addressType;
               to['addressStage'] = stage.addressStage;
               to['shipmentId'] = stage.shipmentId;
               to['country'] = stage.country;
               to['company'] = stage?.companyName ? stage.companyName : '';;
               to['first_name'] = first_name;
               to['last_name'] = last_name;
               to['street_address'] = stage.line;
               to['appartment'] = '';
               to['city'] = stage.city;
               to['state'] = stage.state;
               to['postal_code'] = stage.zipCode;
               to['phone_number'] = stage.phone;
               to['residential'] = stage?.residential ? stage.residential : false;
               if(values[parseInt(stage.addressStage) - 1]){
                  values[parseInt(stage.addressStage) - 1].to = to
               }else{
                  values[parseInt(stage.addressStage) - 1] = {to}
               }
            }
         })
         initialValues.stages = values
         setIsLoadingData(false)
      }catch(error){
         console.log("Error",error)
         setIsLoadingData(false)
      }
   }


   useEffect( ()=>{
      const getData = function(){
         getAddresses()
      }
      getData()
   },[])

  return (
   <>
   {!isLoadingData ? (<FormLayout>
      <div style={{width:'100%'}}>
      {
      errors.map((error)=>{
         return <div id="1" className="notice relative mx-3 block danger mb-5">
               <strong>Heads Up!</strong> 
               <span>{' '+error.Message}</span>
         </div>
      })
      }
      <Formik
         initialValues={initialValues}
         validationSchema={validationSchema}
         onSubmit={handleFormSubmit}
         enableReinitialize={true}
      >{({values,errors,touched,setFieldValue,handleSubmit})=>{
         return (
      <form onSubmit={handleSubmit} autocomplete="on" className="left-column basis-full px-1 md:px-4 mb-3">
         <FieldArray
            name='stages'
            render={(arrayHelpers)=>{
               return <>
                  {(values.stages && values.stages.length) && values.stages.map((stage,i)=>{
                     return <DetailStage key={i} index={i} stage={stage} setFieldValue={setFieldValue} errors={errors}  touched={touched} numberOfStages={values.stages.length}/>
                  })}
               </>
            }}>
         </FieldArray>
         <button aria-label="Next step" type="submit" className="button btn-reverse button--full-width custom-btn bg-[#ce0e2d]">
            Continue
         </button>
      </form>
      )}}
      </Formik>
      </div>
   </FormLayout> ) : (<Loading/>)}
   </>
  )
}

export default Details