import React, { useEffect, useRef, useState } from 'react'
import FormLayout from './FormLayout';
import useApp from '../store/useApp';
import CostStage from '../components/CostStage';
import { Formik,FieldArray } from 'formik';
import * as Yup from 'yup'
import moment from 'moment';
import { updatePickup } from '../helpers/apiRequests';
import Loading from '../components/loaders/Loading';
import { costsAnalytics } from '../helpers/gtagEvents';

const initialValues = {
   stages: []
}

function Costs() {
   const formRef = useRef()
   const {shipmentId,setLoading,setActiveStep,shipment,getStageFromAddress,updatePickupRate} = useApp()
   const [isDataLoading,setIsDataLoading] = useState(true)
   const [stagesCount,setStagesCount] = useState(1)

   const handleFormSubmit = async function(values){

      let pickups = [];

      setLoading(true);
      values.stages?.forEach((stage,index)=>{
         if(stage.pickup.value === 2) return;
         let address = getStageFromAddress(index)
         const {city,state,postal_code,country,street_address} = address
         let pickupModal = {
            shipmentId,
            stageNo: index + 1,
            pickupType: stage.pickupType ? stage.pickupType.value : null,
            pickupAddressType: "From Address",
            serviceDateOption: stage.serviceDate ? stage.serviceDate?.value : null,
            "readyAt": (stage.serviceDate && stage.readyAt) ? moment(stage.readyAt).format('HH:mm:ss') : null,
            "closeAt": (stage.serviceDate && stage.closeAt) ? moment(stage.closeAt).format('HH:mm:ss') : null,
            "pickupDate": (stage.serviceDate && stage.pickupDate) ? moment(stage.pickupDate).format('YYYY-MM-DD') : (stage.serviceDate.value === '01' ? moment(stage.shipmentDate).format('YYYY-MM-DD') : null),
            "address": {
              "addressLine": [street_address],
              "city": city,
              "stateProvinceCode": state,
              "postalCode": postal_code,
              "countryCode": country
            }
         }
         pickups.push(pickupModal)
      })

      if(pickups.length < 1){
         setActiveStep('payments');
         return;
      }


      try {
         let {data} = await updatePickup(pickups)
         let rates = [];
         data?.forEach((item,index)=>{
            if(item.price){
               rates.push({
                  price:item.price,
                  stage: item.stage
               })
            }
         })
         updatePickupRate(rates)
         costsAnalytics()
         setLoading(false)
         setActiveStep('payments')
      } catch (error) {
         setLoading(false)
         console.log("Error",error)
      }
   }

   const validationSchema = Yup.object().shape({
      stages: Yup.array()
        .of(
          Yup.object().shape({
            shipmentDate: Yup.string().required('Required'),
            pickup: Yup.object().required('Required'),
            pickupType: Yup.object().nullable().when("pickup",(pickup,schema)=>{
               return (pickup[0]?.value === 1) ? schema.required('Required') : schema
            }),
            serviceDate: Yup.object().nullable().when("pickup",(pickup,schema)=>{
               return (pickup[0]?.value === 1) ? schema.required('Required') : schema
            }),
            pickupDate: Yup.string().nullable().when("serviceDate",(serviceDate,schema)=>{
               return (serviceDate[0]?.value === '03' || serviceDate[0]?.value === '02') ? schema.required('Required') : schema
            }),
            readyAt: Yup.string().nullable().when("serviceDate",(serviceDate,schema)=>{
               return (serviceDate[0]?.value === '03' || serviceDate[0]?.value === '02' || serviceDate[0]?.value === '01') ? schema.required('Required') : schema
            }),
            closeAt: Yup.string().nullable().when("serviceDate",(serviceDate,schema)=>{
               return (serviceDate[0]?.value === '03' || serviceDate[0]?.value === '02' || serviceDate[0]?.value === '01') ? schema.required('Required') : schema
            })
          })
        ),
  });

   useEffect(()=>{
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setLoading(false)
      const {stages} = shipment
      initialValues.stages = stages?.map((stage)=>{
         return {...stage,
            shipmentDate: null,
            pickup:   {
               name: 'Drop off at a UPS Store or Customer Center (NO CHARGE)',
               value: 2
            },
            pickupType:null,
            serviceDate:null,
            pickupDate:null,
            readyAt:null,
            closeAt:null
         }
      })
      setStagesCount(stages?.length)
      console.log("Initial values",initialValues)
      setIsDataLoading(false)
   },[shipment])

  return (
   <>
      {!isDataLoading ? (<FormLayout>
         <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
         >{({values,errors,touched,setFieldValue,handleSubmit})=>(
            <form className="left-column basis-7/12 px-1 md:px-4 mb-3" onSubmit={handleSubmit}>
            <FieldArray
               name="stages"
               render={arrayHelpers => (
                  <>
                     {(values.stages && values.stages.length ) && values.stages.map((stage,index)=>{
                        return <CostStage key={stage.stageNo} stageCount={stagesCount} errors={errors} touched={touched} stage={stage} index={index} shipmentId={shipmentId} services={stage.services} setFieldValue={setFieldValue} />
                     })}
                  </>
               )}>
               </FieldArray>
            <div className="font-semibold text-tiny my-4">*Expected Delivery dates are based on the selected ship date.</div>
            <button aria-label="Next step" type="submit" className="button btn-reverse button--full-width custom-btn my-4 bg-[#ce0e2d]">
               Continue
            </button>
         </form>)}
         </Formik> 
      </FormLayout>) :
      (<Loading/>)}
   </>
  )
}

export default Costs