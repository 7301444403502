import React, { useEffect, useState } from 'react'
import SelectArrows from '../../../components/SelectArrows'
import { countries } from '../../../utils/data'

function CountryField({index,country,handleCountryChange,error}) {
    const [openDrowpDown,setOpenDropDown] = useState(false)
    const [selectedCountry,setSelectedCountry] = useState(countries.find(c=>c.code===country))
    const countriesToShow = countries.filter((country)=>country.active)

    const handleValueChange = function(item){
        handleCountryChange('country',item?.code)
        setOpenDropDown(false)
        setSelectedCountry(item)
    }
    useEffect(()=>{
        console.log("Selectedcountry",selectedCountry)
    },[selectedCountry])

  return (
    <div className="relative mb-5">
        <input
            id={`stages[${index}.from.country]`}
            type="text"
            name={`stages[${index}.from.country]`}
            value={selectedCountry?.name}
            onFocus={()=>(setOpenDropDown(true))}
            className={`text-[#00263A] border peer block w-full appearance-none cursor-pointer rounded-lg border-[#ccc] p-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:border-b-0 focus:rounded-bl-[0px] focus:rounded-br-[0px] focus:outline-none focus:ring-0 ${(error) && 'bf-form-error-col'}`}
            placeholder=' ' autoComplete='off'
            />
        <label
            htmlFor={`stages[${index}.from.country]`}
            className="absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent">
            Countries
        </label>
        <span onClick={(e)=>{setOpenDropDown(!openDrowpDown);
            if(!openDrowpDown){
                e.currentTarget.parentNode.querySelector('input').focus();
            }
        }} className='absolute right-5 top-[50%] translate-y-[-50%] text-[#808080] peer-focus:text-[#00263A] text-[14px] leading-[20px] text-medium peer-focus:-rotate-180 transform duration-300 cursor-pointer'>
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-8 svg-inline--fa fa-angle-down">
                <path fill="currentColor" d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" className=""></path>
            </svg>
        </span>
        <div className='custom-overlay' style={{display: openDrowpDown ? "block" : "none"}} onClick={()=>setOpenDropDown(!openDrowpDown)}></div>
        <div style={{display: openDrowpDown ? "block":"none"}} className="absolute jb-select-dropdown transform duration-300 shadow-[0_6px_10px_rgba(0,0,0,0.2)] top-full z-40 w-full lef-0 rounded-bl-lg rounded-br-lg border border-[#00263A] border-t-0 overflow-y-auto max-h-52 min-w-133">
            <div className="flex flex-col w-full">
                {countriesToShow.map((country,index)=>{
                    return <div onClick={()=>handleValueChange(country)} className="cursor-pointer w-full bg-white" key={index}>
                        <div className="flex w-full items-center p-2 pl-2 border-transparent hover:bg-brand-200 border-l-2 relative">
                            <div className="w-full items-center flex">
                                <div className="mx-2 text-[#00263A] text-[14px] leading-[20px] text-medium">{country?.name}</div>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    </div>
  )
}

export default CountryField