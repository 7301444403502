import React, { createContext, useEffect, useReducer } from 'react'
import {v4 as uuidv4} from 'uuid'
import { getCategories, getPresets, getProtections, getPurposes, getRates, updateShipmentAddresses } from '../helpers/apiRequests'
import { checkCookie, setActiveSearch, setCookie } from '../helpers/generals'

const initialState = {
    clientSecret: null,
    orderProcessed: false,
    successStatus: null,
    successMessage: {},
    addresses: [],
    shipment: {},
    shipmentId: null,
    activeSearch:{
        boxes:[],
        trips:[],
        purpose: {}
    },
    isLoading: false,
  errors:{},
  errorMessage:'',
  orderSummary:{},
  activeStep: 'search',
  units: {
    weight: 'lb',
    length: 'in'
  },
  numberOfBoxes: {
    value: 1,
    name: '1 Box/Case'
  },
  protections:[],
  presets:[],
  categories:[],
  purposes:[],
  purpose: null,
  trips: [
    {
        from: null,
        to: null
    }
  ],
  boxes: []
}

const reducer = (state, action) => {
    switch (action.type) {
      case "SET_CLIENT_SECRET":
        return {
            ...state,
            clientSecret: action.payload
        }
      case "SET_SEARCH":
        return {
            ...state,
            activeSearch: action.payload
        }
      case "SET_SUCCESS_STATUS":
        return {
            ...state,
            successStatus: action.payload
        }
      case "SET_SUCCESS_MESSAGE":
        return {
            ...state,
            successMessage: action.payload
        }
      case "SET_ADDRESSES":
        return {
            ...state,
            addresses: action.payload
        }
      case "SET_ORDER_PROCESSED":
        return {
            ...state,
            orderProcessed: action.payload
        }
      case "SET_SEARCH_FIELD":
        return {
            ...state,
            activeSearch: {...state.activeSearch,[action.payload.field]: action.payload.value}
        }
      case "SET_IS_LOADING":
        return {
            ...state,
            isLoading: action.payload
        }
      case "SET_SHIPMENT":
        return {
            ...state,
            shipment: action.payload
        }
      case "SET_SHIPMENT_ID":
        return {
            ...state,
            shipmentId: action.payload
        }
      case "SET_ERRORS":
        return {
            ...state,
            errors: action.payload
        }
      case "SET_ERROR_MESSAGE":
        return {
            ...state,
            errorMessage: action.payload
        }
      case "SET_ACTIVE_STEP":
        return {
            ...state,
            activeStep: action.payload
        }
      case "SET_ORDER_SUMMARY":
        return {
            ...state,
            orderSummary: action.payload
        }
      case "SET_UNITS":
        return { 
            ...state,
            units: action.payload
        }

      case "SET_PROTECTIONS":
        return {
            ...state,
            protections: [...action.payload]
        }
      case "SET_PRESETS":
        return {
            ...state,
            presets: [...action.payload]
        }
      case "SET_CATEGORIES":
        return {
            ...state,
            categories: [...action.payload]
        }
      case "SET_PURPOSES":
        return {
            ...state,
            purposes: [...action.payload]
        }
      default: {
          return { ...state }
      }
    }
}

const AppContext = createContext({
    ...initialState,
    // addStepOne: () => Promise.resolve(),
    setErrors: (errors) => null,
    setUnits: (units) => null,
    getTheRates: ()=> null,
    setActiveStep: (step)=>null,
    setLoading: (value)=>null,
    updateShippingRate: (rate,index)=>null,
    updatePickupRate: (rate,index)=>null,
    updateTheShippingAddresses: (obj,stages)=>Promise.resolve(),
    getStageFromAddress: (stage)=>null,
    setClientSecret: (value)=>null,
    setOrderProcessed: (value)=>null,
    setAddresses: (value)=>null,
    setSucessStatus: (value) => null,
    setSucessMessage: (value) => null
})

export const AppProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    function setSearch(value){
        console.log("Value.>>>>...>>>",value)
        dispatch({
            type: 'SET_SEARCH',
            payload:value
        })
    }

    function setOrderProcessed(value){
        dispatch({
            type: 'SET_ORDER_PROCESSED',
            payload: value
        })
    }

    function setSucessStatus(value){
        dispatch({
            type: 'SET_SUCCESS_STATUS',
            payload: value
        })
    }

    function setSucessMessage(value){
        dispatch({
            type: 'SET_SUCCESS_MESSAGE',
            payload: value
        })
    }

    function setAddresses(value){
        dispatch({
            type: 'SET_ADDRESSES',
            payload: value
        })
    }

    function setSearchField(type,value){
        dispatch({
            type:'SET_SEARCH_FIELD',
            payload: {
                field:type,
                value
            }
        })
    }

    function getStageFromAddress(stage){
        return (state.activeSearch?.stages[stage]?.from) ? state.activeSearch.stages[stage].from : null
        
    }

    function setClientSecret(value){
        dispatch({
            type: 'SET_CLIENT_SECRET',
            payload: value
        })
    }

    function updateShippingRate(rate,index){
        let billings = state.orderSummary
        let {dropoffRate,insurance,pickupRate,shippingRate,subTotal} = billings[index]
        shippingRate = rate
        subTotal = parseFloat((dropoffRate + insurance + pickupRate + shippingRate).toFixed(2))
        billings = billings.map((billing,innerIndex)=>{
            return (index === innerIndex) ? {...billing,shippingRate,subTotal} : billing
        })
        console.log("Billings",billings)
        dispatch({
            type: 'SET_ORDER_SUMMARY',
            payload: billings
        })
    }

    async function updateTheShippingAddresses(obj,values){
        setLoading(true)
        try {
            const {data} = await updateShipmentAddresses(state.shipmentId,obj)
            const {billing,shipmentId,stages} = data
   
            dispatch({
                type: "SET_SHIPMENT",
                payload: {stages,shipmentId}
            })
            dispatch({
                type: "SET_SHIPMENT_ID",
                payload: shipmentId
            })
            dispatch({
                type: "SET_ORDER_SUMMARY",
                payload: billing
            })
            setSearch({
                ...state.activeSearch,
                ...values
            })
            setActiveSearch(values)
            setLoading(false)
            setActiveStep('costs')
            return data
            
         } catch (error) {
            console.log("Error",error)
            setLoading(false)
            throw error;
         }
    }

    function updatePickupRate(rates){
        let billings = state.orderSummary
        billings = billings.map((billing,index)=>{
            let rateObj = rates.find((rate)=>rate.stage === (index + 1))
            if(!rateObj) return billing;
            let {dropoffRate,insurance,pickupRate,shippingRate,subTotal} = billings[index]
            pickupRate = rateObj.price ? parseFloat(rateObj.price) : 0
            subTotal = parseFloat((dropoffRate + insurance + pickupRate + shippingRate).toFixed(2))
            return {...billing,pickupRate,subTotal}
        })
        dispatch({
            type: 'SET_ORDER_SUMMARY',
            payload: billings
        })
    }

    function setLoading(value){
        dispatch({
            type: "SET_IS_LOADING",
            payload: value
        })
    }

    function setErrors(errors){
        dispatch({
            type:"SET_ERRORS",
            payload: errors
        })
    }

    function setUnits(units){
        dispatch({
            type:"SET_UNITS",
            payload: units
        })
    }

    function setActiveStep(step){
        dispatch({
            type: 'SET_ACTIVE_STEP',
            payload: step
        })
    }

    async function getTheRates(boxesObj,tripsObj,purpose,values){
        setLoading(true)
        try {
            const {data} = await getRates(boxesObj,tripsObj,purpose)

            dispatch({
                type: "SET_SHIPMENT_ID",
                payload: data[0]?.shipmentId
            })
    
            setAddresses(data)
            setSearch(values)
            setActiveSearch(values)
            setActiveStep('details')
            setLoading(false)
        } catch (error) {
            dispatch({
                type:"SET_ERROR_MESSAGE",
                payload: error?.message
            })
            setLoading(false)
        }
    }

    function setQuickQuote(){
        let quickQuote = window.localStorage.getItem('quickQoute');
        if(quickQuote){
            quickQuote = JSON.parse(quickQuote)
            setSearch({
                ...state.activeSearch,
                boxes: quickQuote?.box ? [quickQuote.box] : [],
                trips: quickQuote?.trip ? [quickQuote.trip] : []
            })
        }else{
            console.log("Running 3")
            setSearch({
                boxes: [],
                trips: [],
                purpose: {}
            })
        }
    }

    function setUserCookie(){
        if(!window.localStorage.getItem('app_shipper_id')){
            window.localStorage.setItem('app_shipper_id',uuidv4());
        }
    }
    
    useEffect(() => {
        ; (async () => {
            try {
                const protections = await getProtections()
                const presets = await getPresets()
                const categories = await getCategories()
                const purposes = await getPurposes()
                dispatch({
                    type:"SET_PROTECTIONS",
                    payload: protections?.data
                })
                dispatch({
                    type:"SET_PRESETS",
                    payload: presets?.data
                })
                dispatch({
                    type:"SET_CATEGORIES",
                    payload: categories?.data
                })
                dispatch({
                    type:"SET_PURPOSES",
                    payload: purposes?.data
                })
            } catch (err) {
                console.error(err)
                // dispatch({type: 'INIT'})
            }
            setQuickQuote()
            setUserCookie()
        })()
    }, [])

    
    return (
        <AppContext.Provider
            value={{
                ...state,
                setErrors,
                setUnits,
                getTheRates,
                setActiveStep,
                setLoading,
                updateShippingRate,
                updatePickupRate,
                updateTheShippingAddresses,
                getStageFromAddress,
                setClientSecret,
                setOrderProcessed,
                setAddresses,
                setSucessStatus,
                setSucessMessage
            }}
        >
            {children}
        </AppContext.Provider>
    )
}

export default AppContext