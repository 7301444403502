export function getQuoteAnalytics(data){
    const {contents,services} = data;
    if(window.gtag === undefined) return;
    window.gtag("event", "get_quote", {
        "contents": contents,
        "price": services
    });
}

export function shipmentInfoAnalytics({boxes,trips}){
    if(window.gtag === undefined) return;
    window.gtag("event", "shipment_step", {
        "step_name": "1_shipment_info",
        "boxes": boxes,
        "trips": trips
    })
}

export function addressesAnalytics(){
    if(window.gtag === undefined) return;
    window.gtag("event", "shipment_step", {
        "step_name": "2_shipment_address",
    });
}

export function costsAnalytics(){
    if(window.gtag === undefined) return;
    window.gtag("event", "shipment_step", {
        "step_name": "3_shipment_options"
    });
}

export function contentsAnalytics(){
    if(window.gtag === undefined) return;
    window.gtag("event", "shipment_step", {
        "step_name": "4_shipment_details"
    });
}

export function billingsAnalytics(){
    if(window.gtag === undefined) return;
    window.gtag("event", "shipment_step", {
        "step_name": "5_shipment_billing"
    });
}

export function purchaseAnalytics({object}){
    if(window.gtag === undefined) return;
    window.gtag("event", "purchase", object);
}
