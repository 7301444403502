class GoogleAutoComplete{

    constructor(){
        this.options = {
            fields: ["formatted_address", "geometry", "name", "address_components", "vicinity"],
            strictBounds: !1,
            componentRestrictions: { country: "us" }
        };
    }

    initMapAPI(){
        return new Promise((resolve) => {
            if (window.google) {
              resolve();
            } else {
                try {
                    const script = document.createElement('script');
                    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}&libraries=places&language=en`;
                    script.async = true;
                    script.defer = true;
                    script.addEventListener('load', resolve);
                    document.head.appendChild(script);
                } catch (error) {
                    console.log("Error>>>",error);
                    resolve();
                }
            }
        });
    }

    initializeAutocomplete(referrer,type,callback){
        if(window.google){
            const autocomplete = new window.google.maps.places.Autocomplete(
                referrer.current,
                this.options
            );
            autocomplete.addListener('place_changed', function() {
                callback(autocomplete,type);
            });
            return true;
        }else{
            return false;
        }
    }
}

export default GoogleAutoComplete;