import React, { useEffect, useRef } from 'react'
// import { initializeAutocomplete } from '../helpers/GoogleMapsService';
import GoogleAutoComplete from '../helpers/GoogleMapsClassService';
import { getFormattedAddress } from '../helpers/apiRequests';

function AddressFields({item,index,setFieldValue,errors,touched}) {
    const fromRef = useRef()
    const fromRef2 = useRef()

    async function fillInAddress(field,type) {
      console.log("Field",field.getPlace())
        var place = field.getPlace();
        try {
          const {data} = await getFormattedAddress(place)
          if(type === 'from'){
            if(data?.XAVResponse?.Response?.ResponseStatus?.Code === '1'){
              setFieldValue(`trips[${index}].from`,{...place,invalid:false, errorMessage: ''})
            }else if(data?.response?.errors){
              setFieldValue(`trips[${index}].from`,{...place,invalid:true, errorMessage:data?.response?.errors[0].message})
            }
          }else if(type === 'to'){
            if(data?.XAVResponse?.Response?.ResponseStatus?.Code === '1'){
              setFieldValue(`trips[${index}].to`,{...place,invalid:false, errorMessage: ''})
            }else if(data?.response?.errors){
              setFieldValue(`trips[${index}].to`,{...place,invalid:true, errorMessage:data?.response?.errors[0].message})
            }
          }
        } catch (error) {
            console.log("Error",error)
        } 
    }

    function initFieldEvent(Obj,ref,type){
      let attempt = 0,maxRetries=10,interval=1000;

      function attemptCheck() {
          attempt++;
  
          const result = Obj.initializeAutocomplete(ref,type,fillInAddress);;
  
          if (result) return true;
  
          if (attempt >= maxRetries) return false;
  
          setTimeout(attemptCheck, interval);
      }
  
      // Start the first attempt
      attemptCheck();
    }

    useEffect(()=>{
      // initializeAutocomplete(fromRef,'from',fillInAddress);
      // setTimeout(()=>{
      //   initializeAutocomplete(fromRef2,'to',fillInAddress)
      // },1500)
      const AutoComplete = new GoogleAutoComplete();
      initFieldEvent(AutoComplete,fromRef,'from');
      initFieldEvent(AutoComplete,fromRef2,'to');
      
    },[initFieldEvent])

  return (
              <div className="w-full stage-form mt-2">
                  <div className="flex flex-col">
                    <div className="relative mb-5">
                        <input
                            id={`trips_from_${index}`}
                            type="text"
                            ref={fromRef}
                            name={`trips[${index}].from`}
                            value={item.from?.formatted_address} onChange={(e)=>setFieldValue(`trips[${index}].from`,e.target.value)}
                            className={`text-[#00263A] border peer block w-full appearance-none rounded-lg border-[#ccc] py-5 pl-12 pr-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:outline-none focus:ring-0 ${(errors?.trips?.length && touched.trips && errors?.trips[index]?.from && touched?.trips[index]?.from) && 'bf-form-error'}`}
                            placeholder=" " autoComplete="off"
                            />
                        <label
                            htmlFor={`trips_from_${index}`}
                            className="absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-placeholder-shown:left-12  peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent">
                            Ship From
                        </label>
                        <span className='absolute left-5 top-[50%] translate-y-[-50%] text-[#808080] peer-focus:text-[#00263A] text-[18px] leading-[20px] text-medium'>
                          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="location-dot" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className="svg-inline--fa fa-location-dot">
                            <path fill="currentColor" d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 256c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z" className=""></path>
                          </svg>
                        </span>
                    </div>
                    <div className="relative mb-5">
                        <input
                            id={`trips_to_${index}`}
                            type="text"
                            ref={fromRef2}
                            name={`trips[${index}].to`}
                            value={item.to?.formatted_address} onChange={(e)=>setFieldValue(`trips[${index}].to`,e.target.value)}
                            className={`text-[#00263A] border peer block w-full appearance-none rounded-lg border-[#ccc] py-5 pl-12 pr-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:outline-none focus:ring-0 ${(errors?.trips?.length && touched.trips && errors?.trips[index]?.to && touched?.trips[index]?.to) && 'bf-form-error'}`}
                            placeholder=" " autoComplete="off"
                            />
                        <label
                            htmlFor={`trips_to_${index}`}
                            className="absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-placeholder-shown:left-12  peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent">
                            Ship To
                        </label>
                        <span className='absolute left-5 top-[50%] translate-y-[-50%] text-[#808080] peer-focus:text-[#00263A] text-[18px] leading-[20px] text-medium'>
                          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="location-dot" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className="svg-inline--fa fa-location-dot">
                            <path fill="currentColor" d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 256c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z" className=""></path>
                          </svg>
                        </span>
                    </div>
                  </div>
              </div>
  )
}

export default AddressFields