import React from 'react'
import SelectArrows from '../../components/SelectArrows'
import Stage from './components/Stage'

function DetailStage({stage,touched,errors,setFieldValue,index,numberOfStages}) {

  return (
    <div className="ship-stage-wrapper mb-5">
      <Stage stage={stage} numberOfStages={numberOfStages} touched={touched} errors={errors} setFieldValue={setFieldValue} index={index} stageType={'from'}/>
      <Stage stage={stage} numberOfStages={numberOfStages} touched={touched} errors={errors} setFieldValue={setFieldValue} index={index} stageType={'to'}/>
 </div>
  )
}

export default DetailStage