import React from 'react'
import useApp from '../store/useApp'

function Success() {
    const {successMessage} = useApp()

  return (
    <>
    <div className="sp-main sp-m-123 ship-form-wrapper">
    <div className="sp-wrap">
        <div className="sp-header center">
            <h1 className="main-page-title page-title h0 text-[#00263A]">
                Thank you!
            </h1>
            <h3 className='text-[#00263A]'>
                Your order was processed successfully.
            </h3>

            <h3 className='text-[#00263A]'>
                Order # {successMessage.orderNumber}
            </h3>
            <h3 className='text-[#00263A]'>
                An email with order confirmation is also sent to {successMessage.email}
            </h3>
            <h3 className='text-[#00263A]'>
                Next we will verify your order and send you shipping labels in a seprate email.
            </h3>
        </div>
        </div>
    </div>
    </>
  )
}

export default Success