import React from 'react'

function CheckBox({width,height}) {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="18" height="18" rx="9" fill="#14B89C"/>
        <path d="M7.33332 10.7395L12.4167 5.65617C12.5694 5.50339 12.7465 5.427 12.9479 5.427C13.1493 5.427 13.3264 5.50339 13.4792 5.65617C13.6319 5.80895 13.7083 5.98603 13.7083 6.18742C13.7083 6.38881 13.6319 6.56589 13.4792 6.71867L7.85416 12.3437C7.70138 12.4964 7.5243 12.5728 7.32291 12.5728C7.12152 12.5728 6.94443 12.4964 6.79166 12.3437L4.52082 10.0728C4.36805 9.92006 4.29166 9.74297 4.29166 9.54159C4.29166 9.3402 4.36805 9.16311 4.52082 9.01034C4.6736 8.85756 4.85068 8.78117 5.05207 8.78117C5.25346 8.78117 5.43055 8.85756 5.58332 9.01034L7.33332 10.7395Z" fill="white"/>
    </svg>
  )
}

export default CheckBox