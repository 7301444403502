import React, { useEffect, useState } from 'react'

function QuoteModel({open,setOpen,isLoading,services,packageObject,isError,errorMessage,handleStartShipping}) {
    console.log("Error message",errorMessage)
    const closeModel = ()=>setOpen(false);

  return (
    <div className={`custom-model-main ${open ? 'model-open' : ''}`}>
        <div className="bg-overlay" onClick={closeModel}><span></span></div>
            <div className="custom-model-inner">
                    <div className="custom-model-wrap">                            
                        {isLoading ? (
                            <div className='loader'></div>
                        ) : (
                            <>
                                {isError ? (
                                    <div>
                                        <div className="close-btn" onClick={closeModel}>
                                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" className="icon icon-close" fill="none" viewBox="0 0 18 17">
                                                <path d="M.865 15.978a.5.5 0 00.707.707l7.433-7.431 7.579 7.282a.501.501 0 00.846-.37.5.5 0 00-.153-.351L9.712 8.546l7.417-7.416a.5.5 0 10-.707-.708L8.991 7.853 1.413.573a.5.5 0 10-.693.72l7.563 7.268-7.418 7.417z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <div className="pop-up-content-wrap">
                                            <div className="gp-header" style={{justifyContent:'center'}}>
                                                <div className="gp-h-title">
                                                    <h2 className="h2">Something went wrong!</h2>
                                                </div>
                                            </div>
                                            <div className="gp-actions">
                                                <p style={{color:'rgb(169 68 66)'}}>
                                                    {(errorMessage !== '') ? (errorMessage) :(<><strong>Sorry!</strong> We were not able to get rates from the information you provided. Typically the reason is that you gave us too much or too little detail. Try typing something as simple as a basic street address or a city, state, and zip code combination to get rates. Thank you.</>)}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="close-btn" onClick={closeModel}>
                                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" className="icon icon-close" fill="none" viewBox="0 0 18 17">
                                                <path d="M.865 15.978a.5.5 0 00.707.707l7.433-7.431 7.579 7.282a.501.501 0 00.846-.37.5.5 0 00-.153-.351L9.712 8.546l7.417-7.416a.5.5 0 10-.707-.708L8.991 7.853 1.413.573a.5.5 0 10-.693.72l7.563 7.268-7.418 7.417z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <div className="pop-up-content-wrap">
                                            <div className="gp-header">
                                                <div className="gp-h-title">
                                                    <h2 className="h2">Get Quote</h2>
                                                    <div className="rte"><em>{packageObject?.box?.contents?.name}</em></div>
                                                </div>
                                                <div className="gp-h-btn">
                                                    <button className="gp-b">$ USD</button>
                                                </div>
                                            </div>
                                            <div className="gp-text rte rte-settings">
                                            <p>{packageObject?.trip?.from?.formatted_address} -&gt; {packageObject?.trip?.to?.formatted_address}</p>
                                            </div>
                                            <div className="gp-actions">
                                                {services?.map((service,i)=>{
                                                    return <div className="gp-action-box action-box-2 " key={i}>
                                                        <div className="gp-a-flex">
                                                            <div className="gp-action-icon">
                                                                <svg className="icon icon-accordion color-foreground-text" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                                <path d="M9.69502 0.6786C9.91338 0.601796 10.1516 0.603123 10.3691 0.682353L18.2151 3.54058C18.61 3.68445 18.8728 4.05988 18.8728 4.48018V14.4287C18.8728 14.8074 18.6588 15.1537 18.32 15.3231L10.4731 19.2465C10.196 19.385 9.87022 19.3873 9.59117 19.2526L1.45405 15.3244C1.10843 15.1576 0.888794 14.8076 0.888794 14.4239V4.48434C0.888794 4.05997 1.15665 3.68181 1.55699 3.541L9.69502 0.6786ZM6.07999 3.01017L2.5346 4.25719L10.149 7.63545L13.5692 6.118L6.07999 3.01017ZM6.78606 2.76183L14.1997 5.83828L17.5367 4.35774L10.0268 1.62195L6.78606 2.76183ZM1.88879 14.4239L1.88879 5.06467L9.64898 8.50762V18.1701L1.88879 14.4239ZM17.8728 14.4287L10.649 18.0405V8.50762L17.8728 5.30263V14.4287Z" fill-rule="evenodd"></path>
                                                                </svg>
                                                            </div>
                                                            <div className="gp-action-content">
                                                                <h2 className="hero-title">{service.service.description}</h2>
                                                                <p className="pricing">${parseFloat(service?.price)?.toFixed(2)} each way</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })}
                                            </div>
                                            <div className="gp-note rte rte-settings">
                                            <p>The Ship date does not count as a day in transit.</p>
                                            </div>
                                            <div className="gp-btn-wrapper">
                                                <button className="button button-my-tertiary" onClick={handleStartShipping}>Start Shipping</button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
            </div>
    </div>
  )
}

export default QuoteModel