export const boxesModal = {
    id: Date.now(),
    length: "",
    label: "",
    width: "",
    height: "",
    weight: "",
    marketValue: "",
    preset: "",
    isEbike: false,
    isExact: false,
    linearUnit: "",
    weightUnit: "",
    sortOrder: "",
    contents: "",
    premiumProtection: ""
}
export const tripsModal = {
    id:Date.now(),
    from:null,
    to:null
}

export const boxesList = [
    {name:'1 Package',value:1},
    {name:'2 Packages',value:2},
    {name:'3 Packages',value:3},
    {name:'4 Packages',value:4}
]

export const boxeTemplate = {
    length: null,
    label: "",
    width: null,
    height: null,
    weight: null,
    marketValue: null,
    preset: null,
    isEbike: false,
    isExact: false,
    linearUnit: null,
    weightUnit: null,
    sortOrder: null,
    contents: null,
    premiumProtection: null
  };

export const contents = ["Complete Bicycle", "Complete E-Bike With Battery Installed", "Bicycle Frame", "Bicycle Wheel(s)", "Bicycle Parts",  "Cycling Apparel, Helmets, Shoes"];
export const protections = [
    "$200 Coverage (+$0)",
    "$300 Coverage (+$1.60)",
    "$400 Coverage (+$3.20)",
    "$500 Coverage & Delivery Signature (+$4.80)",
    "$600 Coverage & Delivery Signature (+$6.40)",
    "$700 Coverage & Delivery Signature (+$8.00)",
    "$800 Coverage & Delivery Signature (+$9.60)",
    "$900 Coverage & Delivery Signature (+$11.20)",
    "$1,000 Coverage & Delivery Signature (+$12.80)",
    "$1,500 Coverage & Delivery Signature (+$20.80)",
    "$2,000 Coverage & Delivery Signature (+$28.80)",
    "$2,500 Coverage & Delivery Signature (+$36.80)",
    "$3,000 Coverage & Delivery Signature (+$44.80)",
    "$3,500 Coverage & Delivery Signature (+$52.80)",
    "$4,000 Coverage & Delivery Signature (+$60.80)",
    "$4,500 Coverage & Delivery Signature (+$68.80)",
    "$5,000 Coverage & Delivery Signature (+$76.80)",
    "$6,000 Coverage & Delivery Signature (+$92.80)",
    "$7,000 Coverage & Delivery Signature (+$108.80)",
    "$8,000 Coverage & Delivery Signature (+$124.80)",
    "$9,000 Coverage & Delivery Signature (+$140.80)",
    "$10,000 Coverage & Delivery Signature (+$156.80)",
    "$11,000 Coverage & Delivery Signature (+$172.80)",
    "$12,000 Coverage & Delivery Signature (+$188.80)",
    "$13,000 Coverage & Delivery Signature (+$204.80)",
    "$14,000 Coverage & Delivery Signature (+$220.80)",
    "$15,000 Coverage & Delivery Signature (+$236.80)",
    "$16,000 Coverage & Delivery Signature (+$252.80)",
    "$17,000 Coverage & Delivery Signature (+$268.80)",
    "$18,000 Coverage & Delivery Signature (+$284.80)",
    "$19,000 Coverage & Delivery Signature (+$300.80)",
    "$20,000 Coverage & Delivery Signature (+$316.80)"
];

export const measurements = [
    {
        name: "TRI ALL 3 Clam Shell Bike Case",
        length: 49,
        width: 10,
        height: 33,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 237
    },
    {
        name: "BIKND Helium Bike Case",
        length: 50,
        width: 12,
        height: 34,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 140
    },
    {
        name: "BIKE PRO Race Case BMX",
        length: 50,
        width: 10,
        height: 22,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 131
    },
    {
        name: "PEDAL PACK Commuter Case",
        length: 42,
        width: 11,
        height: 31,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 165
    },
    {
        name: "SURLY Hard Travel Case",
        length: 26,
        width: 10,
        height: 26,
        linearUnit: "IN",
        weight: 50,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 215
    },
    {
        name: "BIKE PRO Race Case Standard",
        length: 48,
        width: 10,
        height: 30,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 132
    },
    {
        name: "BIKE PRO Race Case Oversize",
        length: 48,
        width: 10,
        height: 34,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 133
    },
    {
        name: "BIKE PRO Race Case 2X Oversize",
        length: 48,
        width: 14,
        height: 38,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 133
    },
    {
        name: "BIKE PRO Double Race Case",
        length: 48,
        width: 14,
        height: 30,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 134
    },
    {
        name: "TRI ALL 3 Velo Safe ISP",
        length: 51,
        width: 16,
        height: 36,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 235
    },
    {
        name: "TRI ALL 3 Compact Velo Safe",
        length: 48,
        width: 12,
        height: 32,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 231
    },
    {
        name: "TRI ALL 3 Custom Road",
        length: 51,
        width: 18,
        height: 32,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 233
    },
    {
        name: "PERFORMANCE Pro Bike Case",
        length: 50,
        width: 13,
        height: 34,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 170
    },
    {
        name: "PERFORMANCE Team Bike Case",
        length: 45,
        width: 12,
        height: 34,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 171
    },
    {
        name: "TRICO Iron Case",
        length: 47,
        width: 11,
        height: 31,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 240
    },
    {
        name: "SERFAS SBT",
        length: 45,
        width: 10,
        height: 28,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 210
    },
    {
        name: "CRATEWORKS Pro XL-C",
        length: 46,
        width: 11,
        height: 30,
        linearUnit: "IN",
        weight: 50,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 151
    },
    {
        name: "CRATEWORKS Pro 1",
        length: 46,
        width: 11,
        height: 30,
        linearUnit: "IN",
        weight: 50,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 150
    },
    {
        name: "CRATEWORKS Pro XL-T",
        length: 71,
        width: 11,
        height: 31,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 152
    },
    {
        name: "SANTANA Airliner Safe Case",
        length: 33,
        width: 14,
        height: 27,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 190
    },
    {
        name: "SCICON Bike Case",
        length: 45,
        width: 14,
        height: 37,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 200
    },
    {
        name: "THULE RoundTrip Sport",
        length: 47,
        width: 11,
        height: 31,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 220
    },
    {
        name: "S&S MACHINE 10\" Travel Case",
        length: 26,
        width: 10,
        height: 26,
        linearUnit: "IN",
        weight: 50,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 185
    },
    {
        name: "TRI ALL 3 Velo Safe II",
        length: 51,
        width: 18,
        height: 32,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 234
    },
    {
        name: "RITCHEY BreakAway Case",
        length: 31,
        width: 9,
        height: 27,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 180
    },
    {
        name: "TRI ALL 3 Original Velo Safe",
        length: 51,
        width: 18,
        height: 32,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 232
    },
    {
        name: "B&W Folding Folding Bike Suitcase",
        length: 28,
        width: 13,
        height: 26,
        linearUnit: "IN",
        weight: 50,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 127
    },
    {
        name: "B&W Bike Guard Curv Bicycle Case",
        length: 52,
        width: 13,
        height: 36,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 126
    },
    {
        name: "EVOC Bike Travel Bag",
        length: 54,
        width: 15,
        height: 33,
        linearUnit: "IN",
        weight: 65,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 156
    },
    {
        name: "B&W Bike Case II",
        length: 53,
        width: 14,
        height: 35,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 125
    },
    {
        name: "PEDAL PACK Folding Bike Case",
        length: 34,
        width: 15,
        height: 27,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 166
    },
    {
        name: "S&S MACHINE 12\" Travel Case",
        length: 26,
        width: 12,
        height: 26,
        linearUnit: "IN",
        weight: 50,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 186
    },
    {
        name: "S&S MACHINE 14\" Travel Case",
        length: 26,
        width: 14,
        height: 26,
        linearUnit: "IN",
        weight: 50,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 187
    },
    {
        name: "TRI ALL 3 Wheel Safe Pro-Series",
        length: 34,
        width: 16,
        height: 32,
        linearUnit: "IN",
        weight: 20,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 236
    },
    {
        name: "TRI ALL 3 Clam Shell Wheel Safe",
        length: 31,
        width: 10,
        height: 31,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 238
    },
    {
        name: "BIKND Helium Bike Case",
        length: 50,
        width: 12,
        height: 34,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 123
    },
    {
        name: "PIKA PACKWORKS EEP Stretch",
        length: 50,
        width: 10,
        height: 30,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 173
    },
    {
        name: "PIKA PACKWORKS EEP Standard",
        length: 49,
        width: 10,
        height: 28,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 172
    },
    {
        name: "PIKA PACKWORKS EEP ISP",
        length: 50,
        width: 10,
        height: 38,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 174
    },
    {
        name: "AERUS Biospeed Bicycle Travel Case",
        length: 48,
        width: 10,
        height: 28,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 102
    },
    {
        name: "BIKEBOX Alan",
        length: 46,
        width: 38,
        height: 15,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 103
    },
    {
        name: "BIKE PRO Tandem Race Case",
        length: 72,
        width: 10,
        height: 34,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 135
    },
    {
        name: "RUSTER Armored Hen House Frame Bag",
        length: 47,
        width: 6,
        height: 26,
        linearUnit: "IN",
        weight: 45,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 182
    },
    {
        name: "RUSTER Armored Wheel Bag",
        length: 29,
        width: 8,
        height: 29,
        linearUnit: "IN",
        weight: 20,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 183
    },
    {
        name: "THULE Round Trip Transition",
        length: 54,
        width: 16,
        height: 37,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 221
    },
    {
        name: "PRO BIKE GEAR - Travel Bag Mega",
        length: 53,
        width: 12,
        height: 34,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 178
    },
    {
        name: "B&W Team Bike Case",
        length: 49,
        width: 13,
        height: 45,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 124
    },
    {
        name: "PIKA PACKWORKS EEP 29er",
        length: 54,
        width: 10,
        height: 32,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 175
    },
    {
        name: "PIKA PACKWORKS DUO",
        length: 53,
        width: 22,
        height: 34,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 176
    },
    {
        name: "DAKINE Bike Bag 15s",
        length: 51,
        width: 13,
        height: 30,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 153
    },
    {
        name: "DAKINE Utility Duffle 90L 15s",
        length: 30,
        width: 14,
        height: 16,
        linearUnit: "IN",
        weight: 50,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 154
    },
    {
        name: "SERFAS SBC",
        length: 47,
        width: 11,
        height: 30,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 214
    },
    {
        name: "BIKEFLIGHTS 2016 Legacy Bike Box",
        length: 43,
        width: 11,
        height: 32,
        linearUnit: "IN",
        weight: 50,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 122
    },
    {
        name: "TOPEAK Packgo X",
        length: 50,
        width: 14,
        height: 33,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 230
    },
    {
        name: "B&W Bike Box II",
        length: 47,
        width: 12,
        height: 35,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 128
    },
    {
        name: "DB Savage Bike bag",
        length: 57,
        width: 12,
        height: 34,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 155
    },
    {
        name: "BUXUM Box",
        length: 53,
        width: 14,
        height: 33,
        linearUnit: "IN",
        weight: 60,
        weightUnit: "LB",
        isEbike: false,
        isExact: false,
        sortOrder: 141
    },
    {
        name: "BIKEFLIGHTS Bike Box LARGE",
        length: 62,
        width: 13,
        height: 33,
        linearUnit: "IN",
        weight: 85,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 120
    },
    {
        name: "BIKEFLIGHTS Bike Box MEDIUM",
        length: 45,
        width: 12,
        height: 30,
        linearUnit: "IN",
        weight: 65,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 119
    },
    {
        name: "ORUCASE Airport Ninja Medium",
        length: 28,
        width: 8,
        height: 34,
        linearUnit: "IN",
        weight: 35,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 161
    },
    {
        name: "ORUCASE Airport Ninja Large",
        length: 37,
        width: 10,
        height: 30,
        linearUnit: "IN",
        weight: 45,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 162
    },
    {
        name: "ORUCASE B2-R",
        length: 34,
        width: 9,
        height: 28,
        linearUnit: "IN",
        weight: 45,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 163
    },
    {
        name: "ORUCASE B2-MTb",
        length: 36,
        width: 12,
        height: 30,
        linearUnit: "IN",
        weight: 55,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 164
    },
    {
        name: "EVOC Road Bike Bag Pro",
        length: 56,
        width: 21,
        height: 36,
        linearUnit: "IN",
        weight: 65,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 159
    },
    {
        name: "EVOC Bike Travel Bag Pro",
        length: 58,
        width: 15,
        height: 34,
        linearUnit: "IN",
        weight: 65,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 157
    },
    {
        name: "THULE RoundTrip Road/Gravel",
        length: 49,
        width: 13,
        height: 34,
        linearUnit: "IN",
        weight: 65,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 222
    },
    {
        name: "THULE RoundTrip MTB",
        length: 57,
        width: 15,
        height: 35,
        linearUnit: "IN",
        weight: 65,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 223
    },
    {
        name: "THULE RoundTrip Pro XT",
        length: 50,
        width: 12,
        height: 35,
        linearUnit: "IN",
        weight: 65,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 224
    },
    {
        name: "EVOC Bike Travel Bag XL",
        length: 56,
        width: 17,
        height: 33,
        linearUnit: "IN",
        weight: 65,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 158
    },
    {
        name: "EVOC BMX Travel Bag",
        length: 53,
        width: 12,
        height: 24,
        linearUnit: "IN",
        weight: 50,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 160
    },
    {
        name: "BIKEFLIGHTS Bike Box X-LARGE",
        length: 56,
        width: 21,
        height: 32,
        linearUnit: "IN",
        weight: 75,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 121
    },
    {
        name: "BIKEFLIGHTS Bike Box SMALL",
        length: 43,
        width: 10,
        height: 24,
        linearUnit: "IN",
        weight: 55,
        weightUnit: "LB",
        isEbike: false,
        isExact: true,
        sortOrder: 118
    },
    {
        name: "SANTANA Tandem Flight Case",
        length: 32,
        width: 12,
        height: 70,
        linearUnit: "IN",
        weight: 70,
        weightUnit: "LB",
        isEbike: false,
        isExact: false,
        sortOrder: 230
    }
]

export const countries = [
    {
        "countryId": 1,
        "code": "AF",
        "name": "Afghanistan",
        "phoneCountryCode": "93",
        "active": false
    },
    {
        "countryId": 2,
        "code": "AL",
        "name": "Albania",
        "phoneCountryCode": "355",
        "active": false
    },
    {
        "countryId": 3,
        "code": "DZ",
        "name": "Algeria",
        "phoneCountryCode": "213",
        "active": false
    },
    {
        "countryId": 4,
        "code": "AD",
        "name": "Andorra",
        "phoneCountryCode": "376",
        "active": false
    },
    {
        "countryId": 5,
        "code": "AO",
        "name": "Angola",
        "phoneCountryCode": "244",
        "active": false
    },
    {
        "countryId": 6,
        "code": "AI",
        "name": "Anguilla",
        "phoneCountryCode": "1264",
        "active": false
    },
    {
        "countryId": 7,
        "code": "AG",
        "name": "Antigua",
        "phoneCountryCode": "1268",
        "active": false
    },
    {
        "countryId": 8,
        "code": "AR",
        "name": "Argentina",
        "phoneCountryCode": "54",
        "active": false
    },
    {
        "countryId": 9,
        "code": "AM",
        "name": "Armenia",
        "phoneCountryCode": "374",
        "active": false
    },
    {
        "countryId": 10,
        "code": "AW",
        "name": "Aruba",
        "phoneCountryCode": "297",
        "active": false
    },
    {
        "countryId": 11,
        "code": "AU",
        "name": "Australia",
        "phoneCountryCode": "61",
        "active": true
    },
    {
        "countryId": 12,
        "code": "AT",
        "name": "Austria",
        "phoneCountryCode": "43",
        "active": true
    },
    {
        "countryId": 13,
        "code": "AZ",
        "name": "Azerbaijan",
        "phoneCountryCode": "994",
        "active": false
    },
    {
        "countryId": 14,
        "code": "BS",
        "name": "Bahamas",
        "phoneCountryCode": "1242",
        "active": false
    },
    {
        "countryId": 15,
        "code": "BH",
        "name": "Bahrain",
        "phoneCountryCode": "973",
        "active": false
    },
    {
        "countryId": 16,
        "code": "BD",
        "name": "Bangladesh",
        "phoneCountryCode": "880",
        "active": false
    },
    {
        "countryId": 17,
        "code": "BB",
        "name": "Barbados",
        "phoneCountryCode": "1246",
        "active": false
    },
    {
        "countryId": 18,
        "code": "BY",
        "name": "Belarus",
        "phoneCountryCode": "375",
        "active": false
    },
    {
        "countryId": 19,
        "code": "BE",
        "name": "Belgium",
        "phoneCountryCode": "32",
        "active": true
    },
    {
        "countryId": 20,
        "code": "BZ",
        "name": "Belize",
        "phoneCountryCode": "501",
        "active": false
    },
    {
        "countryId": 21,
        "code": "BJ",
        "name": "Benin",
        "phoneCountryCode": "229",
        "active": false
    },
    {
        "countryId": 22,
        "code": "BM",
        "name": "Bermuda",
        "phoneCountryCode": "1441",
        "active": false
    },
    {
        "countryId": 23,
        "code": "BT",
        "name": "Bhutan",
        "phoneCountryCode": "975",
        "active": false
    },
    {
        "countryId": 24,
        "code": "BO",
        "name": "Bolivia",
        "phoneCountryCode": "591",
        "active": false
    },
    {
        "countryId": 25,
        "code": "BA",
        "name": "Bosnia Hercegovina",
        "phoneCountryCode": "387",
        "active": false
    },
    {
        "countryId": 26,
        "code": "BW",
        "name": "Botswana",
        "phoneCountryCode": "267",
        "active": false
    },
    {
        "countryId": 27,
        "code": "BR",
        "name": "Brazil",
        "phoneCountryCode": "55",
        "active": false
    },
    {
        "countryId": 28,
        "code": "VG",
        "name": "British Virgin Islands",
        "phoneCountryCode": "1284",
        "active": false
    },
    {
        "countryId": 29,
        "code": "BN",
        "name": "Brunei",
        "phoneCountryCode": "673",
        "active": false
    },
    {
        "countryId": 30,
        "code": "BG",
        "name": "Bulgaria",
        "phoneCountryCode": "359",
        "active": true
    },
    {
        "countryId": 31,
        "code": "BF",
        "name": "Burkina Faso",
        "phoneCountryCode": "226",
        "active": false
    },
    {
        "countryId": 32,
        "code": "BI",
        "name": "Burundi",
        "phoneCountryCode": "257",
        "active": false
    },
    {
        "countryId": 33,
        "code": "KH",
        "name": "Cambodia",
        "phoneCountryCode": "855",
        "active": false
    },
    {
        "countryId": 34,
        "code": "CM",
        "name": "Cameroon",
        "phoneCountryCode": "237",
        "active": false
    },
    {
        "countryId": 35,
        "code": "CA",
        "name": "Canada",
        "phoneCountryCode": "1",
        "active": true
    },
    {
        "countryId": 36,
        "code": "CV",
        "name": "Cape Verde",
        "phoneCountryCode": "238",
        "active": false
    },
    {
        "countryId": 37,
        "code": "KY",
        "name": "Cayman Islands",
        "phoneCountryCode": "1345",
        "active": false
    },
    {
        "countryId": 38,
        "code": "TD",
        "name": "Chad",
        "phoneCountryCode": "235",
        "active": false
    },
    {
        "countryId": 39,
        "code": "CL",
        "name": "Chile",
        "phoneCountryCode": "56",
        "active": false
    },
    {
        "countryId": 40,
        "code": "CN",
        "name": "China",
        "phoneCountryCode": "86",
        "active": true
    },
    {
        "countryId": 41,
        "code": "CO",
        "name": "Colombia",
        "phoneCountryCode": "57",
        "active": false
    },
    {
        "countryId": 42,
        "code": "CG",
        "name": "Congo",
        "phoneCountryCode": "242",
        "active": false
    },
    {
        "countryId": 43,
        "code": "CD",
        "name": "Congo Democratic Republic Of",
        "phoneCountryCode": "242",
        "active": false
    },
    {
        "countryId": 44,
        "code": "CK",
        "name": "Cook Islands",
        "phoneCountryCode": "682",
        "active": false
    },
    {
        "countryId": 45,
        "code": "CR",
        "name": "Costa Rica",
        "phoneCountryCode": "506",
        "active": false
    },
    {
        "countryId": 46,
        "code": "HR",
        "name": "Croatia",
        "phoneCountryCode": "385",
        "active": true
    },
    {
        "countryId": 47,
        "code": "CY",
        "name": "Cyprus",
        "phoneCountryCode": "357",
        "active": true
    },
    {
        "countryId": 48,
        "code": "CZ",
        "name": "Czech Republic",
        "phoneCountryCode": "420",
        "active": true
    },
    {
        "countryId": 49,
        "code": "DK",
        "name": "Denmark",
        "phoneCountryCode": "45",
        "active": true
    },
    {
        "countryId": 50,
        "code": "DJ",
        "name": "Djibouti",
        "phoneCountryCode": "253",
        "active": false
    },
    {
        "countryId": 51,
        "code": "DM",
        "name": "Dominica",
        "phoneCountryCode": "1767",
        "active": false
    },
    {
        "countryId": 52,
        "code": "DO",
        "name": "Dominican Republic",
        "phoneCountryCode": "1809",
        "active": true
    },
    {
        "countryId": 53,
        "code": "EC",
        "name": "Ecuador",
        "phoneCountryCode": "593",
        "active": false
    },
    {
        "countryId": 54,
        "code": "EG",
        "name": "Egypt",
        "phoneCountryCode": "20",
        "active": false
    },
    {
        "countryId": 55,
        "code": "SV",
        "name": "El Salvador",
        "phoneCountryCode": "503",
        "active": false
    },
    {
        "countryId": 56,
        "code": "GQ",
        "name": "Equatorial Guinea",
        "phoneCountryCode": "240",
        "active": false
    },
    {
        "countryId": 57,
        "code": "ER",
        "name": "Eritrea",
        "phoneCountryCode": "291",
        "active": false
    },
    {
        "countryId": 58,
        "code": "EE",
        "name": "Estonia",
        "phoneCountryCode": "372",
        "active": true
    },
    {
        "countryId": 59,
        "code": "ET",
        "name": "Ethiopia",
        "phoneCountryCode": "251",
        "active": false
    },
    {
        "countryId": 60,
        "code": "FO",
        "name": "Faeroe Islands",
        "phoneCountryCode": "298",
        "active": false
    },
    {
        "countryId": 61,
        "code": "FJ",
        "name": "Fiji Islands",
        "phoneCountryCode": "679",
        "active": false
    },
    {
        "countryId": 62,
        "code": "FI",
        "name": "Finland",
        "phoneCountryCode": "358",
        "active": true
    },
    {
        "countryId": 63,
        "code": "FR",
        "name": "France",
        "phoneCountryCode": "33",
        "active": true
    },
    {
        "countryId": 64,
        "code": "GF",
        "name": "French Guiana",
        "phoneCountryCode": "594",
        "active": false
    },
    {
        "countryId": 65,
        "code": "PF",
        "name": "French Polynesia",
        "phoneCountryCode": "689",
        "active": false
    },
    {
        "countryId": 66,
        "code": "GA",
        "name": "Gabon",
        "phoneCountryCode": "241",
        "active": false
    },
    {
        "countryId": 67,
        "code": "GM",
        "name": "Gambia",
        "phoneCountryCode": "220",
        "active": false
    },
    {
        "countryId": 68,
        "code": "GE",
        "name": "Georgia",
        "phoneCountryCode": "995",
        "active": false
    },
    {
        "countryId": 69,
        "code": "DE",
        "name": "Germany",
        "phoneCountryCode": "49",
        "active": true
    },
    {
        "countryId": 70,
        "code": "GH",
        "name": "Ghana",
        "phoneCountryCode": "233",
        "active": false
    },
    {
        "countryId": 71,
        "code": "GI",
        "name": "Gibraltar",
        "phoneCountryCode": "350",
        "active": false
    },
    {
        "countryId": 72,
        "code": "GR",
        "name": "Greece",
        "phoneCountryCode": "30",
        "active": true
    },
    {
        "countryId": 73,
        "code": "GL",
        "name": "Greenland",
        "phoneCountryCode": "299",
        "active": false
    },
    {
        "countryId": 74,
        "code": "GD",
        "name": "Grenada",
        "phoneCountryCode": "1473",
        "active": false
    },
    {
        "countryId": 75,
        "code": "GP",
        "name": "Guadeloupe",
        "phoneCountryCode": "590",
        "active": false
    },
    {
        "countryId": 76,
        "code": "GU",
        "name": "Guam",
        "phoneCountryCode": "1671",
        "active": true
    },
    {
        "countryId": 77,
        "code": "GT",
        "name": "Guatemala",
        "phoneCountryCode": "502",
        "active": false
    },
    {
        "countryId": 78,
        "code": "GN",
        "name": "Guinea",
        "phoneCountryCode": "224",
        "active": false
    },
    {
        "countryId": 79,
        "code": "GY",
        "name": "Guyana",
        "phoneCountryCode": "592",
        "active": false
    },
    {
        "countryId": 80,
        "code": "HT",
        "name": "Haiti",
        "phoneCountryCode": "509",
        "active": false
    },
    {
        "countryId": 81,
        "code": "HN",
        "name": "Honduras",
        "phoneCountryCode": "504",
        "active": false
    },
    {
        "countryId": 82,
        "code": "HK",
        "name": "Hong Kong",
        "phoneCountryCode": "852",
        "active": true
    },
    {
        "countryId": 83,
        "code": "HU",
        "name": "Hungary",
        "phoneCountryCode": "36",
        "active": true
    },
    {
        "countryId": 84,
        "code": "IS",
        "name": "Iceland",
        "phoneCountryCode": "354",
        "active": true
    },
    {
        "countryId": 85,
        "code": "IN",
        "name": "India",
        "phoneCountryCode": "91",
        "active": true
    },
    {
        "countryId": 86,
        "code": "ID",
        "name": "Indonesia",
        "phoneCountryCode": "62",
        "active": true
    },
    {
        "countryId": 87,
        "code": "IQ",
        "name": "Iraq",
        "phoneCountryCode": "964",
        "active": false
    },
    {
        "countryId": 88,
        "code": "IE",
        "name": "Ireland",
        "phoneCountryCode": "353",
        "active": true
    },
    {
        "countryId": 89,
        "code": "IL",
        "name": "Israel",
        "phoneCountryCode": "972",
        "active": true
    },
    {
        "countryId": 90,
        "code": "IT",
        "name": "Italy",
        "phoneCountryCode": "39",
        "active": true
    },
    {
        "countryId": 91,
        "code": "CI",
        "name": "Ivory Coast",
        "phoneCountryCode": "225",
        "active": false
    },
    {
        "countryId": 92,
        "code": "JM",
        "name": "Jamaica",
        "phoneCountryCode": "1876",
        "active": false
    },
    {
        "countryId": 93,
        "code": "JP",
        "name": "Japan",
        "phoneCountryCode": "81",
        "active": true
    },
    {
        "countryId": 94,
        "code": "JO",
        "name": "Jordan",
        "phoneCountryCode": "962",
        "active": false
    },
    {
        "countryId": 95,
        "code": "KZ",
        "name": "Kazakstan",
        "phoneCountryCode": "7",
        "active": true
    },
    {
        "countryId": 96,
        "code": "KE",
        "name": "Kenya",
        "phoneCountryCode": "254",
        "active": false
    },
    {
        "countryId": 97,
        "code": "KI",
        "name": "Kiribati",
        "phoneCountryCode": "686",
        "active": false
    },
    {
        "countryId": 98,
        "code": "KR",
        "name": "South Korea",
        "phoneCountryCode": "82",
        "active": true
    },
    {
        "countryId": 99,
        "code": "KW",
        "name": "Kuwait",
        "phoneCountryCode": "965",
        "active": false
    },
    {
        "countryId": 100,
        "code": "KG",
        "name": "Kyrgyzstan",
        "phoneCountryCode": "996",
        "active": true
    },
    {
        "countryId": 101,
        "code": "LA",
        "name": "Laos",
        "phoneCountryCode": "856",
        "active": true
    },
    {
        "countryId": 102,
        "code": "LV",
        "name": "Latvia",
        "phoneCountryCode": "371",
        "active": true
    },
    {
        "countryId": 103,
        "code": "LB",
        "name": "Lebanon",
        "phoneCountryCode": "961",
        "active": false
    },
    {
        "countryId": 104,
        "code": "LS",
        "name": "Lesotho",
        "phoneCountryCode": "266",
        "active": false
    },
    {
        "countryId": 105,
        "code": "LR",
        "name": "Liberia",
        "phoneCountryCode": "231",
        "active": false
    },
    {
        "countryId": 106,
        "code": "LY",
        "name": "Libya",
        "phoneCountryCode": "218",
        "active": false
    },
    {
        "countryId": 107,
        "code": "LI",
        "name": "Liechtenstein",
        "phoneCountryCode": "423",
        "active": false
    },
    {
        "countryId": 108,
        "code": "LT",
        "name": "Lithuania",
        "phoneCountryCode": "370",
        "active": true
    },
    {
        "countryId": 109,
        "code": "LU",
        "name": "Luxembourg",
        "phoneCountryCode": "352",
        "active": true
    },
    {
        "countryId": 110,
        "code": "MO",
        "name": "Macau",
        "phoneCountryCode": "853",
        "active": false
    },
    {
        "countryId": 111,
        "code": "MK",
        "name": "Macedonia",
        "phoneCountryCode": "389",
        "active": false
    },
    {
        "countryId": 112,
        "code": "MG",
        "name": "Madagascar",
        "phoneCountryCode": "261",
        "active": false
    },
    {
        "countryId": 113,
        "code": "MW",
        "name": "Malawi",
        "phoneCountryCode": "265",
        "active": false
    },
    {
        "countryId": 114,
        "code": "MY",
        "name": "Malaysia",
        "phoneCountryCode": "60",
        "active": false
    },
    {
        "countryId": 115,
        "code": "MV",
        "name": "Maldives",
        "phoneCountryCode": "960",
        "active": false
    },
    {
        "countryId": 116,
        "code": "ML",
        "name": "Mali",
        "phoneCountryCode": "223",
        "active": false
    },
    {
        "countryId": 117,
        "code": "MT",
        "name": "Malta",
        "phoneCountryCode": "356",
        "active": true
    },
    {
        "countryId": 118,
        "code": "MH",
        "name": "Marshall Islands",
        "phoneCountryCode": "692",
        "active": false
    },
    {
        "countryId": 119,
        "code": "MQ",
        "name": "Martinique",
        "phoneCountryCode": "596",
        "active": false
    },
    {
        "countryId": 120,
        "code": "MR",
        "name": "Mauritania",
        "phoneCountryCode": "222",
        "active": false
    },
    {
        "countryId": 121,
        "code": "MU",
        "name": "Mauritius",
        "phoneCountryCode": "230",
        "active": false
    },
    {
        "countryId": 122,
        "code": "MX",
        "name": "Mexico",
        "phoneCountryCode": "52",
        "active": false
    },
    {
        "countryId": 123,
        "code": "FM",
        "name": "Micronesia",
        "phoneCountryCode": "691",
        "active": false
    },
    {
        "countryId": 124,
        "code": "MD",
        "name": "Moldova",
        "phoneCountryCode": "373",
        "active": false
    },
    {
        "countryId": 125,
        "code": "MC",
        "name": "Monaco",
        "phoneCountryCode": "377",
        "active": false
    },
    {
        "countryId": 126,
        "code": "MN",
        "name": "Mongolia",
        "phoneCountryCode": "976",
        "active": false
    },
    {
        "countryId": 127,
        "code": "MS",
        "name": "Montserrat",
        "phoneCountryCode": "1664",
        "active": false
    },
    {
        "countryId": 128,
        "code": "MA",
        "name": "Morocco",
        "phoneCountryCode": "212",
        "active": true
    },
    {
        "countryId": 129,
        "code": "MZ",
        "name": "Mozambique",
        "phoneCountryCode": "258",
        "active": false
    },
    {
        "countryId": 130,
        "code": "MM",
        "name": "Myanmar",
        "phoneCountryCode": "95",
        "active": false
    },
    {
        "countryId": 131,
        "code": "NA",
        "name": "Namibia",
        "phoneCountryCode": "264",
        "active": false
    },
    {
        "countryId": 132,
        "code": "NR",
        "name": "Nauru",
        "phoneCountryCode": "674",
        "active": false
    },
    {
        "countryId": 133,
        "code": "NP",
        "name": "Nepal",
        "phoneCountryCode": "977",
        "active": false
    },
    {
        "countryId": 134,
        "code": "AN",
        "name": "Netherland Antilles",
        "phoneCountryCode": "599",
        "active": false
    },
    {
        "countryId": 135,
        "code": "NL",
        "name": "Netherlands",
        "phoneCountryCode": "31",
        "active": true
    },
    {
        "countryId": 136,
        "code": "NC",
        "name": "New Caledonia",
        "phoneCountryCode": "687",
        "active": false
    },
    {
        "countryId": 137,
        "code": "NZ",
        "name": "New Zealand",
        "phoneCountryCode": "64",
        "active": true
    },
    {
        "countryId": 138,
        "code": "NI",
        "name": "Nicaragua",
        "phoneCountryCode": "505",
        "active": false
    },
    {
        "countryId": 139,
        "code": "NE",
        "name": "Niger",
        "phoneCountryCode": "227",
        "active": false
    },
    {
        "countryId": 140,
        "code": "NG",
        "name": "Nigeria",
        "phoneCountryCode": "234",
        "active": false
    },
    {
        "countryId": 141,
        "code": "NU",
        "name": "Niue",
        "phoneCountryCode": "683",
        "active": false
    },
    {
        "countryId": 142,
        "code": "NO",
        "name": "Norway",
        "phoneCountryCode": "47",
        "active": true
    },
    {
        "countryId": 143,
        "code": "OM",
        "name": "Oman",
        "phoneCountryCode": "968",
        "active": false
    },
    {
        "countryId": 144,
        "code": "PK",
        "name": "Pakistan",
        "phoneCountryCode": "92",
        "active": false
    },
    {
        "countryId": 145,
        "code": "PW",
        "name": "Palau",
        "phoneCountryCode": "680",
        "active": false
    },
    {
        "countryId": 146,
        "code": "PA",
        "name": "Panama",
        "phoneCountryCode": "507",
        "active": true
    },
    {
        "countryId": 147,
        "code": "PG",
        "name": "Papua New Guinea (Niugini)",
        "phoneCountryCode": "675",
        "active": false
    },
    {
        "countryId": 148,
        "code": "PY",
        "name": "Paraguay",
        "phoneCountryCode": "595",
        "active": false
    },
    {
        "countryId": 149,
        "code": "PE",
        "name": "Peru",
        "phoneCountryCode": "51",
        "active": false
    },
    {
        "countryId": 150,
        "code": "PH",
        "name": "Philippines",
        "phoneCountryCode": "63",
        "active": true
    },
    {
        "countryId": 151,
        "code": "PL",
        "name": "Poland",
        "phoneCountryCode": "48",
        "active": true
    },
    {
        "countryId": 152,
        "code": "PT",
        "name": "Portugal",
        "phoneCountryCode": "351",
        "active": true
    },
    {
        "countryId": 153,
        "code": "QA",
        "name": "Qatar",
        "phoneCountryCode": "974",
        "active": false
    },
    {
        "countryId": 154,
        "code": "RE",
        "name": "Reunion",
        "phoneCountryCode": "262",
        "active": false
    },
    {
        "countryId": 155,
        "code": "RO",
        "name": "Romania",
        "phoneCountryCode": "40",
        "active": true
    },
    {
        "countryId": 156,
        "code": "RU",
        "name": "Russia",
        "phoneCountryCode": "70",
        "active": false
    },
    {
        "countryId": 157,
        "code": "RW",
        "name": "Rwanda",
        "phoneCountryCode": "250",
        "active": false
    },
    {
        "countryId": 158,
        "code": "VC",
        "name": "Saint Vincent And The Grenadines",
        "phoneCountryCode": "1784",
        "active": false
    },
    {
        "countryId": 159,
        "code": "AS",
        "name": "American Samoa",
        "phoneCountryCode": "1684",
        "active": false
    },
    {
        "countryId": 160,
        "code": "WS",
        "name": "Samoa",
        "phoneCountryCode": "684",
        "active": false
    },
    {
        "countryId": 161,
        "code": "SM",
        "name": "San Marino",
        "phoneCountryCode": "378",
        "active": false
    },
    {
        "countryId": 162,
        "code": "SA",
        "name": "Saudi Arabia",
        "phoneCountryCode": "966",
        "active": true
    },
    {
        "countryId": 163,
        "code": "SN",
        "name": "Senegal",
        "phoneCountryCode": "221",
        "active": false
    },
    {
        "countryId": 164,
        "code": "SC",
        "name": "Seychelles Islands",
        "phoneCountryCode": "248",
        "active": false
    },
    {
        "countryId": 165,
        "code": "SG",
        "name": "Singapore",
        "phoneCountryCode": "65",
        "active": true
    },
    {
        "countryId": 166,
        "code": "SK",
        "name": "Slovakia",
        "phoneCountryCode": "421",
        "active": true
    },
    {
        "countryId": 167,
        "code": "SI",
        "name": "Slovenia",
        "phoneCountryCode": "386",
        "active": true
    },
    {
        "countryId": 168,
        "code": "SB",
        "name": "Solomon Islands",
        "phoneCountryCode": "677",
        "active": false
    },
    {
        "countryId": 169,
        "code": "ZA",
        "name": "South Africa",
        "phoneCountryCode": "27",
        "active": true
    },
    {
        "countryId": 170,
        "code": "ES",
        "name": "Spain",
        "phoneCountryCode": "34",
        "active": true
    },
    {
        "countryId": 171,
        "code": "LK",
        "name": "Sri Lanka",
        "phoneCountryCode": "94",
        "active": false
    },
    {
        "countryId": 172,
        "code": "KN",
        "name": "St. Kitts - Nevis",
        "phoneCountryCode": "1869",
        "active": false
    },
    {
        "countryId": 173,
        "code": "SR",
        "name": "Suriname",
        "phoneCountryCode": "597",
        "active": false
    },
    {
        "countryId": 174,
        "code": "SZ",
        "name": "Swaziland",
        "phoneCountryCode": "268",
        "active": false
    },
    {
        "countryId": 175,
        "code": "SE",
        "name": "Sweden",
        "phoneCountryCode": "46",
        "active": true
    },
    {
        "countryId": 176,
        "code": "CH",
        "name": "Switzerland",
        "phoneCountryCode": "41",
        "active": true
    },
    {
        "countryId": 177,
        "code": "SY",
        "name": "Syria",
        "phoneCountryCode": "963",
        "active": false
    },
    {
        "countryId": 178,
        "code": "TW",
        "name": "Taiwan",
        "phoneCountryCode": "886",
        "active": true
    },
    {
        "countryId": 179,
        "code": "TJ",
        "name": "Tajikistan",
        "phoneCountryCode": "992",
        "active": false
    },
    {
        "countryId": 180,
        "code": "TZ",
        "name": "Tanzania",
        "phoneCountryCode": "255",
        "active": false
    },
    {
        "countryId": 181,
        "code": "TH",
        "name": "Thailand",
        "phoneCountryCode": "66",
        "active": true
    },
    {
        "countryId": 182,
        "code": "TG",
        "name": "Togo",
        "phoneCountryCode": "228",
        "active": false
    },
    {
        "countryId": 183,
        "code": "TO",
        "name": "Tonga",
        "phoneCountryCode": "676",
        "active": false
    },
    {
        "countryId": 184,
        "code": "TT",
        "name": "Trinidad & Tobago",
        "phoneCountryCode": "1868",
        "active": false
    },
    {
        "countryId": 185,
        "code": "TN",
        "name": "Tunisia",
        "phoneCountryCode": "216",
        "active": false
    },
    {
        "countryId": 186,
        "code": "TR",
        "name": "Turkey",
        "phoneCountryCode": "90",
        "active": true
    },
    {
        "countryId": 187,
        "code": "TM",
        "name": "Turkmenistan",
        "phoneCountryCode": "7370",
        "active": false
    },
    {
        "countryId": 188,
        "code": "TC",
        "name": "Turks And Caicos Islands",
        "phoneCountryCode": "1649",
        "active": false
    },
    {
        "countryId": 189,
        "code": "TV",
        "name": "Tuvalu",
        "phoneCountryCode": "688",
        "active": false
    },
    {
        "countryId": 190,
        "code": "UG",
        "name": "Uganda",
        "phoneCountryCode": "256",
        "active": false
    },
    {
        "countryId": 191,
        "code": "UA",
        "name": "Ukraine",
        "phoneCountryCode": "380",
        "active": false
    },
    {
        "countryId": 192,
        "code": "AE",
        "name": "United Arab Emirates",
        "phoneCountryCode": "971",
        "active": true
    },
    {
        "countryId": 193,
        "code": "GB",
        "name": "United Kingdom",
        "phoneCountryCode": "44",
        "active": true
    },
    {
        "countryId": 194,
        "code": "US",
        "name": "United States",
        "phoneCountryCode": "1",
        "active": true
    },
    {
        "countryId": 195,
        "code": "UY",
        "name": "Uruguay",
        "phoneCountryCode": "598",
        "active": false
    },
    {
        "countryId": 196,
        "code": "UZ",
        "name": "Uzbekistan",
        "phoneCountryCode": "998",
        "active": false
    },
    {
        "countryId": 197,
        "code": "VU",
        "name": "Vanuatu",
        "phoneCountryCode": "678",
        "active": false
    },
    {
        "countryId": 198,
        "code": "VA",
        "name": "Vatican City State",
        "phoneCountryCode": "39",
        "active": false
    },
    {
        "countryId": 199,
        "code": "VE",
        "name": "Venezuela",
        "phoneCountryCode": "58",
        "active": false
    },
    {
        "countryId": 200,
        "code": "VN",
        "name": "Vietnam",
        "phoneCountryCode": "84",
        "active": false
    },
    {
        "countryId": 201,
        "code": "WF",
        "name": "Wallis & Futuna Islands",
        "phoneCountryCode": "681",
        "active": false
    },
    {
        "countryId": 202,
        "code": "EH",
        "name": "Western Sahara",
        "phoneCountryCode": "212",
        "active": false
    },
    {
        "countryId": 203,
        "code": "YE",
        "name": "Yemen",
        "phoneCountryCode": "967",
        "active": false
    },
    {
        "countryId": 204,
        "code": "ZM",
        "name": "Zambia",
        "phoneCountryCode": "260",
        "active": false
    },
    {
        "countryId": 205,
        "code": "ZW",
        "name": "Zimbabwe",
        "phoneCountryCode": "263",
        "active": false
    },
    {
        "countryId": 206,
        "code": "TL",
        "name": "East Timor",
        "phoneCountryCode": "670",
        "active": false
    },
    {
        "countryId": 207,
        "code": "ME",
        "name": "Montenegro",
        "active": false
    },
    {
        "countryId": 208,
        "code": "MP",
        "name": "Northern Mariana Islands",
        "phoneCountryCode": "1670",
        "active": false
    },
    {
        "countryId": 209,
        "code": "PS",
        "name": "Palestine",
        "phoneCountryCode": "970",
        "active": false
    },
    {
        "countryId": 210,
        "code": "PR",
        "name": "Puerto Rico",
        "phoneCountryCode": "1787",
        "active": true
    },
    {
        "countryId": 211,
        "code": "RS",
        "name": "Serbia",
        "active": false
    },
    {
        "countryId": 212,
        "code": "KR",
        "name": "South Korea",
        "phoneCountryCode": "82",
        "active": false
    },
    {
        "countryId": 213,
        "code": "LC",
        "name": "St. Lucia",
        "phoneCountryCode": "1758",
        "active": false
    },
    {
        "countryId": 214,
        "code": "VC",
        "name": "St. Vincent",
        "phoneCountryCode": "1784",
        "active": false
    },
    {
        "countryId": 215,
        "code": "VI",
        "name": "U.S. Virgin Islands",
        "phoneCountryCode": "1340",
        "active": true
    },
    {
        "countryId": 216,
        "code": "SB",
        "name": "St Barthélemy",
        "phoneCountryCode": "677",
        "active": false
    },
    {
        "countryId": 217,
        "code": "CW",
        "name": "Curacao",
        "active": false
    },
    {
        "countryId": 218,
        "code": "MP",
        "name": "Saipan",
        "phoneCountryCode": "1670",
        "active": false
    }
];

export const states = [
    {
        "id": 1,
        "stateprovcode": "AB",
        "stateprov": "Alberta",
        "country": "CA"
    },
    {
        "id": 2,
        "stateprovcode": "AK",
        "stateprov": "Alaska",
        "country": "US"
    },
    {
        "id": 3,
        "stateprovcode": "AL",
        "stateprov": "Alabama",
        "country": "US"
    },
    {
        "id": 4,
        "stateprovcode": "AR",
        "stateprov": "Arkansas",
        "country": "US"
    },
    {
        "id": 5,
        "stateprovcode": "AZ",
        "stateprov": "Arizona",
        "country": "US"
    },
    {
        "id": 6,
        "stateprovcode": "BC",
        "stateprov": "British Columbia",
        "country": "CA"
    },
    {
        "id": 7,
        "stateprovcode": "CA",
        "stateprov": "California",
        "country": "US"
    },
    {
        "id": 8,
        "stateprovcode": "CO",
        "stateprov": "Colorado",
        "country": "US"
    },
    {
        "id": 9,
        "stateprovcode": "CT",
        "stateprov": "Connecticut",
        "country": "US"
    },
    {
        "id": 10,
        "stateprovcode": "DC",
        "stateprov": "Washington DC",
        "country": "US"
    },
    {
        "id": 11,
        "stateprovcode": "DE",
        "stateprov": "Delaware",
        "country": "US"
    },
    {
        "id": 12,
        "stateprovcode": "FL",
        "stateprov": "Florida",
        "country": "US"
    },
    {
        "id": 13,
        "stateprovcode": "GA",
        "stateprov": "Georgia",
        "country": "US"
    },
    {
        "id": 14,
        "stateprovcode": "HI",
        "stateprov": "Hawaii",
        "country": "US"
    },
    {
        "id": 15,
        "stateprovcode": "IA",
        "stateprov": "Iowa",
        "country": "US"
    },
    {
        "id": 16,
        "stateprovcode": "ID",
        "stateprov": "Idaho",
        "country": "US"
    },
    {
        "id": 17,
        "stateprovcode": "IL",
        "stateprov": "Illinois",
        "country": "US"
    },
    {
        "id": 18,
        "stateprovcode": "IN",
        "stateprov": "Indiana",
        "country": "US"
    },
    {
        "id": 19,
        "stateprovcode": "KS",
        "stateprov": "Kansas",
        "country": "US"
    },
    {
        "id": 20,
        "stateprovcode": "KY",
        "stateprov": "Kentucky",
        "country": "US"
    },
    {
        "id": 21,
        "stateprovcode": "LA",
        "stateprov": "Louisiana",
        "country": "US"
    },
    {
        "id": 22,
        "stateprovcode": "MA",
        "stateprov": "Massachusetts",
        "country": "US"
    },
    {
        "id": 23,
        "stateprovcode": "MB",
        "stateprov": "Manitoba",
        "country": "CA"
    },
    {
        "id": 24,
        "stateprovcode": "MD",
        "stateprov": "Maryland",
        "country": "US"
    },
    {
        "id": 25,
        "stateprovcode": "ME",
        "stateprov": "Maine",
        "country": "US"
    },
    {
        "id": 26,
        "stateprovcode": "MI",
        "stateprov": "Michigan",
        "country": "US"
    },
    {
        "id": 27,
        "stateprovcode": "MN",
        "stateprov": "Minnesota",
        "country": "US"
    },
    {
        "id": 28,
        "stateprovcode": "MO",
        "stateprov": "Missouri",
        "country": "US"
    },
    {
        "id": 29,
        "stateprovcode": "MS",
        "stateprov": "Mississippi",
        "country": "US"
    },
    {
        "id": 30,
        "stateprovcode": "MT",
        "stateprov": "Montana",
        "country": "US"
    },
    {
        "id": 31,
        "stateprovcode": "NB",
        "stateprov": "New Brunswick",
        "country": "CA"
    },
    {
        "id": 32,
        "stateprovcode": "NC",
        "stateprov": "North Carolina",
        "country": "US"
    },
    {
        "id": 33,
        "stateprovcode": "ND",
        "stateprov": "North Dakota",
        "country": "US"
    },
    {
        "id": 34,
        "stateprovcode": "NE",
        "stateprov": "Nebraska",
        "country": "US"
    },
    {
        "id": 35,
        "stateprovcode": "NH",
        "stateprov": "New Hampshire",
        "country": "US"
    },
    {
        "id": 36,
        "stateprovcode": "NJ",
        "stateprov": "New Jersey",
        "country": "US"
    },
    {
        "id": 37,
        "stateprovcode": "NL",
        "stateprov": "NewFoundland and Labrador",
        "country": "CA"
    },
    {
        "id": 38,
        "stateprovcode": "NM",
        "stateprov": "New Mexico",
        "country": "US"
    },
    {
        "id": 39,
        "stateprovcode": "NS",
        "stateprov": "Nova Scotia",
        "country": "CA"
    },
    {
        "id": 40,
        "stateprovcode": "NT",
        "stateprov": "Northwest Territories",
        "country": "CA"
    },
    {
        "id": 41,
        "stateprovcode": "NU",
        "stateprov": "Nunavut",
        "country": "CA"
    },
    {
        "id": 42,
        "stateprovcode": "NV",
        "stateprov": "Nevada",
        "country": "US"
    },
    {
        "id": 43,
        "stateprovcode": "NY",
        "stateprov": "New York",
        "country": "US"
    },
    {
        "id": 44,
        "stateprovcode": "OH",
        "stateprov": "Ohio",
        "country": "US"
    },
    {
        "id": 45,
        "stateprovcode": "OK",
        "stateprov": "Oklahoma",
        "country": "US"
    },
    {
        "id": 46,
        "stateprovcode": "ON",
        "stateprov": "Ontario",
        "country": "CA"
    },
    {
        "id": 47,
        "stateprovcode": "OR",
        "stateprov": "Oregon",
        "country": "US"
    },
    {
        "id": 48,
        "stateprovcode": "PA",
        "stateprov": "Pennsylvania",
        "country": "US"
    },
    {
        "id": 49,
        "stateprovcode": "PE",
        "stateprov": "Prince Edward Island",
        "country": "CA"
    },
    {
        "id": 50,
        "stateprovcode": "PQ",
        "stateprov": "Quebec",
        "country": "CA"
    },
    {
        "id": 51,
        "stateprovcode": "RI",
        "stateprov": "Rhode Island",
        "country": "US"
    },
    {
        "id": 52,
        "stateprovcode": "SC",
        "stateprov": "South Carolina",
        "country": "US"
    },
    {
        "id": 53,
        "stateprovcode": "SD",
        "stateprov": "South Dakota",
        "country": "US"
    },
    {
        "id": 54,
        "stateprovcode": "SK",
        "stateprov": "Saskatchewan",
        "country": "CA"
    },
    {
        "id": 55,
        "stateprovcode": "TN",
        "stateprov": "Tennessee",
        "country": "US"
    },
    {
        "id": 56,
        "stateprovcode": "TX",
        "stateprov": "Texas",
        "country": "US"
    },
    {
        "id": 57,
        "stateprovcode": "UT",
        "stateprov": "Utah",
        "country": "US"
    },
    {
        "id": 58,
        "stateprovcode": "VA",
        "stateprov": "Virginia",
        "country": "US"
    },
    {
        "id": 59,
        "stateprovcode": "VT",
        "stateprov": "Vermont",
        "country": "US"
    },
    {
        "id": 60,
        "stateprovcode": "WA",
        "stateprov": "Washington",
        "country": "US"
    },
    {
        "id": 61,
        "stateprovcode": "WI",
        "stateprov": "Wisconsin",
        "country": "US"
    },
    {
        "id": 62,
        "stateprovcode": "WV",
        "stateprov": "West Virginia",
        "country": "US"
    },
    {
        "id": 63,
        "stateprovcode": "WY",
        "stateprov": "Wyoming",
        "country": "US"
    },
    {
        "id": 64,
        "stateprovcode": "YT",
        "stateprov": "Yukon Territories",
        "country": "CA"
    },
    {
        "id": 66,
        "stateprovcode": "ARA",
        "stateprov": "Auvergne-Rhône-Alpes",
        "country": "FR"
    },
    {
        "id": 67,
        "stateprovcode": "BFC",
        "stateprov": "Bourgogne-Franche-Comté",
        "country": "FR"
    },
    {
        "id": 68,
        "stateprovcode": "BRE",
        "stateprov": "Brittany",
        "country": "FR"
    },
    {
        "id": 69,
        "stateprovcode": "CVL",
        "stateprov": "Centre-Val de Loire",
        "country": "FR"
    },
    {
        "id": 70,
        "stateprovcode": "COR",
        "stateprov": "Corsica",
        "country": "FR"
    },
    {
        "id": 71,
        "stateprovcode": "GES",
        "stateprov": "Grand Est",
        "country": "FR"
    },
    {
        "id": 72,
        "stateprovcode": "HDF",
        "stateprov": "Hauts-de-France",
        "country": "FR"
    },
    {
        "id": 73,
        "stateprovcode": "IDF",
        "stateprov": "Île-de-France",
        "country": "FR"
    },
    {
        "id": 74,
        "stateprovcode": "NOR",
        "stateprov": "Normandy",
        "country": "FR"
    },
    {
        "id": 75,
        "stateprovcode": "NAQ",
        "stateprov": "Nouvelle-Aquitaine",
        "country": "FR"
    },
    {
        "id": 76,
        "stateprovcode": "OCC",
        "stateprov": "Occitanie",
        "country": "FR"
    },
    {
        "id": 77,
        "stateprovcode": "PDL",
        "stateprov": "Pays de la Loire",
        "country": "FR"
    },
    {
        "id": 78,
        "stateprovcode": "PAC",
        "stateprov": "Provence-Alpes-Côte d'Azur",
        "country": "FR"
    },
    {
        "id": 79,
        "stateprovcode": "NSW",
        "stateprov": "New South Wales",
        "country": "AU"
    },
    {
        "id": 80,
        "stateprovcode": "NT",
        "stateprov": "Northern Territory",
        "country": "AU"
    },
    {
        "id": 81,
        "stateprovcode": "QLD",
        "stateprov": "Queensland",
        "country": "AU"
    },
    {
        "id": 82,
        "stateprovcode": "SA",
        "stateprov": "South Australia",
        "country": "AU"
    },
    {
        "id": 83,
        "stateprovcode": "TAS",
        "stateprov": "Tasmania",
        "country": "AU"
    },
    {
        "id": 84,
        "stateprovcode": "VIC",
        "stateprov": "Victoria",
        "country": "AU"
    },
    {
        "id": 85,
        "stateprovcode": "WA",
        "stateprov": "Western Australia",
        "country": "AU"
    },
    {
        "id": 86,
        "stateprovcode": "1",
        "stateprov": "Burgenland",
        "country": "AT"
    },
    {
        "id": 87,
        "stateprovcode": "2",
        "stateprov": "Carinthia",
        "country": "AT"
    },
    {
        "id": 88,
        "stateprovcode": "3",
        "stateprov": "Lower Austria",
        "country": "AT"
    },
    {
        "id": 89,
        "stateprovcode": "5",
        "stateprov": "Salzburg",
        "country": "AT"
    },
    {
        "id": 90,
        "stateprovcode": "6",
        "stateprov": "Styria",
        "country": "AT"
    },
    {
        "id": 91,
        "stateprovcode": "7",
        "stateprov": "Tyrol",
        "country": "AT"
    },
    {
        "id": 92,
        "stateprovcode": "4",
        "stateprov": "Upper Austria",
        "country": "AT"
    },
    {
        "id": 93,
        "stateprovcode": "9",
        "stateprov": "Vienna",
        "country": "AT"
    },
    {
        "id": 94,
        "stateprovcode": "8",
        "stateprov": "Vorarlberg",
        "country": "AT"
    },
    {
        "id": 95,
        "stateprovcode": "AN",
        "stateprov": "Antwerpen",
        "country": "BE"
    },
    {
        "id": 96,
        "stateprovcode": "OV",
        "stateprov": "Oost-Vlaanderen",
        "country": "BE"
    },
    {
        "id": 97,
        "stateprovcode": "VB",
        "stateprov": "Vlaams-Brabant",
        "country": "BE"
    },
    {
        "id": 98,
        "stateprovcode": "HT",
        "stateprov": "Henegouwen",
        "country": "BE"
    },
    {
        "id": 99,
        "stateprovcode": "LG",
        "stateprov": "Liège",
        "country": "BE"
    },
    {
        "id": 100,
        "stateprovcode": "LI",
        "stateprov": "Limbourg",
        "country": "BE"
    },
    {
        "id": 101,
        "stateprovcode": "LX",
        "stateprov": "Luxemburg",
        "country": "BE"
    },
    {
        "id": 102,
        "stateprovcode": "NA",
        "stateprov": "Namür",
        "country": "BE"
    },
    {
        "id": 103,
        "stateprovcode": "BW",
        "stateprov": "Brabant Wallon",
        "country": "BE"
    },
    {
        "id": 104,
        "stateprovcode": "WV",
        "stateprov": "West-Vlaanderen",
        "country": "BE"
    },
    {
        "id": 105,
        "stateprovcode": "BW",
        "stateprov": "Baden-Württemberg",
        "country": "DE"
    },
    {
        "id": 106,
        "stateprovcode": "BY",
        "stateprov": "Bavaria",
        "country": "DE"
    },
    {
        "id": 107,
        "stateprovcode": "BE",
        "stateprov": "Berlin",
        "country": "DE"
    },
    {
        "id": 108,
        "stateprovcode": "BB",
        "stateprov": "Brandenburg",
        "country": "DE"
    },
    {
        "id": 109,
        "stateprovcode": "HB",
        "stateprov": "Bremen",
        "country": "DE"
    },
    {
        "id": 110,
        "stateprovcode": "HH",
        "stateprov": "Hamburg",
        "country": "DE"
    },
    {
        "id": 111,
        "stateprovcode": "HE",
        "stateprov": "Hesse",
        "country": "DE"
    },
    {
        "id": 112,
        "stateprovcode": "NI",
        "stateprov": "Lower Saxony",
        "country": "DE"
    },
    {
        "id": 113,
        "stateprovcode": "MV",
        "stateprov": "Mecklenburg-Vorpommern",
        "country": "DE"
    },
    {
        "id": 114,
        "stateprovcode": "NW",
        "stateprov": "North Rhine-Westphalia",
        "country": "DE"
    },
    {
        "id": 115,
        "stateprovcode": "RP",
        "stateprov": "Rhineland-Palatinate",
        "country": "DE"
    },
    {
        "id": 116,
        "stateprovcode": "SL",
        "stateprov": "Saarland",
        "country": "DE"
    },
    {
        "id": 117,
        "stateprovcode": "SN",
        "stateprov": "Saxony",
        "country": "DE"
    },
    {
        "id": 118,
        "stateprovcode": "ST",
        "stateprov": "Saxony-Anhalt",
        "country": "DE"
    },
    {
        "id": 119,
        "stateprovcode": "SH",
        "stateprov": "Schleswig-Holstein",
        "country": "DE"
    },
    {
        "id": 120,
        "stateprovcode": "TH",
        "stateprov": "Thuringia",
        "country": "DE"
    },
    {
        "id": 121,
        "stateprovcode": "07",
        "stateprov": "Bjelovarsko-bilogorska",
        "country": "HR"
    },
    {
        "id": 122,
        "stateprovcode": "12",
        "stateprov": "Brodsko-posavska",
        "country": "HR"
    },
    {
        "id": 123,
        "stateprovcode": "19",
        "stateprov": "Dubrovačko-neretvanska",
        "country": "HR"
    },
    {
        "id": 124,
        "stateprovcode": "18",
        "stateprov": "Istarska",
        "country": "HR"
    },
    {
        "id": 125,
        "stateprovcode": "04",
        "stateprov": "Karlovačka",
        "country": "HR"
    },
    {
        "id": 126,
        "stateprovcode": "06",
        "stateprov": "Koprivničko-križevačka",
        "country": "HR"
    },
    {
        "id": 127,
        "stateprovcode": "02",
        "stateprov": "Krapinsko-zagorska",
        "country": "HR"
    },
    {
        "id": 128,
        "stateprovcode": "09",
        "stateprov": "Ličko-senjska",
        "country": "HR"
    },
    {
        "id": 129,
        "stateprovcode": "20",
        "stateprov": "Međimurska",
        "country": "HR"
    },
    {
        "id": 130,
        "stateprovcode": "14",
        "stateprov": "Osječko-baranjska",
        "country": "HR"
    },
    {
        "id": 131,
        "stateprovcode": "11",
        "stateprov": "Požeško-slavonska",
        "country": "HR"
    },
    {
        "id": 132,
        "stateprovcode": "08",
        "stateprov": "Primorsko-goranska",
        "country": "HR"
    },
    {
        "id": 133,
        "stateprovcode": "15",
        "stateprov": "Šibensko-kninska",
        "country": "HR"
    },
    {
        "id": 134,
        "stateprovcode": "03",
        "stateprov": "Sisačko-moslavačka",
        "country": "HR"
    },
    {
        "id": 135,
        "stateprovcode": "17",
        "stateprov": "Splitsko-dalmatinska",
        "country": "HR"
    },
    {
        "id": 136,
        "stateprovcode": "05",
        "stateprov": "Varaždinska",
        "country": "HR"
    },
    {
        "id": 137,
        "stateprovcode": "10",
        "stateprov": "Virovitičko-podravska",
        "country": "HR"
    },
    {
        "id": 138,
        "stateprovcode": "16",
        "stateprov": "Vukovarsko-srijemska",
        "country": "HR"
    },
    {
        "id": 139,
        "stateprovcode": "13",
        "stateprov": "Zadarska",
        "country": "HR"
    },
    {
        "id": 140,
        "stateprovcode": "01",
        "stateprov": "Zagrebačka",
        "country": "HR"
    },
    {
        "id": 141,
        "stateprovcode": "01",
        "stateprov": "Zagrebačka",
        "country": "HR"
    },
    {
        "id": 142,
        "stateprovcode": "01",
        "stateprov": "Zagrebačka",
        "country": "HR"
    },
    {
        "id": 143,
        "stateprovcode": "21",
        "stateprov": "Grad Zagreb",
        "country": "HR"
    },
    {
        "id": 144,
        "stateprovcode": "ST",
        "stateprov": "Středočeský kraj",
        "country": "CZ"
    },
    {
        "id": 145,
        "stateprovcode": "KR",
        "stateprov": "Královéhradecký kraj",
        "country": "CZ"
    },
    {
        "id": 146,
        "stateprovcode": "KA",
        "stateprov": "Karlovarský kraj",
        "country": "CZ"
    },
    {
        "id": 147,
        "stateprovcode": "LI",
        "stateprov": "Liberecký kraj",
        "country": "CZ"
    },
    {
        "id": 148,
        "stateprovcode": "MO",
        "stateprov": "Moravskoslezský kraj",
        "country": "CZ"
    },
    {
        "id": 149,
        "stateprovcode": "OL",
        "stateprov": "Olomoucký kraj",
        "country": "CZ"
    },
    {
        "id": 150,
        "stateprovcode": "PA",
        "stateprov": "Pardubický kraj",
        "country": "CZ"
    },
    {
        "id": 151,
        "stateprovcode": "PL",
        "stateprov": "Plzeňský kraj",
        "country": "CZ"
    },
    {
        "id": 152,
        "stateprovcode": "PR",
        "stateprov": "Hlavní město Praha",
        "country": "CZ"
    },
    {
        "id": 153,
        "stateprovcode": "JC",
        "stateprov": "Jihočeský kraj",
        "country": "CZ"
    },
    {
        "id": 154,
        "stateprovcode": "JM",
        "stateprov": "Jihomoravský kraj",
        "country": "CZ"
    },
    {
        "id": 155,
        "stateprovcode": "US",
        "stateprov": "Ústecký kraj",
        "country": "CZ"
    },
    {
        "id": 156,
        "stateprovcode": "VY",
        "stateprov": "Kraj Vysočina",
        "country": "CZ"
    },
    {
        "id": 157,
        "stateprovcode": "ZL",
        "stateprov": "Zlínský kraj",
        "country": "CZ"
    },
    {
        "id": 158,
        "stateprovcode": "84",
        "stateprov": "Hovedstaden",
        "country": "DK"
    },
    {
        "id": 159,
        "stateprovcode": "82",
        "stateprov": "Midtjylland",
        "country": "DK"
    },
    {
        "id": 160,
        "stateprovcode": "81",
        "stateprov": "Nordjylland",
        "country": "DK"
    },
    {
        "id": 161,
        "stateprovcode": "83",
        "stateprov": "Syddanmark",
        "country": "DK"
    },
    {
        "id": 163,
        "stateprovcode": "85",
        "stateprov": "Sjælland",
        "country": "DK"
    },
    {
        "id": 164,
        "stateprovcode": "37",
        "stateprov": "Harjumaa",
        "country": "EE"
    },
    {
        "id": 165,
        "stateprovcode": "39",
        "stateprov": "Hiiumaa",
        "country": "EE"
    },
    {
        "id": 166,
        "stateprovcode": "44",
        "stateprov": "Ida-Virumaa",
        "country": "EE"
    },
    {
        "id": 167,
        "stateprovcode": "51",
        "stateprov": "Järvamaa",
        "country": "EE"
    },
    {
        "id": 168,
        "stateprovcode": "49",
        "stateprov": "Jõgevamaa",
        "country": "EE"
    },
    {
        "id": 169,
        "stateprovcode": "57",
        "stateprov": "Läänemaa",
        "country": "EE"
    },
    {
        "id": 170,
        "stateprovcode": "59",
        "stateprov": "Lääne-Virumaa",
        "country": "EE"
    },
    {
        "id": 171,
        "stateprovcode": "67",
        "stateprov": "Pärnumaa",
        "country": "EE"
    },
    {
        "id": 172,
        "stateprovcode": "65",
        "stateprov": "Põlvamaa",
        "country": "EE"
    },
    {
        "id": 173,
        "stateprovcode": "70",
        "stateprov": "Raplamaa",
        "country": "EE"
    },
    {
        "id": 174,
        "stateprovcode": "74",
        "stateprov": "Saaremaa",
        "country": "EE"
    },
    {
        "id": 175,
        "stateprovcode": "78",
        "stateprov": "Tartumaa",
        "country": "EE"
    },
    {
        "id": 176,
        "stateprovcode": "82",
        "stateprov": "Valgamaa",
        "country": "EE"
    },
    {
        "id": 177,
        "stateprovcode": "84",
        "stateprov": "Viljandimaa",
        "country": "EE"
    },
    {
        "id": 178,
        "stateprovcode": "86",
        "stateprov": "Võrumaa",
        "country": "EE"
    },
    {
        "id": 179,
        "stateprovcode": "CW",
        "stateprov": "Carlow",
        "country": "IE"
    },
    {
        "id": 180,
        "stateprovcode": "CN",
        "stateprov": "Cavan",
        "country": "IE"
    },
    {
        "id": 181,
        "stateprovcode": "CE",
        "stateprov": "Clare",
        "country": "IE"
    },
    {
        "id": 182,
        "stateprovcode": "CO",
        "stateprov": "Cork",
        "country": "IE"
    },
    {
        "id": 183,
        "stateprovcode": "DL",
        "stateprov": "Donegal",
        "country": "IE"
    },
    {
        "id": 184,
        "stateprovcode": "D",
        "stateprov": "Dublin",
        "country": "IE"
    },
    {
        "id": 185,
        "stateprovcode": "G",
        "stateprov": "Galway",
        "country": "IE"
    },
    {
        "id": 186,
        "stateprovcode": "KY",
        "stateprov": "Kerry",
        "country": "IE"
    },
    {
        "id": 187,
        "stateprovcode": "KE",
        "stateprov": "Kildare",
        "country": "IE"
    },
    {
        "id": 188,
        "stateprovcode": "KK",
        "stateprov": "Kilkenny",
        "country": "IE"
    },
    {
        "id": 189,
        "stateprovcode": "LS",
        "stateprov": "Laois",
        "country": "IE"
    },
    {
        "id": 190,
        "stateprovcode": "LM",
        "stateprov": "Leitrim",
        "country": "IE"
    },
    {
        "id": 191,
        "stateprovcode": "LLK",
        "stateprov": "Limerick",
        "country": "IE"
    },
    {
        "id": 192,
        "stateprovcode": "LD",
        "stateprov": "Longford",
        "country": "IE"
    },
    {
        "id": 193,
        "stateprovcode": "LH",
        "stateprov": "Louth",
        "country": "IE"
    },
    {
        "id": 194,
        "stateprovcode": "MO",
        "stateprov": "Mayo",
        "country": "IE"
    },
    {
        "id": 195,
        "stateprovcode": "MH",
        "stateprov": "Meath",
        "country": "IE"
    },
    {
        "id": 196,
        "stateprovcode": "MN",
        "stateprov": "Monaghan",
        "country": "IE"
    },
    {
        "id": 197,
        "stateprovcode": "OY",
        "stateprov": "Offaly",
        "country": "IE"
    },
    {
        "id": 198,
        "stateprovcode": "RN",
        "stateprov": "Roscommon",
        "country": "IE"
    },
    {
        "id": 199,
        "stateprovcode": "SO",
        "stateprov": "Sligo",
        "country": "IE"
    },
    {
        "id": 200,
        "stateprovcode": "TA",
        "stateprov": "Tipperary",
        "country": "IE"
    },
    {
        "id": 201,
        "stateprovcode": "WD",
        "stateprov": "Waterford",
        "country": "IE"
    },
    {
        "id": 202,
        "stateprovcode": "WH",
        "stateprov": "Westmeath",
        "country": "IE"
    },
    {
        "id": 203,
        "stateprovcode": "WX",
        "stateprov": "Wexford",
        "country": "IE"
    },
    {
        "id": 204,
        "stateprovcode": "WW",
        "stateprov": "Wicklow",
        "country": "IE"
    },
    {
        "id": 205,
        "stateprovcode": "DR",
        "stateprov": "Drenthe",
        "country": "NL"
    },
    {
        "id": 206,
        "stateprovcode": "FL",
        "stateprov": "Flevoland",
        "country": "NL"
    },
    {
        "id": 207,
        "stateprovcode": "FR",
        "stateprov": "Fryslân",
        "country": "NL"
    },
    {
        "id": 208,
        "stateprovcode": "GE",
        "stateprov": "Gelderland",
        "country": "NL"
    },
    {
        "id": 209,
        "stateprovcode": "GR",
        "stateprov": "Groningen",
        "country": "NL"
    },
    {
        "id": 210,
        "stateprovcode": "LI",
        "stateprov": "Limburg",
        "country": "NL"
    },
    {
        "id": 211,
        "stateprovcode": "NB",
        "stateprov": "Noord-Brabant",
        "country": "NL"
    },
    {
        "id": 212,
        "stateprovcode": "NH",
        "stateprov": "Noord-Holland",
        "country": "NL"
    },
    {
        "id": 213,
        "stateprovcode": "OV",
        "stateprov": "Overissel",
        "country": "NL"
    },
    {
        "id": 214,
        "stateprovcode": "ZH",
        "stateprov": "Zuid-Holland",
        "country": "NL"
    },
    {
        "id": 215,
        "stateprovcode": "UT",
        "stateprov": "Utrecht",
        "country": "NL"
    },
    {
        "id": 216,
        "stateprovcode": "ZE",
        "stateprov": "Zeeland",
        "country": "NL"
    },
    {
        "id": 217,
        "stateprovcode": "AB",
        "stateprov": "Alba",
        "country": "RO"
    },
    {
        "id": 218,
        "stateprovcode": "AR",
        "stateprov": "Arad",
        "country": "RO"
    },
    {
        "id": 219,
        "stateprovcode": "AG",
        "stateprov": "Argeș",
        "country": "RO"
    },
    {
        "id": 220,
        "stateprovcode": "BC",
        "stateprov": "Bacău",
        "country": "RO"
    },
    {
        "id": 221,
        "stateprovcode": "BH",
        "stateprov": "Bihor",
        "country": "RO"
    },
    {
        "id": 222,
        "stateprovcode": "BN",
        "stateprov": "Bistrița-Năsăud",
        "country": "RO"
    },
    {
        "id": 223,
        "stateprovcode": "BT",
        "stateprov": "Botoșani",
        "country": "RO"
    },
    {
        "id": 224,
        "stateprovcode": "BR",
        "stateprov": "Brăila",
        "country": "RO"
    },
    {
        "id": 225,
        "stateprovcode": "BV",
        "stateprov": "Brașov",
        "country": "RO"
    },
    {
        "id": 226,
        "stateprovcode": "B",
        "stateprov": "Bucureşti",
        "country": "RO"
    },
    {
        "id": 227,
        "stateprovcode": "BZ",
        "stateprov": "Buzău",
        "country": "RO"
    },
    {
        "id": 228,
        "stateprovcode": "CL",
        "stateprov": "Călăraşi",
        "country": "RO"
    },
    {
        "id": 229,
        "stateprovcode": "CS",
        "stateprov": "Caraş-Severin",
        "country": "RO"
    },
    {
        "id": 230,
        "stateprovcode": "CJ",
        "stateprov": "Cluj",
        "country": "RO"
    },
    {
        "id": 231,
        "stateprovcode": "CT",
        "stateprov": "Constanţa",
        "country": "RO"
    },
    {
        "id": 232,
        "stateprovcode": "CV",
        "stateprov": "Covasna",
        "country": "RO"
    },
    {
        "id": 233,
        "stateprovcode": "DB",
        "stateprov": "Dâmboviţa",
        "country": "RO"
    },
    {
        "id": 234,
        "stateprovcode": "DJ",
        "stateprov": "Dolj",
        "country": "RO"
    },
    {
        "id": 235,
        "stateprovcode": "GL",
        "stateprov": "Galaţi",
        "country": "RO"
    },
    {
        "id": 236,
        "stateprovcode": "GR",
        "stateprov": "Giurgiu",
        "country": "RO"
    },
    {
        "id": 237,
        "stateprovcode": "GJ",
        "stateprov": "Gorj",
        "country": "RO"
    },
    {
        "id": 238,
        "stateprovcode": "HR",
        "stateprov": "Harghita",
        "country": "RO"
    },
    {
        "id": 239,
        "stateprovcode": "HD",
        "stateprov": "Hunedoara",
        "country": "RO"
    },
    {
        "id": 240,
        "stateprovcode": "IS",
        "stateprov": "Iaşi",
        "country": "RO"
    },
    {
        "id": 241,
        "stateprovcode": "IL",
        "stateprov": "Ialomiţa",
        "country": "RO"
    },
    {
        "id": 242,
        "stateprovcode": "IF",
        "stateprov": "Ilfov",
        "country": "RO"
    },
    {
        "id": 243,
        "stateprovcode": "MM",
        "stateprov": "Maramureş",
        "country": "RO"
    },
    {
        "id": 244,
        "stateprovcode": "MH",
        "stateprov": "Mehedinți",
        "country": "RO"
    },
    {
        "id": 245,
        "stateprovcode": "MS",
        "stateprov": "Mureș",
        "country": "RO"
    },
    {
        "id": 246,
        "stateprovcode": "NT",
        "stateprov": "Neamț",
        "country": "RO"
    },
    {
        "id": 247,
        "stateprovcode": "OT",
        "stateprov": "Olt",
        "country": "RO"
    },
    {
        "id": 248,
        "stateprovcode": "PH",
        "stateprov": "Prahova",
        "country": "RO"
    },
    {
        "id": 249,
        "stateprovcode": "SJ",
        "stateprov": "Sălaj",
        "country": "RO"
    },
    {
        "id": 250,
        "stateprovcode": "SM",
        "stateprov": "Satu Mare",
        "country": "RO"
    },
    {
        "id": 251,
        "stateprovcode": "SB",
        "stateprov": "Sibiu",
        "country": "RO"
    },
    {
        "id": 252,
        "stateprovcode": "SV",
        "stateprov": "Suceava",
        "country": "RO"
    },
    {
        "id": 253,
        "stateprovcode": "TR",
        "stateprov": "Teleorman",
        "country": "RO"
    },
    {
        "id": 254,
        "stateprovcode": "TM",
        "stateprov": "Timiș",
        "country": "RO"
    },
    {
        "id": 255,
        "stateprovcode": "VL",
        "stateprov": "Vâlcea",
        "country": "RO"
    },
    {
        "id": 256,
        "stateprovcode": "VS",
        "stateprov": "Vaslui",
        "country": "RO"
    },
    {
        "id": 257,
        "stateprovcode": "VN",
        "stateprov": "Vrancea",
        "country": "RO"
    },
    {
        "id": 258,
        "stateprovcode": "AG",
        "stateprov": "Agrigento",
        "country": "IT"
    },
    {
        "id": 259,
        "stateprovcode": "AL",
        "stateprov": "Alessandria",
        "country": "IT"
    },
    {
        "id": 260,
        "stateprovcode": "AN",
        "stateprov": "Ancona",
        "country": "IT"
    },
    {
        "id": 261,
        "stateprovcode": "AO",
        "stateprov": "Valle d'Aosta",
        "country": "IT"
    },
    {
        "id": 262,
        "stateprovcode": "AR",
        "stateprov": "Arezzo",
        "country": "IT"
    },
    {
        "id": 263,
        "stateprovcode": "AP",
        "stateprov": "Ascoli Piceno",
        "country": "IT"
    },
    {
        "id": 264,
        "stateprovcode": "AT",
        "stateprov": "Asti",
        "country": "IT"
    },
    {
        "id": 265,
        "stateprovcode": "AV",
        "stateprov": "Avellino",
        "country": "IT"
    },
    {
        "id": 266,
        "stateprovcode": "BA",
        "stateprov": "Bari",
        "country": "IT"
    },
    {
        "id": 267,
        "stateprovcode": "BT",
        "stateprov": "Barletta-Andria-Trani",
        "country": "IT"
    },
    {
        "id": 268,
        "stateprovcode": "BL",
        "stateprov": "Belluno",
        "country": "IT"
    },
    {
        "id": 269,
        "stateprovcode": "BN",
        "stateprov": "Benevento",
        "country": "IT"
    },
    {
        "id": 270,
        "stateprovcode": "BG",
        "stateprov": "Bergamo",
        "country": "IT"
    },
    {
        "id": 271,
        "stateprovcode": "BO",
        "stateprov": "Bologna",
        "country": "IT"
    },
    {
        "id": 272,
        "stateprovcode": "BI",
        "stateprov": "Biella",
        "country": "IT"
    },
    {
        "id": 273,
        "stateprovcode": "BZ",
        "stateprov": "Bolzano",
        "country": "IT"
    },
    {
        "id": 274,
        "stateprovcode": "BS",
        "stateprov": "Brescia",
        "country": "IT"
    },
    {
        "id": 275,
        "stateprovcode": "BR",
        "stateprov": "Brindisi",
        "country": "IT"
    },
    {
        "id": 276,
        "stateprovcode": "CA",
        "stateprov": "Cagliari",
        "country": "IT"
    },
    {
        "id": 277,
        "stateprovcode": "CL",
        "stateprov": "Caltanissetta",
        "country": "IT"
    },
    {
        "id": 278,
        "stateprovcode": "CB",
        "stateprov": "Campobasso",
        "country": "IT"
    },
    {
        "id": 279,
        "stateprovcode": "CI",
        "stateprov": "Carbonia-Iglesias",
        "country": "IT"
    },
    {
        "id": 280,
        "stateprovcode": "CE",
        "stateprov": "Caserta",
        "country": "IT"
    },
    {
        "id": 281,
        "stateprovcode": "CT",
        "stateprov": "Catania",
        "country": "IT"
    },
    {
        "id": 282,
        "stateprovcode": "CZ",
        "stateprov": "Catanzaro",
        "country": "IT"
    },
    {
        "id": 283,
        "stateprovcode": "CH",
        "stateprov": "Chieti",
        "country": "IT"
    },
    {
        "id": 284,
        "stateprovcode": "CO",
        "stateprov": "Como",
        "country": "IT"
    },
    {
        "id": 285,
        "stateprovcode": "CS",
        "stateprov": "Cosenza",
        "country": "IT"
    },
    {
        "id": 286,
        "stateprovcode": "CR",
        "stateprov": "Cremona",
        "country": "IT"
    },
    {
        "id": 287,
        "stateprovcode": "KR",
        "stateprov": "Crotone",
        "country": "IT"
    },
    {
        "id": 288,
        "stateprovcode": "CN",
        "stateprov": "Cuneo",
        "country": "IT"
    },
    {
        "id": 289,
        "stateprovcode": "EN",
        "stateprov": "Enna",
        "country": "IT"
    },
    {
        "id": 290,
        "stateprovcode": "FM",
        "stateprov": "Fermo",
        "country": "IT"
    },
    {
        "id": 291,
        "stateprovcode": "FE",
        "stateprov": "Ferrara",
        "country": "IT"
    },
    {
        "id": 292,
        "stateprovcode": "FI",
        "stateprov": "Firenze",
        "country": "IT"
    },
    {
        "id": 293,
        "stateprovcode": "FG",
        "stateprov": "Foggia",
        "country": "IT"
    },
    {
        "id": 294,
        "stateprovcode": "FC",
        "stateprov": "Forlì-Cesena",
        "country": "IT"
    },
    {
        "id": 295,
        "stateprovcode": "FR",
        "stateprov": "Frosinone",
        "country": "IT"
    },
    {
        "id": 296,
        "stateprovcode": "GE",
        "stateprov": "Genova",
        "country": "IT"
    },
    {
        "id": 297,
        "stateprovcode": "GO",
        "stateprov": "Gorizia",
        "country": "IT"
    },
    {
        "id": 298,
        "stateprovcode": "GR",
        "stateprov": "Grosseto",
        "country": "IT"
    },
    {
        "id": 299,
        "stateprovcode": "IM",
        "stateprov": "Imperia",
        "country": "IT"
    },
    {
        "id": 300,
        "stateprovcode": "IS",
        "stateprov": "Isernia",
        "country": "IT"
    },
    {
        "id": 301,
        "stateprovcode": "SP",
        "stateprov": "La Spezia",
        "country": "IT"
    },
    {
        "id": 302,
        "stateprovcode": "AQ",
        "stateprov": "L'Aquila",
        "country": "IT"
    },
    {
        "id": 303,
        "stateprovcode": "LT",
        "stateprov": "Latina",
        "country": "IT"
    },
    {
        "id": 304,
        "stateprovcode": "LE",
        "stateprov": "Lecce",
        "country": "IT"
    },
    {
        "id": 305,
        "stateprovcode": "LC",
        "stateprov": "Lecco",
        "country": "IT"
    },
    {
        "id": 306,
        "stateprovcode": "LI",
        "stateprov": "Livorno",
        "country": "IT"
    },
    {
        "id": 307,
        "stateprovcode": "LO",
        "stateprov": "Lodi",
        "country": "IT"
    },
    {
        "id": 308,
        "stateprovcode": "LU",
        "stateprov": "Lucca",
        "country": "IT"
    },
    {
        "id": 309,
        "stateprovcode": "MC",
        "stateprov": "Macerata",
        "country": "IT"
    },
    {
        "id": 310,
        "stateprovcode": "MN",
        "stateprov": "Mantova",
        "country": "IT"
    },
    {
        "id": 311,
        "stateprovcode": "MS",
        "stateprov": "Massa-Carrara",
        "country": "IT"
    },
    {
        "id": 312,
        "stateprovcode": "MT",
        "stateprov": "Matera",
        "country": "IT"
    },
    {
        "id": 313,
        "stateprovcode": "VS",
        "stateprov": "Medio Campidano",
        "country": "IT"
    },
    {
        "id": 314,
        "stateprovcode": "ME",
        "stateprov": "Messina",
        "country": "IT"
    },
    {
        "id": 315,
        "stateprovcode": "MI",
        "stateprov": "Milano",
        "country": "IT"
    },
    {
        "id": 316,
        "stateprovcode": "MO",
        "stateprov": "Modena",
        "country": "IT"
    },
    {
        "id": 317,
        "stateprovcode": "MB",
        "stateprov": "Monza e della Brianza",
        "country": "IT"
    },
    {
        "id": 318,
        "stateprovcode": "NA",
        "stateprov": "Napoli",
        "country": "IT"
    },
    {
        "id": 319,
        "stateprovcode": "NO",
        "stateprov": "Novara",
        "country": "IT"
    },
    {
        "id": 320,
        "stateprovcode": "NU",
        "stateprov": "Nuoro",
        "country": "IT"
    },
    {
        "id": 321,
        "stateprovcode": "OG",
        "stateprov": "Ogliastra",
        "country": "IT"
    },
    {
        "id": 322,
        "stateprovcode": "OT",
        "stateprov": "Olbia-Tempio",
        "country": "IT"
    },
    {
        "id": 323,
        "stateprovcode": "OR",
        "stateprov": "Oristano",
        "country": "IT"
    },
    {
        "id": 324,
        "stateprovcode": "PD",
        "stateprov": "Padova",
        "country": "IT"
    },
    {
        "id": 325,
        "stateprovcode": "PA",
        "stateprov": "Palermo",
        "country": "IT"
    },
    {
        "id": 326,
        "stateprovcode": "PR",
        "stateprov": "Parma",
        "country": "IT"
    },
    {
        "id": 327,
        "stateprovcode": "PV",
        "stateprov": "Pavia",
        "country": "IT"
    },
    {
        "id": 328,
        "stateprovcode": "PG",
        "stateprov": "Perugia",
        "country": "IT"
    },
    {
        "id": 329,
        "stateprovcode": "PU",
        "stateprov": "Pesaro e Urbino",
        "country": "IT"
    },
    {
        "id": 330,
        "stateprovcode": "PE",
        "stateprov": "Pescara",
        "country": "IT"
    },
    {
        "id": 331,
        "stateprovcode": "PC",
        "stateprov": "Piacenza",
        "country": "IT"
    },
    {
        "id": 332,
        "stateprovcode": "PI",
        "stateprov": "Pisa",
        "country": "IT"
    },
    {
        "id": 333,
        "stateprovcode": "PT",
        "stateprov": "Pistoia",
        "country": "IT"
    },
    {
        "id": 334,
        "stateprovcode": "PN",
        "stateprov": "Pordenone",
        "country": "IT"
    },
    {
        "id": 335,
        "stateprovcode": "PZ",
        "stateprov": "Potenza",
        "country": "IT"
    },
    {
        "id": 336,
        "stateprovcode": "PO",
        "stateprov": "Prato",
        "country": "IT"
    },
    {
        "id": 337,
        "stateprovcode": "RG",
        "stateprov": "Ragusa",
        "country": "IT"
    },
    {
        "id": 338,
        "stateprovcode": "RA",
        "stateprov": "Ravenna",
        "country": "IT"
    },
    {
        "id": 339,
        "stateprovcode": "RC",
        "stateprov": "Reggio di Calabria",
        "country": "IT"
    },
    {
        "id": 340,
        "stateprovcode": "RE",
        "stateprov": "Reggio Nell'Emilia",
        "country": "IT"
    },
    {
        "id": 341,
        "stateprovcode": "RI",
        "stateprov": "Rieti",
        "country": "IT"
    },
    {
        "id": 342,
        "stateprovcode": "RN",
        "stateprov": "Rimini",
        "country": "IT"
    },
    {
        "id": 343,
        "stateprovcode": "RM",
        "stateprov": "Roma",
        "country": "IT"
    },
    {
        "id": 344,
        "stateprovcode": "SA",
        "stateprov": "Salerno",
        "country": "IT"
    },
    {
        "id": 345,
        "stateprovcode": "SS",
        "stateprov": "Sassari",
        "country": "IT"
    },
    {
        "id": 346,
        "stateprovcode": "SV",
        "stateprov": "Savona",
        "country": "IT"
    },
    {
        "id": 347,
        "stateprovcode": "SI",
        "stateprov": "Siena",
        "country": "IT"
    },
    {
        "id": 348,
        "stateprovcode": "SO",
        "stateprov": "Sondrio",
        "country": "IT"
    },
    {
        "id": 349,
        "stateprovcode": "SR",
        "stateprov": "Siracusa",
        "country": "IT"
    },
    {
        "id": 350,
        "stateprovcode": "TA",
        "stateprov": "Taranto",
        "country": "IT"
    },
    {
        "id": 351,
        "stateprovcode": "TE",
        "stateprov": "Teramo",
        "country": "IT"
    },
    {
        "id": 352,
        "stateprovcode": "TR",
        "stateprov": "Terni",
        "country": "IT"
    },
    {
        "id": 353,
        "stateprovcode": "TP",
        "stateprov": "Trapani",
        "country": "IT"
    },
    {
        "id": 354,
        "stateprovcode": "TN",
        "stateprov": "Trento",
        "country": "IT"
    },
    {
        "id": 355,
        "stateprovcode": "TV",
        "stateprov": "Treviso",
        "country": "IT"
    },
    {
        "id": 356,
        "stateprovcode": "TS",
        "stateprov": "Trieste",
        "country": "IT"
    },
    {
        "id": 357,
        "stateprovcode": "TO",
        "stateprov": "Torino",
        "country": "IT"
    },
    {
        "id": 358,
        "stateprovcode": "UD",
        "stateprov": "Udine",
        "country": "IT"
    },
    {
        "id": 359,
        "stateprovcode": "VA",
        "stateprov": "Varese",
        "country": "IT"
    },
    {
        "id": 360,
        "stateprovcode": "VE",
        "stateprov": "Venezia",
        "country": "IT"
    },
    {
        "id": 361,
        "stateprovcode": "VB",
        "stateprov": "Verbano-Cusio-Ossola",
        "country": "IT"
    },
    {
        "id": 362,
        "stateprovcode": "VC",
        "stateprov": "Vercelli",
        "country": "IT"
    },
    {
        "id": 363,
        "stateprovcode": "VR",
        "stateprov": "Verona",
        "country": "IT"
    },
    {
        "id": 364,
        "stateprovcode": "VV",
        "stateprov": "Vibo VVibo Valentiaalentia",
        "country": "IT"
    },
    {
        "id": 365,
        "stateprovcode": "VI",
        "stateprov": "Vicenza",
        "country": "IT"
    },
    {
        "id": 366,
        "stateprovcode": "VT",
        "stateprov": "Viterbo",
        "country": "IT"
    },
    {
        "id": 367,
        "stateprovcode": "AC",
        "stateprov": "Acre",
        "country": "BR"
    },
    {
        "id": 368,
        "stateprovcode": "AL",
        "stateprov": "Alagoas",
        "country": "BR"
    },
    {
        "id": 369,
        "stateprovcode": "AP",
        "stateprov": "Amapá",
        "country": "BR"
    },
    {
        "id": 370,
        "stateprovcode": "AM",
        "stateprov": "Amazonas",
        "country": "BR"
    },
    {
        "id": 371,
        "stateprovcode": "BA",
        "stateprov": "Bahía",
        "country": "BR"
    },
    {
        "id": 372,
        "stateprovcode": "CE",
        "stateprov": "Ceará",
        "country": "BR"
    },
    {
        "id": 373,
        "stateprovcode": "DF",
        "stateprov": "Distrito Federal",
        "country": "BR"
    },
    {
        "id": 374,
        "stateprovcode": "ES",
        "stateprov": "Espirito Santo",
        "country": "BR"
    },
    {
        "id": 375,
        "stateprovcode": "GO",
        "stateprov": "Goiás",
        "country": "BR"
    },
    {
        "id": 376,
        "stateprovcode": "MA",
        "stateprov": "Maranhão",
        "country": "BR"
    },
    {
        "id": 377,
        "stateprovcode": "MT",
        "stateprov": "Mato Grosso",
        "country": "BR"
    },
    {
        "id": 378,
        "stateprovcode": "MS",
        "stateprov": "Mato Grosso do Sul",
        "country": "BR"
    },
    {
        "id": 379,
        "stateprovcode": "MG",
        "stateprov": "Minas Gerais",
        "country": "BR"
    },
    {
        "id": 380,
        "stateprovcode": "PA",
        "stateprov": "Pará",
        "country": "BR"
    },
    {
        "id": 381,
        "stateprovcode": "PB",
        "stateprov": "Paraíba",
        "country": "BR"
    },
    {
        "id": 382,
        "stateprovcode": "PR",
        "stateprov": "Paraná",
        "country": "BR"
    },
    {
        "id": 383,
        "stateprovcode": "PE",
        "stateprov": "Pernambuco",
        "country": "BR"
    },
    {
        "id": 384,
        "stateprovcode": "PI",
        "stateprov": "Piauí",
        "country": "BR"
    },
    {
        "id": 385,
        "stateprovcode": "RJ",
        "stateprov": "Rio de Janeiro",
        "country": "BR"
    },
    {
        "id": 386,
        "stateprovcode": "RN",
        "stateprov": "Rio Grande do Norte",
        "country": "BR"
    },
    {
        "id": 387,
        "stateprovcode": "RS",
        "stateprov": "Rio Grande do Sul",
        "country": "BR"
    },
    {
        "id": 388,
        "stateprovcode": "RO",
        "stateprov": "Rondônia",
        "country": "BR"
    },
    {
        "id": 389,
        "stateprovcode": "RR",
        "stateprov": "Roraima",
        "country": "BR"
    },
    {
        "id": 390,
        "stateprovcode": "SC",
        "stateprov": "Santa Catarina",
        "country": "BR"
    },
    {
        "id": 391,
        "stateprovcode": "São Paulo",
        "stateprov": "SP",
        "country": "BR"
    },
    {
        "id": 392,
        "stateprovcode": "SE",
        "stateprov": "Sergipe",
        "country": "BR"
    },
    {
        "id": 393,
        "stateprovcode": "TO",
        "stateprov": "Tocantins",
        "country": "BR"
    },
    {
        "id": 394,
        "stateprovcode": "AMA",
        "stateprov": "Amazonas",
        "country": "CO"
    },
    {
        "id": 395,
        "stateprovcode": "ANT",
        "stateprov": "Antioquia",
        "country": "CO"
    },
    {
        "id": 396,
        "stateprovcode": "ARA",
        "stateprov": "Arauca",
        "country": "CO"
    },
    {
        "id": 397,
        "stateprovcode": "ATL",
        "stateprov": "Atlántico",
        "country": "CO"
    },
    {
        "id": 398,
        "stateprovcode": "BOL",
        "stateprov": "Bolívar",
        "country": "CO"
    },
    {
        "id": 399,
        "stateprovcode": "BOY",
        "stateprov": "Boyacá",
        "country": "CO"
    },
    {
        "id": 400,
        "stateprovcode": "CAL",
        "stateprov": "Caldas",
        "country": "CO"
    },
    {
        "id": 401,
        "stateprovcode": "DC",
        "stateprov": "Distrito Capital de Bogotá",
        "country": "CO"
    },
    {
        "id": 402,
        "stateprovcode": "Caquetá",
        "stateprov": "CAQ",
        "country": "CO"
    },
    {
        "id": 403,
        "stateprovcode": "CAS",
        "stateprov": "Casanare",
        "country": "CO"
    },
    {
        "id": 404,
        "stateprovcode": "CAU",
        "stateprov": "Cauca",
        "country": "CO"
    },
    {
        "id": 405,
        "stateprovcode": "CES",
        "stateprov": "Cesar",
        "country": "CO"
    },
    {
        "id": 406,
        "stateprovcode": "CHO",
        "stateprov": "Chocó",
        "country": "CO"
    },
    {
        "id": 407,
        "stateprovcode": "COR",
        "stateprov": "Córdoba",
        "country": "CO"
    },
    {
        "id": 408,
        "stateprovcode": "CUN",
        "stateprov": "Cundinamarca",
        "country": "CO"
    },
    {
        "id": 409,
        "stateprovcode": "CUN",
        "stateprov": "Cundinamarca",
        "country": "CO"
    },
    {
        "id": 410,
        "stateprovcode": "GUA",
        "stateprov": "Guainia",
        "country": "CO"
    },
    {
        "id": 411,
        "stateprovcode": "GUV",
        "stateprov": "Guaviare",
        "country": "CO"
    },
    {
        "id": 412,
        "stateprovcode": "HUI",
        "stateprov": "Huila",
        "country": "CO"
    },
    {
        "id": 413,
        "stateprovcode": "LAG",
        "stateprov": "La Guajira",
        "country": "CO"
    },
    {
        "id": 414,
        "stateprovcode": "MAG",
        "stateprov": "Magdalena",
        "country": "CO"
    },
    {
        "id": 415,
        "stateprovcode": "MET",
        "stateprov": "Meta",
        "country": "CO"
    },
    {
        "id": 416,
        "stateprovcode": "NAR",
        "stateprov": "Nariño",
        "country": "CO"
    },
    {
        "id": 417,
        "stateprovcode": "NSA",
        "stateprov": "Norte de Santander",
        "country": "CO"
    },
    {
        "id": 418,
        "stateprovcode": "PUT",
        "stateprov": "Putumayo",
        "country": "CO"
    },
    {
        "id": 419,
        "stateprovcode": "QUI",
        "stateprov": "Quindío",
        "country": "CO"
    },
    {
        "id": 420,
        "stateprovcode": "RIS",
        "stateprov": "Risaralda",
        "country": "CO"
    },
    {
        "id": 421,
        "stateprovcode": "SAP",
        "stateprov": "San Andrés, Providencia y Santa Catalina",
        "country": "CO"
    },
    {
        "id": 422,
        "stateprovcode": "SAN",
        "stateprov": "Santander",
        "country": "CO"
    },
    {
        "id": 423,
        "stateprovcode": "SUC",
        "stateprov": "Sucre",
        "country": "CO"
    },
    {
        "id": 424,
        "stateprovcode": "TOL",
        "stateprov": "Tolima",
        "country": "CO"
    },
    {
        "id": 425,
        "stateprovcode": "VAC",
        "stateprov": "Valle del Cauca",
        "country": "CO"
    },
    {
        "id": 426,
        "stateprovcode": "VAU",
        "stateprov": "Vaupés",
        "country": "CO"
    },
    {
        "id": 427,
        "stateprovcode": "VID",
        "stateprov": "Vichada",
        "country": "CO"
    },
    {
        "id": 428,
        "stateprovcode": "AV",
        "stateprov": "Alta Verapaz",
        "country": "GT"
    },
    {
        "id": 429,
        "stateprovcode": "BV",
        "stateprov": "Baja Verapaz",
        "country": "GT"
    },
    {
        "id": 430,
        "stateprovcode": "CM",
        "stateprov": "Chimaltenango",
        "country": "GT"
    },
    {
        "id": 431,
        "stateprovcode": "CQ",
        "stateprov": "Chiquimula",
        "country": "CO"
    },
    {
        "id": 432,
        "stateprovcode": "PR",
        "stateprov": "El Progreso",
        "country": "GT"
    },
    {
        "id": 433,
        "stateprovcode": "Chiquimula",
        "stateprov": "CQ",
        "country": "GT"
    },
    {
        "id": 434,
        "stateprovcode": "PR",
        "stateprov": "El Progreso",
        "country": "GT"
    },
    {
        "id": 435,
        "stateprovcode": "ES",
        "stateprov": "Escuintla",
        "country": "GT"
    },
    {
        "id": 436,
        "stateprovcode": "GU",
        "stateprov": "Guatemala",
        "country": "GT"
    },
    {
        "id": 437,
        "stateprovcode": "HU",
        "stateprov": "Huehuetenango",
        "country": "GT"
    },
    {
        "id": 438,
        "stateprovcode": "IZ",
        "stateprov": "Izabal",
        "country": "GT"
    },
    {
        "id": 439,
        "stateprovcode": "JA",
        "stateprov": "Jalapa",
        "country": "GT"
    },
    {
        "id": 440,
        "stateprovcode": "JU",
        "stateprov": "Jutiapa",
        "country": "GT"
    },
    {
        "id": 441,
        "stateprovcode": "PE",
        "stateprov": "Petén",
        "country": "GT"
    },
    {
        "id": 442,
        "stateprovcode": "QZ",
        "stateprov": "Quezaltenango",
        "country": "GT"
    },
    {
        "id": 443,
        "stateprovcode": "QC",
        "stateprov": "Quiché",
        "country": "GT"
    },
    {
        "id": 444,
        "stateprovcode": "RE",
        "stateprov": "Retalhuleu",
        "country": "GT"
    },
    {
        "id": 445,
        "stateprovcode": "SA",
        "stateprov": "Sacatepéquez",
        "country": "GT"
    },
    {
        "id": 446,
        "stateprovcode": "SM",
        "stateprov": "San Marcos",
        "country": "GT"
    },
    {
        "id": 447,
        "stateprovcode": "SR",
        "stateprov": "Santa Rosa",
        "country": "GT"
    },
    {
        "id": 448,
        "stateprovcode": "SO",
        "stateprov": "Sololá",
        "country": "GT"
    },
    {
        "id": 449,
        "stateprovcode": "SU",
        "stateprov": "Suchitepéquez",
        "country": "GT"
    },
    {
        "id": 450,
        "stateprovcode": "TO",
        "stateprov": "Totonicapán",
        "country": "GT"
    },
    {
        "id": 451,
        "stateprovcode": "ZA",
        "stateprov": "Zacapa",
        "country": "GT"
    }
];