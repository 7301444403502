import React, { useEffect, useState } from 'react'
import FormLayout from './FormLayout'
import useApp from '../store/useApp'
import { getShipmentPackages, updateShipmentPackages } from '../helpers/apiRequests'
import { FieldArray, Formik } from 'formik'
import Stage from './Contents/Stage'
import * as Yup from  'yup'
import Loading from '../components/loaders/Loading'
import { contentsAnalytics } from '../helpers/gtagEvents'

function countWords(str) {
   return str.trim().split(/\s+/).length;
}
 

const initialValues = {
   stages:[
      {
         boxes:null
      }
   ]
}

function Contents() {
   const {shipmentId,setLoading,setActiveStep} = useApp()
   const [isDataLoading,setIsDataLoading] = useState(true)

   const validationSchema = Yup.object().shape({
      stages: Yup.array().of(
         Yup.object().shape({
            boxes: Yup.array().of(
               Yup.object().shape({
                  boxOuterDescription: Yup.string().required('Required').test((value,context)=>{
                     return (countWords(value) < 2) ? false : true
                  }),
                  boxInnerDescription: Yup.string().required('Required').test((value,context)=>{
                     return (countWords(value) < 3) ? false : true
                  })
               })
            )
         })
      )
   })

   const handleFormSubmit = async function(values){
      console.log("Values",values)
      let obj = []
      values?.stages.forEach((stage)=>{
         stage?.boxes.forEach((box)=>{
            obj.push(box)
         })
      })

      setLoading(true)
      try {
         const {data} = await updateShipmentPackages(shipmentId,obj)
         console.log("Data",data)
         contentsAnalytics()
         setLoading(false)
         setActiveStep('payments')
      } catch (error) {
         console.log("Error",error)
         setLoading(false)
      }
      console.log("Boxes",obj)
   }

   async function getData(){
      setIsDataLoading(true)
      try {
         const {data} = await getShipmentPackages(shipmentId)
         console.log("Data",data)
         initialValues.stages[0].boxes = data;
         console.log("InitialValues",initialValues)
         setIsDataLoading(false)
      } catch (error) {
         console.log("Errors",error)
         setIsDataLoading(false)
      }
   }

   useEffect(()=>{
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setLoading(false)
      getData()
   },[])

  return (
   <>
   {!isDataLoading ? (
    <FormLayout>
      <Formik
         initialValues={initialValues}
         validationSchema={validationSchema}
         onSubmit={handleFormSubmit}
      >{({values,errors,touched,setFieldValue,handleSubmit})=>{
         console.log("Values",values,errors)
         return (
        <form onSubmit={handleSubmit} autocomplete="on" className="left-column basis-7/12 px-1 md:px-4 mb-3">
         <FieldArray
         name='stages'
         render={(arrayHelpers)=>(
            <>
               {values.stages.length && values.stages.map((stage,index)=>{
                  return <Stage key={index} errors={errors} touched={touched} index={index} stage={stage} handleFieldChange={setFieldValue}/>
               })}
            </>
         )}>
         </FieldArray>
         <div className="font-semibold text-tiny my-3">By continuing, you agree to our <span className="pointer hover:underline text-brand-500"><a href='/policies/shipping-policy'>Shipping Policy</a></span> and <span className="pointer hover:underline text-brand-500"><a href='/policies/terms-of-service'>Terms of Service</a></span>.</div>
         <button aria-label="Next step" type="submit" className="button btn-reverse button--full-width custom-btn">
         Continue
         </button>
         </form>)}}
      </Formik>
    </FormLayout>
   ) : (
      <Loading/>
   )}
    </>
  )
}

export default Contents