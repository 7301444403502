import React, { useEffect } from 'react'
import Index from './bannerForm/Index'
import GoogleAutoComplete from './helpers/GoogleMapsClassService';

function GetQuote() {
  useEffect(()=>{
    console.log("Loading...")
    const AutoComplete = new GoogleAutoComplete();
    AutoComplete.initMapAPI();
  },[])
  return (
    <Index/>
  )
}

export default GetQuote