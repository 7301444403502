import React, { useEffect, useState } from 'react'
import { formatObjectWithUnits } from '../helpers/generals'
import CheckBox from './icons/CheckBox'
import useApp from '../store/useApp'

function Boxes({box,index,setFieldValue,errors,touched}) {
    const {units,protections,categories,presets} = useApp()

    const [openMesurements, setOpenMesurements] = useState(false)
    const [openContents, setOpenContents] = useState(false)
    const [openProtection, setOpenProtection] = useState(false)

    const handleMeasurementsChange = function(item){
        const newObj = formatObjectWithUnits(units,item)
        setFieldValue(`boxes[${index}].preset`, item.name)
        setFieldValue(`boxes[${index}].length`, newObj.length)
        setFieldValue(`boxes[${index}].width`, newObj.width)
        setFieldValue(`boxes[${index}].height`, newObj.height)
        setFieldValue(`boxes[${index}].weight`, newObj.weight)
        setFieldValue(`boxes[${index}].linearUnit`, newObj.linearUnit)
        setFieldValue(`boxes[${index}].weightUnit`, newObj.weightUnit)
    }

    const handleMarketValueChange = function(value){
        setFieldValue(`boxes[${index}].marketValue`,value)
    }

    const handleProtectionChange = function(item){
        setOpenProtection(false);
        setFieldValue(`boxes[${index}].premiumProtection`,item)
    }

    const handleContentChange = function(item){
        setOpenContents(false);
        console.log("Item",item)
        setFieldValue(`boxes[${index}].contents`,item)
    }

    const handlePresetFieldChange = function(type,value){
        setFieldValue(`boxes[${index}][${type}]`,value)
        setFieldValue(`boxes[${index}].preset`,'custom')
    }

    useEffect(()=>{
        if(box){
            const newObj = formatObjectWithUnits(units,box)
            setFieldValue(`boxes[${index}]`,{...box,...newObj})
        }
    },[units])

  return (
    <div>
    <p className='m-0 mb-4 font-bold text-black'>SHIPMENT DETAILS</p>
    <div className="mt-2 mb-2 uppercase">
        PACKAGE DIMENSIONS #{index+1} <span>{box?.preset ? '- ' + box?.preset : ''}</span>
    </div>
    <div className={(errors?.boxes?.length && touched.boxes && (errors.boxes[index].width || errors.boxes[index].length || errors.boxes[index].height || errors.boxes[index].weight)) ? "flex flex-col box-case-form" : "flex flex-col"}>
        {/* <div className="flex w-full flex-col items-center relative pb-2">
          <button aria-label="Package content" onClick={()=>setOpenMesurements(!openMesurements)} type="button" className={`outline-none w-full focus:outline-none appearance-none p-0 ${(errors.boxes?.length && touched.boxes && errors.boxes[index].preset && touched.boxes[index].preset) ? 'bf-form-error-col' : ''}`}>
              <div className="bg-white rounded flex border justify-between custom-style-input border-secondary-100 h-12">
                <div className="relative w-full h-12 w-[calc(100%-28px)]">
                    <input name={`boxes[${index}].preset`} placeholder='Case Model' value={box?.preset} defaultValue={box?.preset} 
                        className="w-full jb-input break-words max-h-full truncate leading-5 appearance-none p-3 outline-none sm:text-sm text-base font-medium bg-transparent cursor-pointer text-left placeholder:text-[#808080]" autoComplete='off' readOnly/>
                </div>
                <div className="px-2">
                    <div className="w-4 h-full flex items-center text-stone-600">
                        {openMesurements ? (
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-4 svg-inline--fa fa-angle-up">
                                <path fill="currentColor" d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z" className=""></path>
                            </svg>
                        ):(
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-4 svg-inline--fa fa-angle-down">
                                <path fill="currentColor" d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" className=""></path>
                            </svg>
                        )}
                    </div>
                </div>
              </div>
          </button>
          <div className='custom-overlay' style={{display: openMesurements ? "block" : "none"}} onClick={()=>setOpenMesurements(!openMesurements)}></div>
          <div className="absolute shadow top-full z-40 w-full lef-0 rounded overflow-y-auto max-h-52" style={{display: openMesurements ? "block":"none"}}>
              <div className="flex flex-col text-left w-full">
                {presets.sort((a,b)=>a.sortOrder - b.sortOrder).map((item,index)=>{
                    return <div key={index} onClick={()=>{setOpenMesurements(false);handleMeasurementsChange(item)}} className="cursor-pointer w-full bg-white">
                        <div className="flex w-full items-center p-2 pl-2 border-transparent hover:bg-brand-200 border-l-2 relative">
                        <div className="w-full items-center flex">
                            <div className="mx-2 leading-6 text-xs font-semibold">{item?.name}</div>
                        </div>
                        </div>
                    </div>
                })}
              </div>
          </div>
        </div> */}
        <div className="relative mb-5">
            <input
                id="case_model"
                type="text"
                name="casemodel"
                value={box?.preset}
                onFocus={()=>(setOpenMesurements(true))}
                className="text-[#00263A] border peer block w-full appearance-none cursor-pointer rounded-lg border-[#ccc] p-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:border-b-0 focus:rounded-bl-[0px] focus:rounded-br-[0px] focus:outline-none focus:ring-0"
                placeholder=' '
                readOnly
                />
            <label
                htmlFor="case_model"
                className="absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent">
                Case Model
            </label>
            <span onClick={(e)=>{setOpenMesurements(!openMesurements);
                if(!openMesurements){
                    e.currentTarget.parentNode.querySelector('input').focus();
                }
            }} className='absolute right-5 top-[50%] translate-y-[-50%] text-[#808080] peer-focus:text-[#00263A] text-[14px] leading-[20px] text-medium peer-focus:-rotate-180 transform duration-300 cursor-pointer'>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-8 svg-inline--fa fa-angle-down">
                    <path fill="currentColor" d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" className=""></path>
                </svg>
            </span>
            <div className='custom-overlay' style={{display: openMesurements ? "block" : "none"}} onClick={()=>setOpenMesurements(!openMesurements)}></div>
            <div style={{display: openMesurements ? "block":"none"}} className="absolute jb-select-dropdown transform duration-300 shadow-[0_6px_10px_rgba(0,0,0,0.2)] top-full z-40 w-full lef-0 rounded-bl-lg rounded-br-lg border border-[#00263A] border-t-0 overflow-y-auto max-h-52 min-w-133">
                <div className="flex flex-col w-full">
                    {presets.sort((a,b)=>a.sortOrder - b.sortOrder).map((item,index)=>{
                        return <div onClick={()=>{setOpenMesurements(false);handleMeasurementsChange(item)}} className="cursor-pointer w-full bg-white" key={index}>
                            <div className="flex w-full items-center p-2 pl-2 border-transparent hover:bg-brand-200 border-l-2 relative">
                                <div className="w-full items-center flex">
                                    <div className="mx-2 text-[#00263A] text-[14px] leading-[20px] text-medium">{item?.name}</div>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
        <div className="flex sm:gap-2 relative flex-wrap xl:flex-nowrap">
            <div className="relative mb-5 grow">
                <input
                    id="units_length" type="number" name={`boxes[${index}].length`} pattern="[0-9]*" min="0"
                    className={`text-[#00263A] border peer block w-full appearance-none rounded-lg border-[#ccc] p-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:outline-none focus:ring-0 ${(errors?.boxes?.length && touched.boxes && errors.boxes[index].length && touched.boxes[index].length) && 'bf-form-error-col'}`}
                    placeholder=" " value={box?.length} onChange={(e)=>handlePresetFieldChange('length',e.target.value)} data-number-field
                    />
                <label
                    htmlFor="units_length"
                    className="absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent">
                    {'Length'}
                </label>
                <span className='absolute right-5 top-[50%] translate-y-[-50%] text-[#808080] peer-focus:text-[#00263A] text-[14px] leading-[20px] text-medium'>{units.length}</span>
            </div>
            <div className="relative mb-5 grow">
                <input
                    id="units_width" type="number" name={`boxes[${index}].width`} pattern="[0-9]*" min="0"
                    className={`text-[#00263A] border peer block w-full appearance-none rounded-lg border-[#ccc] p-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:outline-none focus:ring-0 ${(errors?.boxes?.length && touched.boxes && errors.boxes[index].width && touched.boxes[index].width) && 'bf-form-error-col'}`}
                    placeholder=" " value={box?.width} onChange={(e)=>handlePresetFieldChange('width',e.target.value)} data-number-field
                    />
                <label
                    htmlFor="units_width"
                    className="absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent">
                    {'Width'}
                </label>
                <span className='absolute right-5 top-[50%] translate-y-[-50%] text-[#808080] peer-focus:text-[#00263A] text-[14px] leading-[20px] text-medium'>{units.length}</span>
            </div>
            <div className="relative mb-5 grow">
                <input
                    id="units_height" type="number" name={`boxes[${index}].height`} pattern="[0-9]*" min="0"
                    className={`text-[#00263A] border peer block w-full appearance-none rounded-lg border-[#ccc] p-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:outline-none focus:ring-0 ${(errors?.boxes?.length && touched.boxes && errors.boxes[index].height && touched.boxes[index].height) && 'bf-form-error-col'}`}
                    placeholder=" " value={box?.height} onChange={(e)=>handlePresetFieldChange('height',e.target.value)} data-number-field
                    />
                <label
                    htmlFor="units_height"
                    className="absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent">
                    {'Height'}
                </label>
                <span className='absolute right-5 top-[50%] translate-y-[-50%] text-[#808080] peer-focus:text-[#00263A] text-[14px] leading-[20px] text-medium'>{units.length}</span>
            </div>
            <div className="relative mb-5 grow">
                <input
                    id="units_weight" type="number" name={`boxes[${index}].weight`} pattern="[0-9]*" min="0"
                    className={`text-[#00263A] border peer block w-full appearance-none rounded-lg border-[#ccc] p-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:outline-none focus:ring-0 ${(errors?.boxes?.length && touched.boxes && errors.boxes[index].weight && touched.boxes[index].weight) && 'bf-form-error-col'}`}
                    placeholder=" " value={box?.weight} onChange={(e)=>handlePresetFieldChange('weight',e.target.value)} data-number-field
                    />
                <label
                    htmlFor="units_weight"
                    className="absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent">
                    {'Weight'}
                </label>
                <span className='absolute right-5 top-[50%] translate-y-[-50%] text-[#808080] peer-focus:text-[#00263A] text-[14px] leading-[20px] text-medium'>{units.weight}</span>
            </div>
        </div>        
        <div className="relative mb-5">
            <input
                id="box_contents"
                type="text"
                name="trips"
                value={box?.contents?.name}
                onFocus={()=>(setOpenContents(true))}
                className="text-[#00263A] border peer block w-full appearance-none cursor-pointer rounded-lg border-[#ccc] p-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:border-b-0 focus:rounded-bl-[0px] focus:rounded-br-[0px] focus:outline-none focus:ring-0"
                placeholder=' '
                readOnly
                />
            <label
                htmlFor="box_contents"
                className="absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent">
                Box Contents
            </label>
            <span onClick={(e)=>{setOpenContents(!openContents);
                if(!openContents){
                    e.currentTarget.parentNode.querySelector('input').focus();
                }
            }} className='absolute right-5 top-[50%] translate-y-[-50%] text-[#808080] peer-focus:text-[#00263A] text-[14px] leading-[20px] text-medium peer-focus:-rotate-180 transform duration-300 cursor-pointer'>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-8 svg-inline--fa fa-angle-down">
                    <path fill="currentColor" d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" className=""></path>
                </svg>
            </span>
            <div className='custom-overlay' style={{display: openContents ? "block" : "none"}} onClick={()=>setOpenContents(!openContents)}></div>
            <div style={{display: openContents ? "block":"none"}} className="absolute jb-select-dropdown transform duration-300 shadow-[0_6px_10px_rgba(0,0,0,0.2)] top-full z-40 w-full lef-0 rounded-bl-lg rounded-br-lg border border-[#00263A] border-t-0 overflow-y-auto max-h-52 min-w-133">
                <div className="flex flex-col w-full">
                    {categories.map((item,index)=>{
                        return <div onClick={()=>handleContentChange(item)} className="cursor-pointer w-full bg-white" key={index}>
                            <div className="flex w-full items-center p-2 pl-2 border-transparent hover:bg-brand-200 border-l-2 relative">
                                <div className="w-full items-center flex">
                                    <div className="mx-2 text-[#00263A] text-[14px] leading-[20px] text-medium">{item?.name}</div>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
        <div className="relative mb-5">
            <input
                id="resell_value"
                type="number"
                name={`boxes[${index}].marketValue`}
                value={box?.marketValue} onChange={(e)=>handleMarketValueChange(e.target.value)}
                className={`text-[#00263A] border peer block w-full appearance-none rounded-lg border-[#ccc] py-5 pl-12 pr-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:outline-none focus:ring-0 ${(errors?.boxes?.length && touched.boxes && errors.boxes[index].marketValue && touched.boxes[index].marketValue) && 'bf-form-error-col'}`}
                placeholder=" " data-number-field=""
                />
            <label
                htmlFor="resell_value"
                className="absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-placeholder-shown:left-12  peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent">
                Resell Value
            </label>
            <span className='absolute left-5 top-[50%] translate-y-[-50%] text-[#808080] peer-focus:text-[#00263A] text-[18px] leading-[20px] text-medium'>$</span>
            <span className='absolute right-5 top-[50%] translate-y-[-50%] text-[#808080] peer-focus:text-[#00263A] text-[14px] leading-[20px] text-medium'>USD</span>
        </div>
        <div className="relative mb-5">
            <input
                id="premium_protection"
                type="text"
                name={`boxes[${index}].premiumProtection`}
                value={box?.premiumProtection?.name}
                onFocus={()=>(setOpenProtection(true))}
                className={`text-[#00263A] border peer block w-full appearance-none cursor-pointer rounded-lg border-[#ccc] p-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:border-b-0 focus:rounded-bl-[0px] focus:rounded-br-[0px] focus:outline-none focus:ring-0 ${(errors?.boxes?.length && touched.boxes && errors.boxes[index].premiumProtection && touched.boxes[index].premiumProtection) && 'bf-form-error-col'}`}
                placeholder=' '
                autoComplete='off' readOnly
                />
            <label
                htmlFor="premium_protection"
                className="absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent">
                Shipping Protection
            </label>
            <span onClick={(e)=>{setOpenProtection(!openProtection);
                if(!openProtection){
                    e.currentTarget.parentNode.querySelector('input').focus();
                }
            }} className='absolute right-5 top-[50%] translate-y-[-50%] text-[#808080] peer-focus:text-[#00263A] text-[14px] leading-[20px] text-medium peer-focus:-rotate-180 transform duration-300 cursor-pointer'>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-8 svg-inline--fa fa-angle-down">
                    <path fill="currentColor" d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" className=""></path>
                </svg>
            </span>
            <div className='custom-overlay' style={{display: openProtection ? "block" : "none"}} onClick={()=>setOpenProtection(!openProtection)}></div>
            <div style={{display: openProtection ? "block":"none"}} className="absolute jb-select-dropdown transform duration-300 shadow-[0_6px_10px_rgba(0,0,0,0.2)] top-full z-40 w-full lef-0 rounded-bl-lg rounded-br-lg border border-[#00263A] border-t-0 overflow-y-auto max-h-[32.5rem] min-w-133">
                <div className="flex flex-col text-left w-full bg-white">
                    <div className="flex flex-wrap items-center justify-around mx-[16px] my-[8px] p-[8px_24px] border border-[#00263A] rounded-[6px]">
                        <div className="flex flex-col grow order-2 sm:order-1">
                            <h2 className="uppercase text-[#00263A] font-semibold font-[16px] leading-[24px] mb-2">SHIPPING PROTECTION</h2>
                            <ul className="list-none text-[#00263A] text-[12px] leading-[18px] font-medium my-2">
                                <li className='flex items-center mb-3'><CheckBox width={18} height={18}/> <span className='ml-4'>Ship with confidence.</span></li>
                                <li className='flex items-center mb-3'><CheckBox width={18} height={18}/> <span className='ml-4'>Simple and easy claims process.</span></li>
                                <li className='flex items-center mb-3'><CheckBox width={18} height={18}/> <span className='ml-4'>Covers accidental damage or if lost in transit.</span></li>
                            </ul>
                            <a href="/pages/faqs-1" className="text-[#CE0E2D] text-[14px] leading-[20px] mt-px mb-2 font-medium">Learn More</a>
                        </div>
                        <img src="https://cdn.shopify.com/s/files/1/0795/8813/1105/files/JBS-protection1-removebg-preview.png?v=1694640574" 
                                fallback="ship/bf-ship-4-protection-icon.png" className="max-w-[100px] order-1 sm:order-2"/>
                    </div>
                    {protections.map((item,index)=>{
                        return <div onClick={()=>handleProtectionChange(item)} className="cursor-pointer w-full bg-white" key={index}>
                            <div className="flex w-full items-center p-2 pl-2 border-transparent hover:bg-brand-200 border-l-2 relative">
                                <div className="w-full items-center flex">
                                    <div className="mx-2 text-[#00263A] text-[14px] leading-[20px] text-medium">{item?.name}</div>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    </div>
  </div>
  )
}

export default Boxes