import React, { useEffect, useRef, useState } from 'react'
import {Formik} from 'formik'
import * as Yup from 'yup';
import { getFormattedAddress, getQuickRates } from '../helpers/apiRequests';
import QuoteModel from '../components/models/QuoteModel';
import useApp from '../store/useApp';
// import { initializeAutocomplete } from '../helpers/GoogleMapsService';
import GoogleAutoComplete from '../helpers/GoogleMapsClassService';

import { getQuoteAnalytics } from '../helpers/gtagEvents';

// Testing
// 1. commit trip in validationSchema
// 2. Add to and from in getQuickRates

const initialValues = {
   trip:{
           id: Date.now(),
           from: '',
           to: ''
   },
   box:{
           id: Date.now(),
           length: "",
           label: "",
           width: "",
           height: "",
           weight: "",
           marketValue: "",
           preset: "Custom",
           isEbike: false,
           isExact: false,
           linearUnit: "in",
           weightUnit: "lb",
           sortOrder: "",
           contents: "",
           premiumProtection: ""
   }
}

function Index() {
   const {categories} = useApp()
   const formRef = useRef(null)
   const fromRef = useRef()
   const toRef = useRef()

   const [modelOpened,setModelOpened] = useState(false)
   const [isLoading,setIsLoading] = useState(false)
   const [isError,setIsError] = useState(false)
   const [errorMessage,setErrorMessage] = useState('')
   const [services,setServices] = useState([])

   const validationSchema = Yup.object().shape({
      trip: Yup.object().shape({
         from: Yup.object().required('Required').shape({
            invalid: Yup.bool().test(value => value === false || undefined || null)
         }),
         to: Yup.object().required('Required').shape({
            invalid: Yup.bool().test(value => value === false || undefined || null)
         })
      }),
      box: Yup.object().shape({
         length: Yup.number().required('Required'),
         width: Yup.number().required('Required'),
         height: Yup.number().required('Required'),
         weight: Yup.number().required('Required'),
         contents: Yup.object().required('Required')
      }),
  });

   function handleContentChange(value){
      let content = categories.filter((category)=>category.id==value)
      formRef.current?.setFieldValue('box.contents',content[0])
   }

   async function fillInAddress(field,type) {
      var place = field.getPlace();
      const {data} = await getFormattedAddress(place)
      if(type === 'from'){
        if(data?.XAVResponse?.Response?.ResponseStatus?.Code === '1'){
          formRef.current.setFieldValue(`trip.from`,{...place,invalid:false})
        }else if(data?.response?.errors){
         formRef.current.setFieldValue(`trip.from`,{...place,invalid:true, errorMessage:data?.response?.errors[0].message})
        }
      }else if(type === 'to'){
        if(data?.XAVResponse?.Response?.ResponseStatus?.Code === '1'){
         formRef.current.setFieldValue(`trip.to`,{...place,invalid:false})
        }else if(data?.response?.errors){
         formRef.current.setFieldValue(`trip.to`,{...place,invalid:true, errorMessage:data?.response?.errors[0].message})
        }
      }    
  }

  function handleStartShipping(){
   const obj = formRef.current?.values
   window.localStorage.setItem('quickQoute',JSON.stringify(obj))
   window.location.href = '/pages/shipping'
  }

  async function handleSubmit(values){

   setIsLoading(true)
   setIsError(false)
   setErrorMessage('')
   setModelOpened(true)
   try {
      const {data} = await getQuickRates(values.box,values.trip)
      setServices(data?.services)
      if(data?.services === undefined) setIsError(true)
      getQuoteAnalytics({contents:values.box.contents,services:data?.services});
      setIsLoading(false)
   } catch (error) {
      console.log("err",error)
      const {data} = error?.response
      if(data.status === 'Error'){
         setErrorMessage(data?.message)
      }
      setIsLoading(false)
      setIsError(true)
   }
  }

   function initFieldEvent(Obj,ref,type){
      let attempt = 0,maxRetries=10,interval=1000;

      function attemptCheck() {
         attempt++;

         const result = Obj.initializeAutocomplete(ref,type,fillInAddress);;

         if (result) return true;

         if (attempt >= maxRetries) return false;

         setTimeout(attemptCheck, interval);
      }

      // Start the first attempt
      attemptCheck();
   }

   useEffect(()=>{
      // initializeAutocomplete(fromRef,'from',fillInAddress)
      // setTimeout(()=>{
      //    initializeAutocomplete(toRef,'to',fillInAddress)
      // },1500)

      const AutoComplete = new GoogleAutoComplete();
      initFieldEvent(AutoComplete,fromRef,'from');
      initFieldEvent(AutoComplete,toRef,'to');
   },[initFieldEvent])

  return (
    <div id="shopify-section-template--20115075465505__7b270add-c799-4deb-bc3d-a5454f27dc03" className="shopify-section get-quote--form">
      <QuoteModel 
         open={modelOpened} 
         setOpen={setModelOpened} 
         isLoading={isLoading}
         isError={isError}
         errorMessage={errorMessage}
         services={services}
         packageObject={formRef.current?.values}
         handleStartShipping={handleStartShipping}/>
      <Formik 
         innerRef={formRef} 
         validationSchema={validationSchema} 
         initialValues={initialValues}
         onSubmit={handleSubmit}>
            {({values,errors,touched,setFieldValue,handleSubmit,handleChange})=>{
         return (
         <form onSubmit={handleSubmit}>
            <div className="gt-main gt-template--20115075465505__7b270add-c799-4deb-bc3d-a5454f27dc03">
               <div className="gt-wrap page-width">
                  <div className="gt-flex gt-main-card">
                     <div className="first-row">
                        <div className={`gt-field fr-item ${(errors.trip?.from && touched.trip?.from) ? 'input__field-error' : ''}`}>
                           <label htmlFor="From">From</label>
                           <div className="input-field">
                              <input type="text" ref={fromRef} value={values.trip?.from?.formatted_address} onChange={(e)=>setFieldValue(`trip.from`,e.target.value)} name="trip.from" placeholder="Your Pickup or drop off location" 
                                 className={`input__field`}/>
                           </div>
                        </div>
                        <div className={`gt-field fr-item ${(errors.trip?.to && touched.trip?.to) ? 'input__field-error' : ''}`}>
                           <label htmlFor="To">To</label>
                           <div className="input-field">
                              <input type="text" ref={toRef} value={values.trip?.to?.formatted_address} onChange={(e)=>setFieldValue(`trip.to`,e.target.value)} name="trip.to" placeholder="Any Destination" className="input__field"/>
                           </div>
                        </div>
                        <div className={`gt-field fr-item ${(errors.box?.contents && touched.box?.contents) ? 'input__field-error' : ''}`}>
                           <label htmlFor="Content-button">Content</label>
                           <div className="input-field sp-field">
                              <select name="box.contents" id="Content" value={values.box.contents?.id} onChange={(e)=>handleContentChange(e.target.value)}>
                                 <option value="" selected hidden>Choose Contents</option>
                                 {categories?.map((category)=>{
                                    return <option key={category.id} value={category.id}>{category.name}</option>
                                 })}
                              </select>
                           </div>
                        </div>
                     </div>
                     <div className="second-row">
                        <div className={`gt-field fr-item ${(errors.box?.length && touched.box?.length) ? 'input__field-error' : ''}`}>
                           <label htmlFor="Length">Length</label>
                           <div className="input-field">
                              <input type="number" value={values.box?.length} onChange={handleChange} pattern="[0-9]*" min="0" name="box.length" className="input__field"/>
                              <p className="prefix">in</p>
                           </div>
                        </div>
                        <div className={`gt-field fr-item ${(errors.box?.width && touched.box?.weight) ? 'input__field-error' : ''}`}>
                           <label htmlFor="Width">Width</label>
                           <div className="input-field">
                              <input type="number" value={values.box?.width} onChange={handleChange} pattern="[0-9]*" min="0" name="box.width" className="input__field"/>
                              <p className="prefix">in</p>
                           </div>
                        </div>
                        <div className={`gt-field fr-item i-3 ${(errors.box?.height && touched.box?.height) ? 'input__field-error' : ''}`}>
                           <label htmlFor="Height">Height</label>
                           <div className="input-field">
                              <input type="number" value={values.box?.height} onChange={handleChange} pattern="[0-9]*" min="0" name="box.height" className="input__field"/>
                              <p className="prefix">in</p>
                           </div>
                        </div>
                        <div className={`gt-field fr-item ${(errors.box?.weight && touched.box?.weight) ? 'input__field-error' : ''}`}>
                           <label htmlFor="Weight">Weight</label>
                           <div className="input-field">
                              <input type="number" value={values.box?.weight} onChange={handleChange} pattern="[0-9]*" min="0" name="box.weight" className="input__field"/>
                              <p className="prefix">lb</p>
                           </div>
                        </div>
                     </div>
                     <div className="third-row">
                        <div className="button-wrapper">
                           <button type="submit" className="button button--full-width button-my-tertiary">Get A Quote</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </form>
      )}
      }

      </Formik>
   </div>
  )
}

export default Index