import React from 'react'
import SingleBox from './SingleBox'

function Stage({index,errors,touched,stage,handleFieldChange}) {

  const handleDescriptionChange = function(type,value,i){
    if(type === 'outer'){
        handleFieldChange(`stages[${index}].boxes[${i}].boxOuterDescription`,value)
    }else if(type === 'inner'){
        handleFieldChange(`stages[${index}].boxes[${i}].boxInnerDescription`,value)
    }
  }

  function getBoxErrors(i){
    console.log("Is calling",errors?.stages,touched?.stages)
    let obj = {
        innerDescError:false,
        outerDescError:false
    }
    if(errors?.stages === undefined || touched?.stages === undefined) return obj
    console.log("2nd")
    if(errors.stages.length === 0 || touched.stages.length === 0) return obj
    console.log("3nd")
    if(errors.stages[index]?.boxes === undefined || touched.stages[index]?.boxes === undefined) return obj
    console.log("4nd")
    if(errors.stages[index].boxes.length === 0 || touched.stages[index].boxes.length === 0) return obj
    console.log("5nd")
    if(errors.stages[index].boxes[i]?.boxOuterDescription && touched.stages[index].boxes[i]?.boxOuterDescription){
        obj.outerDescError = true
    }
    if(errors.stages[index].boxes[i]?.boxInnerDescription && touched.stages[index].boxes[i]?.boxInnerDescription){
        obj.innerDescError = true
    }
    console.log("Obje",obj)
    return obj
  }

  return (
    <div className="ship-stage-wrapper mt-3">
    <div className="contents-stage-header">
       <div className="font-bold uppercase">Package Details</div>
       {/* <div className="font-semibold text-tiny pb-3">3790 S Las Vegas Blvd, Las Vegas, NV 89109, USA To 5757 Wayne Newton Blvd, Las Vegas, NV 89119, USA</div> */}
    </div>
    <div className="contents-stage-body">
        {stage.boxes.length && stage.boxes.map((box,i)=>{
            let boxErrors = getBoxErrors(i)
            return <SingleBox key={i} index={i} box={box} boxErrors={boxErrors} handleDescriptionChange={handleDescriptionChange}/>
        })}
    </div>
 </div>
  )
}

export default Stage