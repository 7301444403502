import React, { useContext, useState } from 'react'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
// import AppContext from '../../../store/AppContext';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function StripeContainer({clientSecret}) {
    //   const {clientSecret,cancelOrder,setClientSecret,setToaster} = useContext(AppContext) 

      const options = {
        // passing the client secret obtained from the server
        clientSecret: clientSecret
      };

      return (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm />
        </Elements>
      );
}

export default StripeContainer