import axios from 'axios';

const instance = axios.create({
    withCredentials: true
});

instance.defaults.headers.common['app_shipper_id'] = window.localStorage.getItem('app_shipper_id');

instance.interceptors.request.use(function (config) {
  return config;
});

export default instance;