import React, { useEffect, useState } from 'react'

import SearchForm from '../components/SearchForm';
import useApp from '../store/useApp';
import Loading from '../components/loaders/Loading';
import FormLayout from './FormLayout';

// const initialValues = {
//     trips: [
//         {
//             id: Date.now(),
//             from: '',
//             to: ''
//         }
//     ],
//     boxes: [
//         {
//             id: Date.now(),
//             length: "",
//             label: "",
//             width: "",
//             height: "",
//             weight: "",
//             marketValue: "",
//             preset: "",
//             isEbike: false,
//             isExact: false,
//             linearUnit: "",
//             weightUnit: "",
//             sortOrder: "",
//             contents: "",
//             premiumProtection: ""
//         }
//     ],
//     purpose: ''
//   }

function Search() {
    const {activeSearch} =  useApp()
    const [isDataLoading,setIsDataLoading] = useState(true)
    const [initialValues, setInitialValues] = useState({
        trips: [
            {
                id: Date.now(),
                from: '',
                to: ''
            }
        ],
        boxes: [
            {
                id: Date.now(),
                length: "",
                label: "",
                width: "",
                height: "",
                weight: "",
                marketValue: "",
                preset: "",
                isEbike: false,
                isExact: false,
                linearUnit: "",
                weightUnit: "",
                sortOrder: "",
                contents: "",
                premiumProtection: ""
            }
        ],
        purpose: ''
      })

    useEffect(()=>{
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if(activeSearch.boxes?.length || activeSearch.trips?.length){
            setInitialValues({
                boxes: activeSearch?.boxes ? activeSearch.boxes : [],
                trips: activeSearch?.trips ? activeSearch.trips : []
            })

        }
        setIsDataLoading(false)
      },[activeSearch])

  return (
    <FormLayout>
        {!isDataLoading && <SearchForm initialValues={initialValues}/>}
        {isDataLoading && <Loading/>}
    </FormLayout>
  )
}

export default Search